import React, { useState } from 'react';
import Input from 'components/Input';
import * as styledComponents from './styles';
import { useQuery } from 'react-query';
import { djsAPI, cmsHeaders } from 'api/hosts';
import { CombSpinner } from 'react-spinners-kit';
import DJCard from './DJCard';

export type DJ = {
  attributes: {
    name: String;
    description: String;
    image: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
    background: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
};

const DJs = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const { isLoading, data } = useQuery('shopItems', () => fetch(djsAPI, cmsHeaders).then((res) => res.json()));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  if (isLoading)
    return (
      <styledComponents.SpinnerContainer>
        <CombSpinner color="#86ff0dc4" />
      </styledComponents.SpinnerContainer>
    );

  const djs = data?.data || [];

  return (
    <styledComponents.Container className="flex flex-col items-center justify-start pt-24 px-7">
      <Input onChange={handleOnChange} />
      <styledComponents.CardsContainer>
        {!isLoading &&
          djs
            .filter(
              (dj: DJ) =>
                dj.attributes.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                dj.attributes.description.toLowerCase().includes(searchTerm),
            )
            ?.map((dj: DJ, idx: number) => {
              return <DJCard key={idx} dj={dj} />;
            })}
      </styledComponents.CardsContainer>
    </styledComponents.Container>
  );
};
export default DJs;
