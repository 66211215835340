import React, { useState } from 'react';
import * as styledComponents from './styles';
import MenuCard from './MenuCard';
import { useQuery } from 'react-query';
import { itemsAPI, cmsHeaders } from 'api/hosts';
import { CombSpinner } from 'react-spinners-kit';
import Input from 'components/Input';

export type Item = {
  attributes: {
    name: String;
    description: String;
    price: Number;
    image: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
    background: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
};

const Menu = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const { isLoading, data } = useQuery('shopItems', () => fetch(itemsAPI, cmsHeaders).then((res) => res.json()));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const items = data?.data || [];
  return (
    <styledComponents.Container className="flex flex-col items-center justify-start pt-24 px-7">
      <Input onChange={handleOnChange} />
      <styledComponents.CardsContainer>
        {!isLoading ? (
          items
            .filter(
              (item: Item) =>
                item.attributes.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.attributes.description.toLowerCase().includes(searchTerm),
            )
            ?.map((item: Item, idx: number) => {
              return <MenuCard key={idx} item={item} />;
            })
        ) : (
          <CombSpinner color={'#86ff0dc4'} />
        )}
      </styledComponents.CardsContainer>
    </styledComponents.Container>
  );
};

export default Menu;
