import { useState } from 'react';
import Burguer from './Burguer';
import GeneralSettings from './GeneralSettings';
import NavElements from './NavElements/index';
import SideMenu from './SideMenu/index';
import * as styledComponents from './styles';
const Header = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Burguer toggle={toggle} open={open} />
      <styledComponents.TopHeader>
        <styledComponents.HeaderElementsContainer>
          <NavElements open={open} toggle={toggle} />
        </styledComponents.HeaderElementsContainer>
      </styledComponents.TopHeader>
      <SideMenu open={open} toggle={toggle} />
      <GeneralSettings />
    </>
  );
};

export default Header;
