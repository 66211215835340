import {
  faMusic,
  faBuilding,
  faCity,
  faGlobe,
  faUser,
  faUtensils,
  faQrcode, // Import QR code icon
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from 'Contexts/LanguageContext';

const translations = {
  english: {
    events: 'Events',
    venues: 'Venues',
    cities: 'Cities',
    countries: 'Countries',
    profile: 'Profile',
    menuItems: 'Menu Items',
    scanDiscounts: 'Scan Discounts', // New translation
  },
  spanish: {
    events: 'Eventos',
    venues: 'Lugares',
    cities: 'Ciudades',
    countries: 'Países',
    profile: 'Perfil',
    menuItems: 'Elementos del Menú',
    scanDiscounts: 'Escanear Descuentos', // New translation
  },
  polish: {
    events: 'Wydarzenia',
    venues: 'Miejsca',
    cities: 'Miasta',
    countries: 'Kraje',
    profile: 'Profil',
    menuItems: 'Pozycje Menu',
    scanDiscounts: 'Skanuj Zniżki', // New translation
  },
};

type Language = 'english' | 'spanish' | 'polish';

const PartyTicketLowerBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();

  const t = translations[language as Language];

  const getButtonClass = (path: string) => {
    return location.pathname === path
      ? 'text-neon-blue border-b-2 border-neon-blue'
      : 'text-white';
  };

  return (
    <div
      className="fixed bottom-0 left-0 w-full bg-gray-800 text-white flex justify-around p-2 h-20 pt-3"
      style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 8px)' }} // Add safe area padding for iPhones
    >
      <button
        onClick={() => navigate('/app/admin/events')}
        className={`flex flex-col items-center ${getButtonClass('/app/admin/events')}`}
      >
        <FontAwesomeIcon icon={faMusic} className="text-xl" />
        <span className="text-xs">{t.events}</span>
      </button>
      <button
        onClick={() => navigate('/app/admin/venues')}
        className={`flex flex-col items-center ${getButtonClass('/app/admin/venues')}`}
      >
        <FontAwesomeIcon icon={faBuilding} className="text-xl" />
        <span className="text-xs">{t.venues}</span>
      </button>
      <button
        onClick={() => navigate('/app/admin/cities')}
        className={`flex flex-col items-center ${getButtonClass('/app/admin/cities')}`}
      >
        <FontAwesomeIcon icon={faCity} className="text-xl" />
        <span className="text-xs">{t.cities}</span>
      </button>
      <button
        onClick={() => navigate('/app/admin/countries')}
        className={`flex flex-col items-center ${getButtonClass('/app/admin/countries')}`}
      >
        <FontAwesomeIcon icon={faGlobe} className="text-xl" />
        <span className="text-xs">{t.countries}</span>
      </button>
      <button
        onClick={() => navigate('/app/admin/menu-items')}
        className={`flex flex-col items-center ${getButtonClass('/app/admin/menu-items')}`}
      >
        <FontAwesomeIcon icon={faUtensils} className="text-xl" />
        <span className="text-xs">{t.menuItems}</span>
      </button>
      <button
        onClick={() => navigate('/app/admin/scan-discount')} // New route for scanning discounts
        className={`flex flex-col items-center ${getButtonClass('/app/admin/scan-discount')}`}
      >
        <FontAwesomeIcon icon={faQrcode} className="text-xl" /> {/* QR Code icon */}
        <span className="text-xs">{t.scanDiscounts}</span>
      </button>
      <button
        onClick={() => navigate('/app/profile')}
        className={`flex flex-col items-center ${getButtonClass('/app/profile')}`}
      >
        <FontAwesomeIcon icon={faUser} className="text-xl" />
        <span className="text-xs">{t.profile}</span>
      </button>
    </div>
  );
};

export default PartyTicketLowerBar;
