import parse from 'html-react-parser';
import React from 'react';

interface BioProps {
  content: string;
  getUser: () => void;
}

const Bio: React.FC<BioProps> = ({ content }) => {
  return (
    <div>
      <p className="text-sm">{typeof content === 'string' && parse(content)}</p>
    </div>
  );
};

export default Bio;
