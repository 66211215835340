// ShareHandler.js
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const ShareHandler = () => {
  const [searchParams] = useSearchParams();
  const [sharedData, setSharedData] = useState(null);

  useEffect(() => {
    const title = searchParams.get('name');
    const text = searchParams.get('description');
    const url = searchParams.get('link');
    if (title || text || url) {
      setSharedData({ title, text, url });
    }
  }, [searchParams]);

  if (!sharedData) {
    return <div>No shared data found.</div>;
  }

  return (
    <div>
      <h1>Shared Data</h1>
      <p><strong>Title:</strong> {sharedData.title}</p>
      <p><strong>Description:</strong> {sharedData.text}</p>
      <p><strong>URL:</strong> <a href={sharedData.url}>{sharedData.url}</a></p>
    </div>
  );
};

export default ShareHandler;
