import React, { useState, ChangeEvent, FormEvent } from 'react';
import Swal from 'sweetalert2';
import { APIBase } from 'api/hosts';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ClapSpinner } from 'react-spinners-kit';

const Register: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [usernameTaken, setUsernameTaken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChangeUsername = async (e: ChangeEvent<HTMLInputElement>) => {
    const intendedUsername = e.target.value;
    setUsername(intendedUsername);
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${APIBase}/auth/usernameSlot/${intendedUsername}`, data);
    setUsernameTaken(!response.ok);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const checker = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!password.match(checker)) {
      Swal.fire({
        title: 'Invalid password format',
        text: 'Must contain at least 1 number, 1 uppercase, 1 special character, and at least 8 or more characters',
        showConfirmButton: false,
        background: '#000000',
        color: '#FFFFFF',
      });
      setLoading(false);
      return;
    }
    Swal.fire({
      title: 'Creating account...',
      showConfirmButton: false,
      background: '#000000',
      color: '#FFFFFF',
    });
    const body = {
      username,
      email,
      password,
    };
    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    try {
      const response = await fetch(`${APIBase}/auth/register`, data);
      if (response.ok) {
        navigate('/auth/login');
        Swal.fire({
          title: 'User Registered',
          text: 'Please check your inbox for an email confirmation',
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
      } else {
        Swal.fire({
          title: 'System Error',
          text: 'Please try again later',
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Network Error',
        text: 'Please check your connection and try again',
        showConfirmButton: false,
        timer: 3000,
        background: '#000000',
        color: '#FFFFFF',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-cover bg-top bg-no-repeat bg-black py-10 px-6">
      <div className="w-full max-w-sm bg-black bg-opacity-70 backdrop-blur-lg rounded-lg shadow-md p-6">
        <h1 className="text-4xl text-white font-bold mb-6">Register</h1>
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          {usernameTaken && <div className="text-red-500 mb-2">Username Taken</div>}
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={handleChangeUsername}
            className="w-full px-3 py-2 mb-4 text-gray-900 rounded bg-gray-200 focus:outline-none"
            required
          />
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={handleChangeEmail}
            className="w-full px-3 py-2 mb-4 text-gray-900 rounded bg-gray-200 focus:outline-none"
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleChangePassword}
            className="w-full px-3 py-2 mb-4 text-gray-900 rounded bg-gray-200 focus:outline-none"
            title="Must contain at least 1 number, 1 uppercase, 1 special character, and at least 8 or more characters"
            required
          />
          <div className="w-full max-w-xs">
            <button
              type="submit"
              className="w-full py-2 flex items-center justify-center text-white rounded-lg border border-solid border-white shadow-md  focus:outline-none"
            >
              {loading ? <ClapSpinner color="#86ff0dc4" /> : 'Register'}
            </button>
          </div>
        </form>
        <div className="flex justify-between mt-4">
          <Link to="/auth/login" className="text-sm text-gray-400 hover:text-white">
            Login
          </Link>
          <Link to="/auth/recover" className="text-sm text-gray-400 hover:text-white">
            Forgot password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
