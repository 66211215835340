import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaEllipsisV, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

const translations = {
  english: {
    menuItems: 'Menu Items',
    searchPlaceholder: 'Search menu items...',
    selectVenue: 'Select venue...',
    newMenuItem: 'New Menu Item',
    edit: 'Edit',
    view: 'View',
    delete: 'Delete',
    deleted: 'Deleted!',
    deletedMessage: 'Menu item has been deleted.',
    error: 'Error',
    deleteError: 'Failed to delete menu item. Please try again later.',
    fetchError: 'Error fetching menu items',
    retryLater: 'Please try again later 😞',
    confirmDelete: 'Are you sure?',
    confirmDeleteText: "You won't be able to revert this!",
    confirmDeleteYes: 'Yes, delete it!',
    discountAvailable: 'Discount Available',
    pointsRequired: 'Points Required',
    priceWithDiscount: 'Price with Discount', // Updated field
  },
  spanish: {
    menuItems: 'Elementos del menú',
    searchPlaceholder: 'Buscar elementos del menú...',
    selectVenue: 'Selecciona lugar...',
    newMenuItem: 'Nuevo elemento del menú',
    edit: 'Editar',
    view: 'Ver',
    delete: 'Eliminar',
    deleted: '¡Eliminado!',
    deletedMessage: 'El elemento del menú ha sido eliminado.',
    error: 'Error',
    deleteError: 'No se pudo eliminar el elemento del menú. Por favor, inténtelo de nuevo más tarde.',
    fetchError: 'Error al obtener los elementos del menú',
    retryLater: 'Por favor, inténtelo de nuevo más tarde 😞',
    confirmDelete: '¿Estás seguro?',
    confirmDeleteText: '¡No podrás revertir esto!',
    confirmDeleteYes: 'Sí, ¡eliminarlo!',
    discountAvailable: 'Descuento disponible',
    pointsRequired: 'Puntos requeridos',
    priceWithDiscount: 'Precio con Descuento', // Updated field
  },
  polish: {
    menuItems: 'Pozycje menu',
    searchPlaceholder: 'Szukaj pozycji menu...',
    selectVenue: 'Wybierz miejsce...',
    newMenuItem: 'Nowa pozycja menu',
    edit: 'Edytuj',
    view: 'Zobacz',
    delete: 'Usuń',
    deleted: 'Usunięto!',
    deletedMessage: 'Pozycja menu została usunięta.',
    error: 'Błąd',
    deleteError: 'Nie udało się usunąć pozycji menu. Spróbuj ponownie później.',
    fetchError: 'Błąd pobierania pozycji menu',
    retryLater: 'Spróbuj ponownie później 😞',
    confirmDelete: 'Czy jesteś pewien?',
    confirmDeleteText: 'Nie będziesz mógł tego cofnąć!',
    confirmDeleteYes: 'Tak, usuń to!',
    discountAvailable: 'Dostępny rabat',
    pointsRequired: 'Wymagane punkty',
    priceWithDiscount: 'Cena ze Zniżką', // Updated field
  },
};

const MenuItemList: React.FC = () => {
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedVenue, setSelectedVenue] = useState<any>(null);
  const [venues, setVenues] = useState<any[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchVenues();
  }, [currentToken]);

  useEffect(() => {
    fetchMenuItems();
  }, [currentToken, searchTerm, selectedVenue]);

  const fetchVenues = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => response.json())
      .then((responseJson) => setVenues(responseJson))
      .catch(() => Swal.fire({ title: t.error, text: t.retryLater, icon: 'error', timer: 3000 }));
  };

  const fetchMenuItems = async () => {
    setLoading(true);

    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    let url = `${APIBase}/client/menu-item?search=${searchTerm}`;
    if (selectedVenue?.value) {
      url += `&venue=${selectedVenue.value}`;
    }

    await fetch(url, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.fetchError);
        }
      })
      .then((responseJson) => {
        setMenuItems(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.retryLater,
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = async (menuItemId: string) => {
    const data: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/menu-item/${menuItemId}`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.deleted,
            text: t.deletedMessage,
            icon: 'success',
            timer: 2000,
          });
          fetchMenuItems();
        } else {
          Swal.fire({
            title: t.error,
            text: t.deleteError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.deleteError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const confirmDelete = (menuItemId: string) => {
    Swal.fire({
      title: t.confirmDelete,
      text: t.confirmDeleteText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t.confirmDeleteYes,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(menuItemId);
      }
    });
  };

  const handleVenueChange = (selectedOption: any) => {
    setSelectedVenue(selectedOption || null);
  };

  const calculateDiscountedPrice = (price: number, priceWithDiscount: number) => {
    return priceWithDiscount;
  };

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t.menuItems}</h2>
        <button
          onClick={() => navigate('/app/admin/create-menu-item')}
          className="bg-gradient-to-r from-black via-gray-800 to-black text-white p-3 rounded-full flex items-center justify-center shadow-lg hover:bg-gradient-to-r hover:from-gray-700 hover:via-gray-800 hover:to-gray-900 transition-all duration-300 ease-in-out border border-neon-blue"
        >
          <FaPlus className="text-2xl" />
        </button>
      </div>
      <div className="mb-4">
        <input
          type="text"
          className="w-full p-2 rounded-md bg-gray-800 text-white"
          placeholder={t.searchPlaceholder}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <Select
          id="venueSelect"
          name="venue"
          value={venues.find((option) => option._id === selectedVenue)}
          onChange={handleVenueChange}
          options={venues.map((venue) => ({ value: venue._id, label: venue.name }))}
          className="w-full text-black"
          placeholder={t.selectVenue}
        />
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <StageSpinner size={50} color="#ffffff" />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {menuItems.map((menuItem: any) => (
            <div
              key={menuItem._id}
              className="relative bg-gradient-to-b from-black via-gray-800 to-gray-900 p-4 rounded-lg shadow-lg border border-gray-700 hover:border-neon-purple transition-all duration-300 ease-in-out cursor-pointer flex"
            >
              {menuItem.imageURL && (
                <img src={menuItem.imageURL} alt={menuItem.name} className="w-16 h-16 object-cover rounded-md mr-4" />
              )}
              <div className="flex-grow">
                <button
                  onClick={() => setDropdownOpen(dropdownOpen === menuItem._id ? null : menuItem._id)}
                  className="absolute top-2 right-2 p-2 rounded-full text-neon-purple hover:text-neon-pink transition-colors duration-300 border border-neon-purple shadow-lg"
                >
                  <FaEllipsisV className="text-xl" />
                </button>
                {dropdownOpen === menuItem._id && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-10"
                    ref={dropdownRef}
                  >
                    <button
                      onClick={() => navigate(`/app/admin/edit-menu-item/${menuItem._id}`)}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.edit}
                    </button>
                    <button
                      onClick={() => navigate(`/app/admin/view-menu-item/${menuItem._id}`)}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.view}
                    </button>
                    <button
                      onClick={() => confirmDelete(menuItem._id)}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.delete}
                    </button>
                  </div>
                )}
                <div onClick={() => navigate(`/app/admin/view-menu-item/${menuItem._id}`)}>
                  <h3 className="text-lg font-semibold mb-2">{menuItem.name}</h3>
                  <p className="text-sm text-gray-400">{menuItem.description}</p>

                  {/* Price Section */}
                  <p className="text-sm text-gray-500">
                    {menuItem.discounts && menuItem.discounts.length > 0 ? (
                      <>
                        <span>
                          {menuItem.price} {menuItem.currency}
                        </span>
                        {menuItem.discounts.map((discount: any, index: number) => (
                          <div key={index} className="text-sm">
                            <span>
                              <span className="line-through mr-2">
                                {menuItem.price} {menuItem.currency}
                              </span>
                              {discount.priceWithDiscount}{' '}
                              {menuItem.currency} ({t.discountAvailable} with {discount.pointsRequired} points)
                            </span>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {menuItem.price} {menuItem.currency}
                      </>
                    )}
                  </p>

                  <p className="text-sm text-gray-500">{menuItem.venueName}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItemList;
