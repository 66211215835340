import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const EditCountry: React.FC = () => {
  const { countryId } = useParams<{ countryId: string }>();
  const [countryData, setCountryData] = useState({ name: '', code: '' });
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();

  const translations = {
    english: {
      back: 'Back',
      editCountry: 'Edit Country',
      countryName: 'Country Name',
      countryCode: 'Country Code',
      saveChanges: 'Save Changes',
      success: 'Success',
      updateSuccess: 'Country updated successfully!',
      error: 'Error',
      fetchError: 'Failed to fetch country details. Please try again later.',
      updateError: 'Failed to update country. Please try again later.',
    },
    spanish: {
      back: 'Atrás',
      editCountry: 'Editar País',
      countryName: 'Nombre del País',
      countryCode: 'Código del País',
      saveChanges: 'Guardar Cambios',
      success: 'Éxito',
      updateSuccess: '¡País actualizado con éxito!',
      error: 'Error',
      fetchError: 'No se pudieron obtener los detalles del país. Por favor, inténtelo de nuevo más tarde.',
      updateError: 'No se pudo actualizar el país. Por favor, inténtelo de nuevo más tarde.',
    },
    polish: {
      back: 'Powrót',
      editCountry: 'Edytuj Kraj',
      countryName: 'Nazwa Kraju',
      countryCode: 'Kod Kraju',
      saveChanges: 'Zapisz zmiany',
      success: 'Sukces',
      updateSuccess: 'Kraj zaktualizowany pomyślnie!',
      error: 'Błąd',
      fetchError: 'Nie udało się pobrać szczegółów kraju. Spróbuj ponownie później.',
      updateError: 'Nie udało się zaktualizować kraju. Spróbuj ponownie później.',
    },
  };
  type Language = 'english' | 'spanish' | 'polish';

  const t = translations[language as Language];

  useEffect(() => {
    fetchCountry();
  }, [countryId]);

  const fetchCountry = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/country/country/${countryId}`, data);
      if (response.ok) {
        const data = await response.json();
        setCountryData(data);
      } else {
        throw new Error(t.fetchError);
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCountryData({ ...countryData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(countryData),
    };

    try {
      const response = await fetch(`${APIBase}/client/country/country/${countryId}`, data);
      if (response.ok) {
        Swal.fire({
          title: t.success,
          text: t.updateSuccess,
          icon: 'success',
          timer: 2000,
        });
        navigate('/app/admin/countries');
      } else {
        throw new Error(t.updateError);
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.updateError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.editCountry}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="name">
            {t.countryName}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={countryData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="code">
            {t.countryCode}
          </label>
          <input
            type="text"
            id="code"
            name="code"
            value={countryData.code}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.saveChanges}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCountry;
