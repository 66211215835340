import styled from 'styled-components';

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 170px;
  max-width: 50%;
  min-height: 170px;
  margin-bottom: 20px;
`;

export const CardContentContainer = styled.div`
  top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const CardImage = styled.img`
  height: 170px;
  flex-shrink: no-shrink;
  padding-top: 10px;
  z-index: 2;
`;

export const CardBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 170px;
  padding: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 30px;
`;

export const CardTitle = styled.div`
  margin-top: 10px;
  font-family: 'Khula';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;

export const CardDescription = styled.div`
  font-family: 'Khula';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  padding: 0 10px;
`;

export const CardPrice = styled.div`
  width: 96%;
  margin: 0px 2%;
  height: 30px;
  border: 2px solid rgba(222, 137, 193, 0.17);
  filter: blur(0.5px);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Khula';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
`;
