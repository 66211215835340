export type Language = 'english' | 'spanish' | 'polish';

export interface Room {
  title: string;
  description: string;
  imgSrc: string;
}

export interface Video {
  title: string;
  url: string;
}

export const rooms: Record<Language, Room[]> = {
  english: [
    {
      title: 'Party Room',
      description: 'Immerse yourself in the world of exciting rhythms and pulsating beats in Room 1, our dynamic party room. Come and lose yourself in the music, dancing until dawn!',
      imgSrc: 'assets/party_room.jpg',
    },
    {
      title: 'Cave Room',
      description: 'A multiuse room that sometimes is used for Karaoke, Games, Boiler Room Party and many more.',
      imgSrc: 'assets/games_room.jpg',
    },
    {
      title: 'Karaoke Room',
      description: 'Equipped with professional karaoke equipment, it allows you to sing your favorite songs. A great place to showcase your talents!',
      imgSrc: 'assets/karaoke_room.jpg',
    },
  ],
  spanish: [
    {
      title: 'Sala de Fiestas',
      description: 'Sumérgete en el mundo de ritmos emocionantes y ritmos pulsantes en la Sala 1, nuestra sala de fiestas dinámica. ¡Ven y piérdete en la música, bailando hasta el amanecer!',
      imgSrc: 'assets/party_room.jpg',
    },
    {
      title: 'Sala de la Cueva',
      description: 'Una sala de usos múltiples que a veces se utiliza para karaoke, juegos, Boiler Room Party y muchos más.',
      imgSrc: 'assets/games_room.jpg',
    },
    {
      title: 'Sala de Karaoke',
      description: 'Equipado con equipo de karaoke profesional, te permite cantar tus canciones favoritas. ¡Un gran lugar para mostrar tus talentos!',
      imgSrc: 'assets/karaoke_room.jpg',
    },
  ],
  polish: [
    {
      title: 'Sala Imprez',
      description: 'Zanurz się w świecie ekscytujących rytmów i pulsujących bitów w Sali 1, naszym dynamicznym pokoju imprezowym. Przyjdź i zgub się w muzyce, tańcząc do białego rana!',
      imgSrc: 'assets/party_room.jpg',
    },
    {
      title: 'Sala Jaskinia',
      description: 'Wielofunkcyjna sala, która czasami jest wykorzystywana do karaoke, gier, Boiler Room Party i wielu innych.',
      imgSrc: 'assets/games_room.jpg',
    },
    {
      title: 'Sala Karaoke',
      description: 'Wyposażona w profesjonalny sprzęt do karaoke, umożliwi Ci śpiewanie swoich ulubionych utworów. Świetne miejsce, aby pokazać swoje talenty!',
      imgSrc: 'assets/karaoke_room.jpg',
    },
  ],
};

export const videos: Record<Language, Video[]> = {
  english: [
    {
      title: 'Taki Parties be like...',
      url: 'https://www.youtube.com/embed/iLhUMDSRnas',
    },
    {
      title: 'Sora Travels Visits Taki Taki',
      url: 'https://www.youtube.com/embed/FTnoSRs6lbw',
    },
    {
      title: 'Erasmus Night at Taki Taki',
      url: 'https://youtube.com/embed/ci9auUVOECU',
    },
    {
      title: 'Taki Weekends',
      url: 'https://youtube.com/embed/ZBRRHx3Jpqk',
    },
    {
      title: 'Taki Sessions #4: Live set remix by DJ Kinder B.',
      url: 'https://www.youtube.com/embed/fYLgeDJhOOA',
    },
  ],
  spanish: [
    {
      title: 'Las fiestas de Taki son así...',
      url: 'https://www.youtube.com/embed/iLhUMDSRnas',
    },
    {
      title: 'Sora Travels Visits Taki Taki',
      url: 'https://www.youtube.com/embed/FTnoSRs6lbw',
    },
    {
      title: 'Noche Erasmus en Taki Taki',
      url: 'https://youtube.com/embed/ci9auUVOECU',
    },
    {
      title: 'Fines de semana en Taki',
      url: 'https://youtube.com/embed/ZBRRHx3Jpqk',
    },
    {
      title: 'Taki Sessions #4: Remix en vivo por DJ Kinder B.',
      url: 'https://www.youtube.com/embed/fYLgeDJhOOA',
    },
  ],
  polish: [
    {
      title: 'Tak wyglądają imprezy w Taki...',
      url: 'https://www.youtube.com/embed/iLhUMDSRnas',
    },
    {
      title: 'Sora Travels Visits Taki Taki',
      url: 'https://www.youtube.com/embed/FTnoSRs6lbw',
    },
    {
      title: 'Noc Erasmus w Taki Taki',
      url: 'https://youtube.com/embed/ci9auUVOECU',
    },
    {
      title: 'Weekend w Taki',
      url: 'https://youtube.com/embed/ZBRRHx3Jpqk',
    },
    {
      title: 'Taki Sessions #4: Live set remix by DJ Kinder B.',
      url: 'https://www.youtube.com/embed/fYLgeDJhOOA',
    },
  ],
};
