import React, { useState, ChangeEvent, FormEvent } from 'react';
import Swal from 'sweetalert2';
import { APIBase } from 'api/hosts';
import { useNavigate, useParams } from 'react-router-dom';
import { ClapSpinner } from 'react-spinners-kit';
import { Link } from 'react-router-dom';

const ChangePassword: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { tokenId } = useParams<{ tokenId: string }>();

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const passwordChecker = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

    if (!password.match(passwordChecker)) {
      Swal.fire({
        title: 'Invalid password format',
        text: 'Must contain at least 1 number, 1 uppercase, 1 special character, and at least 8 or more characters.',
        showConfirmButton: false,
        background: '#000000',
        color: '#FFFFFF',
      });
      return;
    }

    Swal.fire({
      title: 'Updating Password...',
      showConfirmButton: false,
      background: '#000000',
      color: '#FFFFFF',
    });

    setLoading(true);

    const body = {
      password,
      tokenId,
    };

    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(`${APIBase}/auth/changepassword`, data);
      if (response.ok) {
        Swal.fire({
          title: 'Password Updated',
          text: 'Please log in with your new password.',
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
        navigate('/auth/login');
      } else {
        Swal.fire({
          title: 'System Error',
          text: 'Please try again later.',
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Network Error',
        text: 'Please check your connection and try again.',
        showConfirmButton: false,
        timer: 3000,
        background: '#000000',
        color: '#FFFFFF',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-cover bg-top bg-no-repeat bg-black py-10 px-6">
      <div className="w-full max-w-sm bg-black bg-opacity-70 backdrop-blur-lg rounded-lg shadow-md p-6">
        <h1 className="text-4xl text-white font-bold mb-6">Password Update</h1>
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={handleChangePassword}
            className="w-full px-3 py-2 mb-4 text-gray-900 rounded bg-gray-200 focus:outline-none focus:ring-2"
            title="Must contain at least 1 number, 1 uppercase, 1 special character, and at least 8 or more characters."
            required
          />
          <div className="w-full max-w-xs">
            <button
              type="submit"
              className="w-full py-2 flex items-center justify-center text-white rounded-lg border border-solid border-white shadow-md focus:outline-none"
            >
              {loading ? <ClapSpinner color="#86ff0dc4" size={15} /> : 'Update Password'}
            </button>
          </div>
        </form>
        <div className="flex justify-between mt-6">
          <Link to="/auth/login" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
            Login
          </Link>
          <Link to="/auth/recover" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
            Forgot password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
