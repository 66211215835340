import ProfileComponent from 'components/Profile';
import React from 'react';

const DetailCard: React.FC = () => {
  return (
    <div className="w-screen h-screen flex flex-col justify-start bg-black overflow-scroll items-center">
      <ProfileComponent />
    </div>
  );
};

export default DetailCard;
