import { APIBase } from 'api/hosts';
import { useLanguage } from 'Contexts/LanguageContext';
import { AuthContext } from 'Contexts/Auth';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';
import Select from 'react-select';

const translations = {
  english: {
    back: 'Back',
    createEvent: 'Create Event',
    title: 'Title',
    description: 'Description',
    date: 'Date',
    time: 'Time',
    startHour: 'Start Hour',
    endHour: 'End Hour',
    uploadImage: 'Upload Image',
    facebookLink: 'Facebook Link',
    genres: 'Genres',
    add: 'Add',
    dressCode: 'Dress Code',
    selectDressCode: 'Select Dress Code',
    noDressCode: 'No Dress Code',
    casual: 'Casual',
    formal: 'Formal',
    smartCasual: 'Smart Casual',
    business: 'Business Attire',
    blackTie: 'Black Tie',
    cocktail: 'Cocktail Attire',
    costume: 'Costume',
    beach: 'Beachwear',
    festival: 'Festival',
    themed: 'Themed',
    ageRestriction: 'Age Restriction',
    allAges: 'All Ages',
    djs: 'DJs',
    clubId: 'Club ID',
    selectClub: 'Select a club...',
    type: 'Type',
    selectEventType: 'Select event type...',
    ticketLink: 'Ticket Link',
    verified: 'Verified',
    network: 'Network',
    success: 'Success',
    eventCreated: 'Event created successfully! 🎉',
    error: 'Error',
    submitError: 'Please try again later 😞',
  },
  spanish: {
    back: 'Regresar',
    createEvent: 'Crear Evento',
    title: 'Título',
    description: 'Descripción',
    date: 'Fecha',
    time: 'Hora',
    startHour: 'Hora de Inicio',
    endHour: 'Hora de Fin',
    uploadImage: 'Subir Imagen',
    facebookLink: 'Enlace de Facebook',
    genres: 'Géneros',
    add: 'Añadir',
    dressCode: 'Código de Vestimenta',
    selectDressCode: 'Seleccionar Código de Vestimenta',
    noDressCode: 'Sin Código de Vestimenta',
    casual: 'Informal',
    formal: 'Formal',
    smartCasual: 'Smart Casual',
    business: 'Atuendo de Negocios',
    blackTie: 'Etiqueta',
    cocktail: 'Etiqueta Coctel',
    costume: 'Disfraz',
    beach: 'Ropa de Playa',
    festival: 'Ropa de Festival',
    themed: 'Temático',
    ageRestriction: 'Restricción de Edad',
    allAges: 'Todas las Edades',
    djs: 'DJs',
    clubId: 'ID del Club',
    selectClub: 'Seleccionar un club...',
    type: 'Tipo',
    selectEventType: 'Seleccionar tipo de evento...',
    ticketLink: 'Enlace de Ticket',
    verified: 'Verificado',
    network: 'Red',
    success: 'Éxito',
    eventCreated: '¡Evento creado con éxito! 🎉',
    error: 'Error',
    submitError: 'Inténtalo de nuevo más tarde 😞',
  },
  polish: {
    back: 'Powrót',
    createEvent: 'Utwórz Wydarzenie',
    title: 'Tytuł',
    description: 'Opis',
    date: 'Data',
    time: 'Czas',
    startHour: 'Godzina Rozpoczęcia',
    endHour: 'Godzina Zakończenia',
    uploadImage: 'Prześlij Obraz',
    facebookLink: 'Link do Facebooka',
    genres: 'Gatunki',
    add: 'Dodaj',
    dressCode: 'Dress Code',
    selectDressCode: 'Wybierz Dress Code',
    noDressCode: 'Brak Kodu Ubioru',
    casual: 'Codzienny',
    formal: 'Formalny',
    smartCasual: 'Smart Casual',
    business: 'Strój Biznesowy',
    blackTie: 'Strój Wieczorowy',
    cocktail: 'Strój Koktajlowy',
    costume: 'Kostium',
    beach: 'Strój Plażowy',
    festival: 'Strój Festiwalowy',
    themed: 'Tematyczny',
    ageRestriction: 'Ograniczenie Wiekowe',
    allAges: 'Wszystkie Wiek',
    djs: 'DJs',
    clubId: 'ID Klubu',
    selectClub: 'Wybierz klub...',
    type: 'Rodzaj',
    selectEventType: 'Wybierz rodzaj wydarzenia...',
    ticketLink: 'Link do Biletu',
    verified: 'Zweryfikowane',
    network: 'Sieć',
    success: 'Sukces',
    eventCreated: 'Wydarzenie utworzone pomyślnie! 🎉',
    error: 'Błąd',
    submitError: 'Spróbuj ponownie później 😞',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const CreateEvent: React.FC = () => {
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    date: '',
    time: '',
    startHour: '',
    endHour: '',
    imageURL: '',
    facebookLink: '',
    ticketLink: '',
    genres: [] as string[],
    dressCode: '',
    ageRestriction: '',
    djs: [] as string[],
    verified: false,
    network: false,
    clubId: '',
    type: '',
  });
  const [venues, setVenues] = useState([]);
  const [genreInput, setGenreInput] = useState('');
  const [djInput, setDjInput] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const t = translations[language as Language];
  const navigate = useNavigate();

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const uploadImage = async (): Promise<string | null> => {
    if (!imageFile) return null;

    const formData = new FormData();
    formData.append('mediaUpload', imageFile);

    const data: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(`${APIBase}/client/event/upload-media`, data);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson.imageURL;
      } else {
        throw new Error('Image upload failed');
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.submitError,
        icon: 'error',
        timer: 3000,
      });
      return null;
    }
  };

  useEffect(() => {
    fetchVenues();
  }, [currentToken]);

  const fetchVenues = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error fetching venues');
        }
      })
      .then((responseJson) => {
        setVenues(responseJson);
      })
      .catch((error) => {
        Swal.fire({
          title: t.error,
          text: t.submitError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;
    setEventData({ ...eventData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSelectChange = (selectedOption: any) => {
    setEventData({ ...eventData, clubId: selectedOption ? selectedOption.value : '' });
  };

  const handleGenreAdd = () => {
    if (genreInput && !eventData.genres.includes(genreInput)) {
      setEventData({ ...eventData, genres: [...eventData.genres, genreInput] });
      setGenreInput('');
    }
  };

  const handleGenreRemove = (genre: string) => {
    setEventData({ ...eventData, genres: eventData.genres.filter((g) => g !== genre) });
  };

  const handleDjAdd = () => {
    if (djInput && !eventData.djs.includes(djInput)) {
      setEventData({ ...eventData, djs: [...eventData.djs, djInput] });
      setDjInput('');
    }
  };

  const handleDjRemove = (dj: string) => {
    setEventData({ ...eventData, djs: eventData.djs.filter((d) => d !== dj) });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const imageURL = await uploadImage();
    if (imageURL) {
      eventData.imageURL = imageURL;
    }

    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    };

    await fetch(`${APIBase}/client/event`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.success,
            text: t.eventCreated,
            icon: 'success',
            timer: 2000,
          });
          navigate('/app/admin/events');
        } else {
          Swal.fire({
            title: t.error,
            text: t.submitError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.submitError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const venueOptions = venues.map((venue: any) => ({
    value: venue._id,
    label: venue.name,
  }));

  const typeOptions = [
    { value: 'party', label: 'Party' },
    { value: 'karaoke', label: 'Karaoke' },
  ];

  const dressCodes = [
    t.noDressCode,
    t.casual,
    t.formal,
    t.smartCasual,
    t.business,
    t.blackTie,
    t.cocktail,
    t.costume,
    t.beach,
    t.festival,
    t.themed,
  ];

  const genreOptions = [
    'Reggaeton', 'Twerk', 'Salsa', 'Bachata', 'Kizomba', 'Merengue', 'Cumbia', 'Trap', 'Rap', 'House', 'EDM',
    'Pop', 'Rock', 'Hip-hop', 'Jazz', 'Classical', 'Country', 'Reggae', 'Blues', 'Soul', 'Funk', 'Disco',
    'Techno', 'Dubstep', 'Trance', 'Drum & Bass', 'Garage', 'Latin', 'Ska', 'Metal', 'Punk', 'R&B', 'Folk',
    'Gospel', 'Opera', 'World', 'K-Pop', 'J-Pop',
  ];

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.createEvent}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="title">{t.title}</label>
          <input
            type="text"
            id="title"
            name="title"
            value={eventData.title}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="description">{t.description}</label>
          <textarea
            id="description"
            name="description"
            value={eventData.description}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="date">{t.date}</label>
          <input
            type="date"
            id="date"
            name="date"
            value={eventData.date}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="time">{t.time}</label>
          <input
            type="time"
            id="time"
            name="time"
            value={eventData.time}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="startHour">{t.startHour}</label>
          <input
            type="time"
            id="startHour"
            name="startHour"
            value={eventData.startHour}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="endHour">{t.endHour}</label>
          <input
            type="time"
            id="endHour"
            name="endHour"
            value={eventData.endHour}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="imageUpload">{t.uploadImage}</label>
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            onChange={handleImageUpload}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="facebookLink">{t.facebookLink}</label>
          <input
            type="text"
            id="facebookLink"
            name="facebookLink"
            value={eventData.facebookLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="ticketLink">{t.ticketLink}</label>
          <input
            type="text"
            id="ticketLink"
            name="ticketLink"
            value={eventData.ticketLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="genres">{t.genres}</label>
          <div className="flex items-center">
            <input
              type="text"
              id="genres"
              name="genres"
              value={genreInput}
              onChange={(e) => setGenreInput(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 text-white rounded"
              onKeyDown={(e) => e.key === 'Enter' && (e.preventDefault(), handleGenreAdd())}
              list="genreOptions"
            />
            <datalist id="genreOptions">
              {genreOptions.map((genre) => (
                <option key={genre} value={genre} />
              ))}
            </datalist>
            <button
              type="button"
              onClick={handleGenreAdd}
              className="ml-2 px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
            >
              {t.add}
            </button>
          </div>
          <div className="flex flex-wrap mt-2">
            {eventData.genres.map((genre) => (
              <div key={genre} className="bg-gray-700 text-white rounded-full px-3 py-1 m-1 flex items-center">
                {genre}
                <FaTimes className="ml-2 cursor-pointer" onClick={() => handleGenreRemove(genre)} />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="dressCode">{t.dressCode}</label>
          <select
            id="dressCode"
            name="dressCode"
            value={eventData.dressCode}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          >
            <option value="">{t.selectDressCode}</option>
            {dressCodes.map((dressCode) => (
              <option key={dressCode} value={dressCode}>{dressCode}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="ageRestriction">{t.ageRestriction}</label>
          <select
            id="ageRestriction"
            name="ageRestriction"
            value={eventData.ageRestriction}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          >
            <option value="">{t.allAges}</option>
            <option value="18+">18+</option>
            <option value="21+">21+</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="djs">{t.djs}</label>
          <div className="flex items-center">
            <input
              type="text"
              id="djs"
              name="djs"
              value={djInput}
              onChange={(e) => setDjInput(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 text-white rounded"
              onKeyDown={(e) => e.key === 'Enter' && (e.preventDefault(), handleDjAdd())}
            />
            <button
              type="button"
              onClick={handleDjAdd}
              className="ml-2 px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
            >
              {t.add}
            </button>
          </div>
          <div className="flex flex-wrap mt-2">
            {eventData.djs.map((dj) => (
              <div key={dj} className="bg-gray-700 text-white rounded-full px-3 py-1 m-1 flex items-center">
                {dj}
                <FaTimes className="ml-2 cursor-pointer" onClick={() => handleDjRemove(dj)} />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="clubId">{t.clubId}</label>
          <Select
            id="clubId"
            name="clubId"
            value={venueOptions.find(option => option.value === eventData.clubId)}
            onChange={handleSelectChange}
            options={venueOptions}
            className="w-full text-black"
            placeholder={t.selectClub}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="type">{t.type}</label>
          <Select
            id="type"
            name="type"
            value={typeOptions.find(option => option.value === eventData.type)}
            onChange={(option) => setEventData({ ...eventData, type: option?.value || '' })}
            options={typeOptions}
            className="w-full text-black"
            placeholder={t.selectEventType}
            required
          />
        </div>
        <div className="mb-4 flex items-center">
          <label className="text-sm mr-2" htmlFor="verified">{t.verified}</label>
          <input
            type="checkbox"
            id="verified"
            name="verified"
            checked={eventData.verified}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        <div className="mb-4 flex items-center">
          <label className="text-sm mr-2" htmlFor="network">{t.network}</label>
          <input
            type="checkbox"
            id="network"
            name="network"
            checked={eventData.network}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.createEvent}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateEvent;
