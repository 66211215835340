import React, { FocusEvent, useState } from 'react';
import * as styledComponents from './styles';

interface InputProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: String;
  type?: String;
  value?: String | Number;
  required?: Boolean;
}

const Input: React.FC<InputProps> = ({ onChange, placeholder, type, value, required }) => {
  const [focus, setFocus] = useState(false);
  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(true);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(false);
  };

  return (
    <styledComponents.InputContainerLayers focus={focus}>
      <styledComponents.InputContainer focus={focus} />
      <styledComponents.InputSubContainer focus={focus} />
      <styledComponents.Input
        onFocus={handleOnFocus}
        onBlur={handleBlur}
        onChange={onChange}
        placeholder={placeholder || 'Write down here'}
        type={type}
        value={value}
        required={required}
      />
    </styledComponents.InputContainerLayers>
  );
};

export default Input;
