import sun3RemovebgPrev from 'assets/sun3RemovebgPrev.png';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const NavElements = () => {
  return (
    <nav className="w-full flex flex-col justify-center items-start h-full">
      <div className="fixed left-0 top-0 w-full flex items-center justify-center bg-opacity-70 backdrop-blur-md flex-row h-16 py-10">
        <a href="https://www.facebook.com/takitakikatowice" className="text-sm mx-5 text-white hidden md:block">
          FACEBOOK
        </a>
        <a href="https://www.tiktok.com/@takitakikatowice" className="text-sm mx-5 text-white hidden md:block">
          TIKTOK
        </a>
        <img alt="Logo Taki" className="h-20 hidden md:block" src={sun3RemovebgPrev} />
        <a href="https://www.instagram.com/takitakientertainment" className="text-sm mx-5 text-white hidden md:block">
          INSTAGRAM
        </a>
        <a href="https://chat.whatsapp.com/Kd6k8NtVI65H638LEiAY3Z" className="text-sm mx-5 text-white hidden md:block">
          WHATSAPP
        </a>
      </div>
    </nav>
  );
};

export default NavElements;
