import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { FaMusic, FaPeopleCarry, FaChartLine, FaCamera } from 'react-icons/fa';
import Typed from 'react-typed';

const Overview: React.FC = () => {
  const { language } = useLanguage();
  const goToTypeform = () => {
    window.open('https://fjvc5zlwg1q.typeform.com/to/r4ACfWs8');
  };

  const getTypedStrings = () => {
    switch (language) {
      case 'spanish':
        return [
          '¡Todo lo que necesitas para una noche de fiesta!',
          'Fiesta',
          'Karaoke',
          'Juegos',
          'Eventos',
          '¡y más!',
          '¡Todo lo que necesitas para una noche de fiesta!'
        ];
      case 'polish':
        return [
          'Wszystko, czego potrzebujesz na nocną imprezę!',
          'Impreza',
          'Karaoke',
          'Gry',
          'Wydarzenia',
          'i więcej!',
          'Wszystko, czego potrzebujesz na nocną imprezę!'
        ];
      default:
        return [
          'All what you need for a Party Night!',
          'Party',
          'Karaoke',
          'Games',
          'Events',
          'and more!',
          'All what you need for a Party Night!'
        ];
    }
  };

  return (
    <div
      className="w-full min-h-screen flex flex-col items-center  bg-cover bg-center overflow-y-scroll"
      style={{ backgroundImage: 'url("assets/back_facebook.jpg")' }}
    >
      <div className="w-full bg-black bg-opacity-80 flex items-center justify-center pt-20">
        <div className="w-full flex flex-col items-center px-4 sm:px-8 md:px-16 max-w-4xl">
          <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto">
            <div className="w-full flex items-center justify-center mb-4">
              <img alt="Logo Taki" src={'assets/sun3RemovebgPrev.png'} />
            </div>
            <div className="text-white text-xl md:text-3xl uppercase font-Khula max-w-90 px-10">
              <Typed
                strings={getTypedStrings()}
                typeSpeed={40}
              />
            </div>
            {language === 'english' && (
              <>
                <p className="text-lg md:text-xl text-white mb-8 mt-4">
                  We are Taki Entertainment, your go-to solution for unforgettable nightlife experiences. Whether you are looking for a dynamic club night, an intimate lounge experience, or a themed party, we have you covered. Our mission is to make every event a memorable one.
                </p>
                <img src="https://i.imgur.com/AA4xfz3.jpg" alt="Company Overview" className="w-full rounded-lg mb-6" />
              </>
            )}
            {language === 'spanish' && (
              <>
                <p className="text-lg md:text-xl text-white mb-8 mt-4">
                  Somos Taki Entertainment, tu solución ideal para experiencias nocturnas inolvidables. Ya sea que busques una noche dinámica en un club, una experiencia íntima en un lounge o una fiesta temática, lo tenemos todo cubierto. Nuestra misión es hacer que cada evento sea memorable.
                </p>
                <img src="https://i.imgur.com/AA4xfz3.jpg" alt="Company Overview" className="w-full rounded-lg mb-6" />
              </>
            )}
            {language === 'polish' && (
              <>
                <p className="text-lg md:text-xl text-white mb-8 mt-4">
                  Jesteśmy Taki Entertainment, Twoim niezawodnym partnerem w organizacji niezapomnianych doświadczeń nocnych. Niezależnie od tego, czy szukasz dynamicznej nocy klubowej, intymnej atmosfery w lounge, czy tematycznej imprezy, mamy wszystko, czego potrzebujesz. Naszą misją jest uczynienie każdego wydarzenia niezapomnianym.
                </p>
                <img src="https://i.imgur.com/AA4xfz3.jpg" alt="Company Overview" className="w-full rounded-lg mb-6" />
              </>
            )}
          </div>

          <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto mt-6">
            <h2 className="text-3xl text-white mb-4">{language === 'english' ? 'What We Do' : language === 'spanish' ? 'Qué Hacemos' : 'Co Robimy'}</h2>
            <div className="flex flex-wrap justify-center gap-6 mb-6">
              <div className="flex flex-col items-center text-white">
                <FaMusic className="text-6xl mb-2" />
                <p>{language === 'english' ? 'Top-notch Music & DJs' : language === 'spanish' ? 'Música y DJs de Primera' : 'Najlepsza Muzyka i DJ-e'}</p>
              </div>
              <div className="flex flex-col items-center text-white">
                <FaPeopleCarry className="text-6xl mb-2" />
                <p>{language === 'english' ? 'Corporate Events' : language === 'spanish' ? 'Eventos Corporativos' : 'Wydarzenia Firmowe'}</p>
              </div>
              <div className="flex flex-col items-center text-white">
                <FaChartLine className="text-6xl mb-2" />
                <p>{language === 'english' ? 'Marketing & Promotion' : language === 'spanish' ? 'Marketing y Promoción' : 'Marketing i Promocja'}</p>
              </div>
              <div className="flex flex-col items-center text-white">
                <FaCamera className="text-6xl mb-2" />
                <p>{language === 'english' ? 'Photography & Videography' : language === 'spanish' ? 'Fotografía y Videografía' : 'Fotografia i Wideografia'}</p>
              </div>
            </div>
          </div>

          <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto mt-6">
            <h2 className="text-3xl text-white mb-4">{language === 'english' ? 'What We Offer' : language === 'spanish' ? 'Qué Ofrecemos' : 'Co Oferujemy'}</h2>
            <div className="flex flex-col md:flex-row justify-between items-center mb-6">
              <div className="flex-1 p-4 text-white">
                <h3 className="text-2xl font-semibold mb-2">{language === 'english' ? 'For Individuals' : language === 'spanish' ? 'Para Individuos' : 'Dla Osób Indywidualnych'}</h3>
                <p>{language === 'english' ? 'Birthday parties, private gatherings, and more. We bring the party to you with professional DJs, vibrant decorations, and interactive activities.' : language === 'spanish' ? 'Fiestas de cumpleaños, reuniones privadas y más. Llevamos la fiesta a ti con DJs profesionales, decoraciones vibrantes y actividades interactivas.' : 'Przyjęcia urodzinowe, prywatne spotkania i inne. Przywozimy imprezę do Ciebie z profesjonalnymi DJ-ami, żywymi dekoracjami i interaktywnymi atrakcjami.'}</p>
              </div>
              <div className="flex-1 p-4 text-white">
                <h3 className="text-2xl font-semibold mb-2">{language === 'english' ? 'For Business Owners' : language === 'spanish' ? 'Para Propietarios de Negocios' : 'Dla Właścicieli Firm'}</h3>
                <p>{language === 'english' ? 'We collaborate with bars, clubs, and event organizers to create spectacular events. We offer marketing services, event planning, and the necessary equipment and personnel.' : language === 'spanish' ? 'Colaboramos con bares, clubes y organizadores de eventos para crear eventos espectaculares. Ofrecemos servicios de marketing, planificación de eventos y el equipo y personal necesarios.' : 'Współpracujemy z barami, klubami i organizatorami wydarzeń, aby tworzyć spektakularne wydarzenia. Oferujemy usługi marketingowe, planowanie wydarzeń oraz niezbędny sprzęt i personel.'}</p>
              </div>
            </div>
            <img src="https://i.imgur.com/KS7q7Y8.png" alt="Our Offerings" className="w-full rounded-lg mb-6" />
          </div>

          <div className="flex flex-col items-center justify-center my-8 w-full">
            <h2 className="text-2xl md:text-4xl text-white mb-4">{language === 'english' ? 'Contact us today to plan your perfect event!' : language === 'spanish' ? '¡Contáctanos hoy para planificar tu evento perfecto!' : 'Skontaktuj się z nami już dziś, aby zaplanować idealne wydarzenie!'}</h2>
            <Button
              variant="primary"
              className="bg-white text-black cursor-pointer p-3 rounded-lg w-full max-w-xs"
              onClick={goToTypeform}
              aria-label={language === 'english' ? 'Get a Free Quote' : language === 'spanish' ? 'Obtén una cotización gratuita' : 'Uzyskaj bezpłatną wycenę'}
            >
              {language === 'english' ? 'Get a Free Quote' : language === 'spanish' ? 'Obtén una cotización gratuita' : 'Uzyskaj bezpłatną wycenę'}
            </Button>
          </div>

          <div className="text-white text-center my-8">
            {language === 'english' && '#PartyPlanner #EventServices #CorporateEvents #BirthdayParties #DJService #EventEquipment #TakiEntertainment'}
            {language === 'spanish' && '#OrganizadorDeFiestas #ServiciosDeEventos #EventosCorporativos #FiestasDeCumpleaños #ServicioDeDJ #EquipoDeEventos #TakiEntertainment'}
            {language === 'polish' && '#OrganizatorImprez #UsługiEventowe #WydarzeniaFirmowe #Urodziny #DJService #SprzętEventowy #TakiEntertainment'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
