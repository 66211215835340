import { AuthContext } from 'Contexts/Auth';
import { APIBase } from 'api/hosts';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import { Event, Venue } from 'types';
import DateFilter from './DateFilter';
import EventList from './EventList';
import LowerBar from './LowerBar';
import MapComponent from './MapComponent';

const FilteredEvents: React.FC = () => {
  const { currentToken } = useContext(AuthContext);
  const [events, setEvents] = useState<Event[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));
  const [selectedClub, setSelectedClub] = useState<string | null>(null);
  const [isDateFilterModalOpen, setIsDateFilterModalOpen] = useState(false);
  const eventListRef = useRef<HTMLDivElement>(null); // Create a ref for the EventList

  useEffect(() => {
    fetchEvents();
    fetchVenues();
  }, [currentToken]);

  const fetchEvents = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/event`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error fetching events');
        }
      })
      .then((responseJson) => {
        setEvents(responseJson);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch events. Please try again later.',
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const fetchVenues = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error fetching venues');
        }
      })
      .then((responseJson) => {
        setVenues(responseJson);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch venues. Please try again later.',
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const resetTimeToMidnight = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const filteredEvents = events.filter((event) => {
    const matchesSelectedClub = selectedClub === null || event.clubId?._id === selectedClub;
    return matchesSelectedClub;
  });

  const filteredVenues = venues.filter((venue) =>
    events.some((event) => {
      const eventDate = new Date(event.date || '');
      const isWithinDateRange =
        eventDate >= resetTimeToMidnight(startDate) && eventDate <= new Date(endDate.setHours(23, 59, 59, 999));

      return isWithinDateRange && event.clubId?._id === venue._id;
    }),
  );

  const handleMarkerClick = (clubId: string | null) => {
    setSelectedClub(clubId);
    // Scroll to the top of the EventList when a club is selected
    if (eventListRef.current) {
      eventListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="w-screen flex flex-col justify-start items-center bg-black">
      <Modal
        isOpen={isDateFilterModalOpen}
        onRequestClose={() => setIsDateFilterModalOpen(false)}
        contentLabel="Date Filter"
        className="fixed inset-0 flex items-center justify-center z-50 py-8"
        overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-40"
        style={{
          content: {
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '20%',
            backgroundColor: 'black',
            borderRadius: '10px',
            padding: '20px',
          },
        }}
      >
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleCloseModal={() => setIsDateFilterModalOpen(false)}
        />
      </Modal>
      <MapComponent venues={filteredVenues} onMarkerClick={handleMarkerClick} selectedClub={selectedClub} />
      <div className="w-full pb-10" style={{ paddingTop: '50vh' }} ref={eventListRef}>
        <EventList events={filteredEvents} singleList={true} />
      </div>
      <LowerBar />
    </div>
  );
};

export default FilteredEvents;
