import React from 'react';
import * as styledComponents from './styles';

type ButtonProps = {
  onClick?: (e: React.MouseEventHandler<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  autofocus?: Boolean;
};

const Button: React.FC<ButtonProps> = ({ onClick, children, autofocus }) => {
  return (
    <styledComponents.ButtonLayersContainer>
      <styledComponents.ButtonLayerA />
      <styledComponents.ButtonLayerB />
      <styledComponents.Button onClick={onClick} autofocus={autofocus}>
        {children}
      </styledComponents.Button>
    </styledComponents.ButtonLayersContainer>
  );
};

export default Button;
