import React from 'react';
import * as styledComponents from './styles';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Typed from 'react-typed';
const Home = () => {
  const goToEvents = () => {
    window.open('https://www.facebook.com/takitakikatowice/events');
  };

  return (
    <styledComponents.HomeContainer>
      <styledComponents.HeroContainer>
        <img alt="Logo Taki" className="h-60" src={'assets/sun3RemovebgPrev.png'} />
        <div className="text-white text-xl md:text-3xl uppercase Khula max-w-90 px-10">
          <Typed
            strings={[
              'All what you need for a Party Night!',
              'Party',
              'Karaoke',
              'Games',
              'Events',
              'and more!',
              'All what you need for a Party Night!',
            ]}
            typeSpeed={40}
          />
        </div>
      </styledComponents.HeroContainer>
      {/* <styledComponents.NewsContainer className="p-5 w-3/4 md:w-2/5">
        <div className="text-base">JOIN OUR WHATSAPP CHANNEL AND RECEIVE SPECIAL OFFERS, DISCOUNTS AND MORE!</div>
        <styledComponents.ButtonNews href="https://chat.whatsapp.com/Kd6k8NtVI65H638LEiAY3Z" className="text-sm mt-4">
          JOIN OUR WHATSAPP
        </styledComponents.ButtonNews>
      </styledComponents.NewsContainer> */}
      <styledComponents.BarContainer>
        <div className="flex flex-col md:flex-row items-center justify-center " style={{ marginTop: '-120px' }}>
          <Card style={{ width: '18rem' }} className="m-5 bg-black rounded-lg">
            <Card.Img variant="top" src="assets/party_room.jpg" className="rounded-t-lg" />
            <Card.Body>
              <Card.Title className="text-white w-full flex items-center justify-center uppercase mt-3 text-xl">
                Sala Imprez
              </Card.Title>
              <Card.Text className="text-white p-3 w-full">
                Zanurz się w świecie ekscytujących rytmów i pulsujących bitów w Sali 1, naszym dynamicznym pokoju
                imprezowym. Przyjdź i zgub się w muzyce, tańcząc do białego rana!
              </Card.Text>
              <Button
                variant="primary"
                className="bg-white text-black mx-3 cursor-pointer p-3 rounded-lg w-full"
                onClick={goToEvents}
              >
                Sprawdź wydarzenia
              </Button>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem' }} className="m-5 bg-black rounded-lg">
            <Card.Img variant="top" src="assets/games_room.jpg" className="rounded-t-lg" />
            <Card.Body>
              <Card.Title className="text-white w-full flex items-center justify-center uppercase mt-3 text-xl">
                Sala Chill & Gry
              </Card.Title>
              <Card.Text className="text-white p-3">
                Znajdziesz tu wygodne siedzenia, gry planszowe i arcade oraz szeroki wybór napojów. Miejsce idealne do
                naładowania baterii!Znajdziesz tu wygodne siedzenia, gry planszowe i arcade oraz szeroki wybór napojów.
                Miejsce idealne do naładowania baterii!
              </Card.Text>
              <Button
                variant="primary"
                className="bg-white text-black mx-3 cursor-pointer p-3 rounded-lg w-full"
                onClick={goToEvents}
              >
                Sprawdź wydarzenia
              </Button>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem' }} className="m-5 bg-black rounded-lg">
            <Card.Img variant="top" src="assets/karaoke_room.jpg" className="rounded-t-lg" />
            <Card.Body>
              <Card.Title className="text-white w-full flex items-center justify-center uppercase mt-3  text-xl">
                Sala Karaoke
              </Card.Title>
              <Card.Text className="text-white p-3">
                Wyposażona w profesjonalny sprzęt do karaoke, umożliwi Ci śpiewanie swoich ulubionych utworów. Świetne
                miejsce, aby pokazać swoje talenty!{' '}
              </Card.Text>
              <Button
                variant="primary"
                className="bg-white text-black mx-3 cursor-pointer p-3 rounded-lg w-full"
                onClick={goToEvents}
              >
                Sprawdź wydarzenia
              </Button>
            </Card.Body>
          </Card>
        </div>
      </styledComponents.BarContainer>
      <styledComponents.TakitokContainer>
        <div className="flex flex-col max-w-4xl px-6">
          <div className="text-white w-full flex items-center justify-center uppercase text-3xl mt-28">
            Takiton: Latino & International Music Bash
          </div>
          <div className="flex flex-col md:flex-row items-start justify-start">
            <div className="text-white w-full flex items-center justify-center mt-3  text-lg md:mt-20">
              Zanurz się w nocy, gdzie Latino i rytmy świata zderzają się! 🎵 To nie jest zwykła impreza muzyczna, to
              LATINO i MIĘDZYNARODOWA BASH MUZYCZNA w mieście! Od pulsujących rytmów Ameryki Łacińskiej do
              elektryzujących dźwięków sceny międzynarodowej - mamy to wszystko! Nasz line-up prezentuje NAJLEPSZYCH
              DJ-ów w mieście 🎧, którzy kręcą najgorętsze kawałki, które sprawią, że zatańczysz jakby nie było jutra.
              💃🕺 Ale to nie wszystko! Na Takiton chodzi o coś więcej niż tylko muzykę. Spodziewaj się eksplozji
              kultury, z żywymi grami, kuszącymi niespodziankami i publicznością, która potrafi się bawić! 🎉 Zaznacz w
              kalendarzu i przygotuj się na zanurzenie w magii muzyki na Takiton! Przygotuj się na noc niezapomnianych
              melodii i hipnotyzujących bitów. Dołącz do nas na noc jedności, rytmu i czystego świętowania. Takiton -
              tam, gdzie muzyka nie zna granic. 🗓️ Data: 13.10.23 🕗 Godzina: 21:00 📍 Miejsce: Królestwo, Rondo im,
              rondo Generała Jerzego Ziętka 1 Kup już teraz swoje bilety i sprawmy, że Takiton będzie nocą do
              zapamiętania! Informacje o biletach i składzie DJów wkrótce!{' '}
            </div>
            <iframe
              style={{
                zIndex: 16777271,
                border: '0px none transparent',
                overflow: 'hidden auto',
                margin: '0px',
                padding: '0px',
                width: '100%',
                height: '830px'
              }}
              src={
                'https://assets.ticketinghub.com/checkout/index-cfceb503279e4c2005fe58583a0178a487ef4c27.html#options=%7B%22context%22%3A%22customer%22%2C%22layout%22%3A%22button%22%2C%22landing%22%3A%22tickets%22%2C%22color%22%3A%22%23ffffff%22%2C%22footer%22%3Atrue%2C%22discounts%22%3Atrue%2C%22subscribe%22%3Atrue%2C%22avs%22%3Afalse%2C%22free%22%3Afalse%2C%22referral-code-param-name%22%3A%22th-reseller-ref%22%2C%22coupon-param-name%22%3A%22th-coupon%22%2C%22fields%22%3A%22name%2Cemail%2Ctelephone%22%2C%22event-period%22%3A%227%22%2C%22events-view-mode%22%3A%22one-day%22%2C%22collect-voucher-recipient-info%22%3Atrue%2C%22button-label%22%3A%22BOOK%20NOW%22%2C%22social-sharing%22%3Afalse%2C%22book-more%22%3Afalse%2C%22book-more-if-tickets-limit-reached%22%3Afalse%2C%22show-product-logo%22%3Afalse%2C%22combination-discounts%22%3Atrue%2C%22show-product-description%22%3Afalse%2C%22gift-cards%22%3Atrue%2C%22ga-track-pageviews%22%3Atrue%2C%22ga-track-purchases%22%3Atrue%2C%22extras-right-after-tickets%22%3Afalse%2C%22events-show-product-description%22%3Afalse%2C%22display-currency-as-symbol%22%3Atrue%2C%22extra-tickets%22%3Afalse%2C%22queueit%22%3Afalse%2C%22read-only-api%22%3Afalse%2C%22hide-option-prices%22%3Afalse%2C%22channel%22%3A%22389b840b-7be7-4d28-b5c5-658c2992f4a1%22%2C%22name%22%3A%22Takiton%22%2C%22products_enabled%22%3Atrue%2C%22products%22%3A%2237e7bc5e-b259-44f8-96bb-95ed078e91a1%22%2C%22widget%22%3A%225e2975c3-3360-44db-ae91-6d252f855ea0%22%2C%22react-helmet%22%3Atrue%2C%22referral-code%22%3Anull%2C%22origin%22%3A%22https%3A%2F%2Fmaster--takientertainment.netlify.app%22%2C%22parentWidth%22%3A1920%7D'
              }
            ></iframe>
          </div>
        </div>
      </styledComponents.TakitokContainer>
    </styledComponents.HomeContainer>
  );
};
export default Home;
