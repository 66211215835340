import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaEllipsisV, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { StageSpinner } from 'react-spinners-kit';

const translations = {
  english: {
    events: 'Events',
    newEvent: 'New Event',
    edit: 'Edit',
    view: 'View',
    delete: 'Delete',
    deleted: 'Deleted!',
    deletedMessage: 'Event has been deleted.',
    error: 'Error',
    deleteError: 'Failed to delete event. Please try again later.',
    fetchError: 'Error fetching events',
    retryLater: 'Please try again later 😞',
    confirmDelete: 'Are you sure?',
    confirmDeleteText: "You won't be able to revert this!",
    confirmDeleteYes: 'Yes, delete it!',
  },
  spanish: {
    events: 'Eventos',
    newEvent: 'Nuevo Evento',
    edit: 'Editar',
    view: 'Ver',
    delete: 'Eliminar',
    deleted: '¡Eliminado!',
    deletedMessage: 'El evento ha sido eliminado.',
    error: 'Error',
    deleteError: 'Error al eliminar el evento. Por favor, inténtelo de nuevo más tarde.',
    fetchError: 'Error al obtener eventos',
    retryLater: 'Por favor, inténtelo de nuevo más tarde 😞',
    confirmDelete: '¿Estás seguro?',
    confirmDeleteText: '¡No podrás revertir esto!',
    confirmDeleteYes: 'Sí, eliminarlo!',
  },
  polish: {
    events: 'Wydarzenia',
    newEvent: 'Nowe Wydarzenie',
    edit: 'Edytuj',
    view: 'Zobacz',
    delete: 'Usuń',
    deleted: 'Usunięto!',
    deletedMessage: 'Wydarzenie zostało usunięte.',
    error: 'Błąd',
    deleteError: 'Nie udało się usunąć wydarzenia. Spróbuj ponownie później.',
    fetchError: 'Błąd pobierania wydarzeń',
    retryLater: 'Spróbuj ponownie później 😞',
    confirmDelete: 'Jesteś pewien?',
    confirmDeleteText: 'Nie będziesz mógł tego cofnąć!',
    confirmDeleteYes: 'Tak, usuń to!',
  },
};

const EventList: React.FC = () => {
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchEvents();
  }, [currentToken]);

  const fetchEvents = async () => {
    setLoading(true); // Start loading
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/event`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.fetchError);
        }
      })
      .then((responseJson) => {
        const sortedEvents = responseJson.sort((a: any, b: any) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
          const dateB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
          return dateB - dateA;
        });
        setEvents(sortedEvents);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.retryLater,
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  const handleDelete = async (eventId: string) => {
    const data: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ eventId }),
    };
    await fetch(`${APIBase}/client/event`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.deleted,
            text: t.deletedMessage,
            icon: 'success',
            timer: 2000,
          });
          fetchEvents();
        } else {
          Swal.fire({
            title: t.error,
            text: t.deleteError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.deleteError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const confirmDelete = (eventId: string) => {
    Swal.fire({
      title: t.confirmDelete,
      text: t.confirmDeleteText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t.confirmDeleteYes,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(eventId);
      }
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuItemClick = (callback: () => void) => {
    callback();
    setDropdownOpen(null);
  };

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t.events}</h2>
        <button
          onClick={() => navigate('/app/admin/new-event')}
          className="bg-gradient-to-r from-black via-gray-800 to-black text-white p-3 rounded-full flex items-center justify-center shadow-lg hover:bg-gradient-to-r hover:from-gray-700 hover:via-gray-800 hover:to-gray-900 transition-all duration-300 ease-in-out border border-neon-blue"
        >
          <FaPlus className="text-2xl" />
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <StageSpinner size={50} color="#ffffff" />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {events.map((event: any) => (
            <div
              key={event._id}
              className="relative bg-gradient-to-b from-black via-gray-800 to-gray-900 p-4 rounded-lg shadow-lg border border-gray-700 hover:border-neon-purple transition-all duration-300 ease-in-out cursor-pointer"
            >
              <button
                onClick={() => setDropdownOpen(dropdownOpen === event._id ? null : event._id)}
                className="absolute top-2 right-2 p-2 rounded-full text-neon-purple hover:text-neon-pink transition-colors duration-300 border border-neon-purple shadow-lg"
              >
                <FaEllipsisV className="text-xl" />
              </button>
              {dropdownOpen === event._id && (
                <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-10" ref={dropdownRef}>
                  <button
                    onClick={() => handleMenuItemClick(() => navigate(`/app/admin/edit-event/${event._id}`))}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                  >
                    {t.edit}
                  </button>
                  <button
                    onClick={() => handleMenuItemClick(() => navigate(`/app/admin/view-event/${event._id}`))}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                  >
                    {t.view}
                  </button>
                  <button
                    onClick={() => handleMenuItemClick(() => confirmDelete(event._id))}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                  >
                    {t.delete}
                  </button>
                </div>
              )}
              <div onClick={() => navigate(`/app/admin/view-event/${event._id}`)}>
                <h3 className="text-lg font-semibold mb-2">{event.title}</h3>
                {event.imageURL && (
                  <img src={event.imageURL} alt={event.title} className="w-full h-32 object-cover rounded-md mt-2" />
                )}
                <p className="text-sm text-gray-400">
                  {moment(event.date).format('MMMM Do, YYYY')}
                  {event.startHour && event.endHour
                    ? ` | ${moment(event.startHour, 'HH:mm').format('h:mm A')} - ${moment(event.endHour, 'HH:mm').format(
                        'h:mm A',
                      )}`
                    : ''}
                </p>
                <p className="text-sm text-gray-500">
                  {event.description.length > 100 ? `${event.description.substring(0, 100)}...` : event.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventList;
