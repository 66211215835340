import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import Button from 'react-bootstrap/Button';

const Home: React.FC = () => {
  const { language } = useLanguage();
  const goToTypeform = () => {
    window.open('https://fjvc5zlwg1q.typeform.com/to/r4ACfWs8');
  };

  return (
    <div
      className="w-full min-h-screen flex flex-col items-center  bg-cover bg-center overflow-y-scroll"
      style={{ backgroundImage: 'url("assets/back_facebook.jpg")' }}
    >
      <div className="w-full bg-black bg-opacity-80 flex items-center justify-center pt-20">
        <div className="w-full flex flex-col items-center px-4 sm:px-8 md:px-16 max-w-4xl">
          {language === 'english' && (
            <>
              {/* Services Section */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto">
              <div className='w-full flex items-center justify-center'>
              <img alt="Logo Taki" src={'assets/sun3RemovebgPrev.png'} />

              </div>

                <h2 className="text-2xl md:text-4xl text-white mb-4">
                 All You Need for a Party Night!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  Do you have a birthday party, a corporate integration event, or just an evening where you don't know
                  what to do?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  Don't worry, we can handle it for you! Taki Entertainment provides everything you need for a
                  spectacular night:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>🎧 Professional DJs</li>
                  <li>🎶 Top-notch Music Equipment</li>
                  <li>🌟 Dynamic Light Equipment</li>
                  <li>🍹 Drink Animations</li>
                  <li>🎉 Thematic Decorations</li>
                  <li>🎤 Engaging Hosts</li>
                  <li>🕺 Interactive Activities</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  Let us transform your event into an unforgettable experience!
                </p>
              </div>

              {/* Call to Action Section */}
              <div className="flex flex-col items-center justify-center my-8 w-full">
                <h2 className="text-2xl md:text-4xl text-white mb-4">Contact us today to plan your perfect event!</h2>
                <Button
                  variant="primary"
                  className="bg-white text-black cursor-pointer p-3 rounded-lg w-full max-w-xs"
                  onClick={goToTypeform}
                  aria-label="Get a Free Quote"
                >
                  Get a Free Quote
                </Button>
              </div>

              {/* Hashtags */}
              <div className="text-white text-center my-8">
                #PartyPlanner #EventServices #CorporateEvents #BirthdayParties #DJService #EventEquipment
                #TakiEntertainment
              </div>
            </>
          )}

          {language === 'polish' && (
            <>
              {/* Polish Services Section */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full">
                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  Taki Entertainment - Wszystko, czego potrzebujesz na nocną imprezę!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  Masz urodziny, integrację w pracy lub wieczór, w którym nie wiesz, co zrobić?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  Nie martw się, zrobimy to za Ciebie! Taki Entertainment zapewnia wszystko, czego potrzebujesz na
                  niezapomnianą noc:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>🎧 Profesjonalni DJ-e</li>
                  <li>🎶 Najlepszy sprzęt muzyczny</li>
                  <li>🌟 Dynamiczne oświetlenie</li>
                  <li>🍹 Animacje drinków</li>
                  <li>🎉 Tematyczne dekoracje</li>
                  <li>🎤 Zabawni prowadzący</li>
                  <li>🕺 Interaktywne atrakcje</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  Pozwól nam przekształcić Twoje wydarzenie w niezapomniane doświadczenie!
                </p>
              </div>

              {/* Call to Action Section for Polish Version */}
              <div className="flex flex-col items-center justify-center my-8 w-full">
                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  Skontaktuj się z nami już dziś, aby zaplanować idealne wydarzenie!
                </h2>
                <Button
                  variant="primary"
                  className="bg-white text-black cursor-pointer p-3 rounded-lg w-full max-w-xs"
                  onClick={goToTypeform}
                  aria-label="Uzyskaj bezpłatną wycenę"
                >
                  Uzyskaj bezpłatną wycenę
                </Button>
              </div>

              {/* Hashtags */}
              <div className="text-white text-center my-8">
                #OrganizatorImprez #UsługiEventowe #WydarzeniaFirmowe #Urodziny #DJService #SprzętEventowy
                #TakiEntertainment
              </div>
            </>
          )}

          {language === 'spanish' && (
            <>
              {/* Spanish Services Section */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full">
                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  Taki Entertainment - Todo lo que necesitas para una noche de fiesta!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  ¿Tienes una fiesta de cumpleaños, un evento de integración corporativa o simplemente una noche en la
                  que no sabes qué hacer?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  No te preocupes, ¡nosotros lo manejamos por ti! Taki Entertainment proporciona todo lo que necesitas
                  para una noche espectacular:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>🎧 DJs profesionales</li>
                  <li>🎶 Equipo de música de primera clase</li>
                  <li>🌟 Equipos de iluminación dinámicos</li>
                  <li>🍹 Animaciones de bebidas</li>
                  <li>🎉 Decoraciones temáticas</li>
                  <li>🎤 Anfitriones atractivos</li>
                  <li>🕺 Actividades interactivas</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  ¡Permítenos transformar tu evento en una experiencia inolvidable!
                </p>
              </div>

              {/* Call to Action Section for Spanish Version */}
              <div className="flex flex-col items-center justify-center my-8 w-full">
                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  ¡Contáctanos hoy para planificar tu evento perfecto!
                </h2>
                <Button
                  variant="primary"
                  className="bg-white text-black cursor-pointer p-3 rounded-lg w-full max-w-xs"
                  onClick={goToTypeform}
                  aria-label="Obtén una cotización gratuita"
                >
                  Obtén una cotización gratuita
                </Button>
              </div>

              {/* Hashtags */}
              <div className="text-white text-center my-8">
                #OrganizadorDeFiestas #ServiciosDeEventos #EventosCorporativos #FiestasDeCumpleaños #ServicioDeDJ
                #EquipoDeEventos #TakiEntertainment
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
