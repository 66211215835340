import { APIBase } from 'api/hosts';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useLanguage } from 'Contexts/LanguageContext';

const translations = {
  english: {
    cityDetails: 'City Details',
    name: 'Name',
    country: 'Country',
    latitude: 'Latitude',
    longitude: 'Longitude',
    editCity: 'Edit City',
    loading: 'Loading...',
    error: 'Error',
    fetchError: 'Failed to fetch city details. Please try again later.',
  },
  spanish: {
    cityDetails: 'Detalles de la Ciudad',
    name: 'Nombre',
    country: 'País',
    latitude: 'Latitud',
    longitude: 'Longitud',
    editCity: 'Editar Ciudad',
    loading: 'Cargando...',
    error: 'Error',
    fetchError: 'No se pudieron obtener los detalles de la ciudad. Inténtalo de nuevo más tarde.',
  },
  polish: {
    cityDetails: 'Szczegóły Miasta',
    name: 'Nazwa',
    country: 'Kraj',
    latitude: 'Szerokość geograficzna',
    longitude: 'Długość geograficzna',
    editCity: 'Edytuj Miasto',
    loading: 'Ładowanie...',
    error: 'Błąd',
    fetchError: 'Nie udało się pobrać szczegółów miasta. Spróbuj ponownie później.',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const CityDetail: React.FC = () => {
  const { cityId } = useParams<{ cityId: string }>();
  const [city, setCity] = useState<any>(null);
  const { language } = useLanguage();
  const t = translations[language as Language];
  const navigate = useNavigate();

  useEffect(() => {
    fetchCity();
  }, [cityId]);

  const fetchCity = async () => {
    try {
      const response = await fetch(`${APIBase}/city/${cityId}`);
      const data = await response.json();
      setCity(data);
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  if (!city) {
    return <div>{t.loading}</div>;
  }

  return (
    <div className="text-white p-4 pb-28">
      <h2 className="text-2xl mb-4">{t.cityDetails}</h2>
      <p>
        <strong>{t.name}:</strong> {city.name}
      </p>
      <p>
        <strong>{t.country}:</strong> {city.country.name}
      </p>
      <p>
        <strong>{t.latitude}:</strong> {city.lat}
      </p>
      <p>
        <strong>{t.longitude}:</strong> {city.lng}
      </p>
      <button
        onClick={() => navigate(`/app/admin/edit-city/${cityId}`)}
        className="mt-4 bg-blue-600 text-white px-4 py-2 rounded"
      >
        {t.editCity}
      </button>
    </div>
  );
};

export default CityDetail;
