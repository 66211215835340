import React from 'react';

type VideoObject = {
  title: string;
  url: string;
};

interface VideoGalleryProps {
  videos: VideoObject[];
}

const renderVideo = (video: VideoObject, idx: number) => (
  <div
    key={idx}
    className="relative overflow-hidden rounded-md shadow-lg hover:shadow-xl transition-shadow duration-300 w-full"
    style={{ width: '100%', height: 'auto', paddingBottom: '56.25%' }}
  >
    <iframe
      title={video.title}
      src={video.url}
      frameBorder="0"
      allowFullScreen
      className="absolute inset-0 w-full h-full object-cover"
    ></iframe>
    <div className="absolute bottom-0 bg-black bg-opacity-50 text-white text-sm p-2">{video.title}</div>
  </div>
);

const VideoGallery: React.FC<VideoGalleryProps> = ({ videos }) => {
  return (
    <div className="p-4 md:p-8 bg-opacity-80 bg-black rounded-lg flex flex-col items-center justify-center w-screen">
      <h2
        className="mb-5 pt-20 flex items-end text-white text-xl md:text-3xl uppercase font-Khula max-w-90 px-10"
        style={{ width: '900px', maxWidth: '100%' }}
      >
        A PLACE WHERE YOU ACTUALLY MAKE FRIENDS!
      </h2>
      <div className="flex flex-col md:flex-row gap-4" style={{ width: '900px', maxWidth: '100%' }}>
        {videos.slice(0, 4).map((video, idx) => renderVideo(video, idx))}
      </div>
      <div className="flex-row w-full gap-4" style={{ maxWidth: '900px' }}>
        <div
          className="relative overflow-hidden rounded-md shadow-lg hover:shadow-xl transition-shadow duration-300 w-full h-96 mb-24"
          style={{ paddingBottom: '56.25%' }}
        >
          <iframe
            title={videos[4].title}
            src={videos[4].url}
            frameBorder="0"
            allowFullScreen
            className="absolute inset-0 w-full h-full object-cover"
          ></iframe>
          <div className="absolute bottom-0 bg-black bg-opacity-50 text-white text-sm p-2">Taki Sessions #2</div>
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
