import React from 'react';
import Chip from '@material-ui/core/Chip';

interface VenueTypeFilterProps {
  selectedVenueTypes: string[];
  setSelectedVenueTypes: (types: string[]) => void;
}

const venueTypes = ['Club', 'Bar', 'Lounge', 'Rooftop', 'Underground', 'Outdoor', 'Apartment', 'Hotel'];

const VenueTypeFilter: React.FC<VenueTypeFilterProps> = ({ selectedVenueTypes, setSelectedVenueTypes }) => {
  const handleVenueTypeChange = (type: string) => {
    if (selectedVenueTypes.includes(type)) {
      setSelectedVenueTypes(selectedVenueTypes.filter((t) => t !== type));
    } else {
      setSelectedVenueTypes([...selectedVenueTypes, type]);
    }
  };

  const chipStyles = {
    fontSize: '0.75rem', // Smaller font size
    padding: '2px 6px', // Smaller padding
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Transparent background
    color: 'white', // Text color
    margin: '2px', // Margin between chips
    cursor: 'pointer',
  };

  return (
    <div className="flex flex-col items-start">
      <h4 className="text-white mb-2">Venue Type</h4>
      <div className="flex flex-wrap gap-2">
        {venueTypes.map((type) => (
          <Chip
            key={type}
            label={type}
            style={{
              ...chipStyles,
              backgroundColor: selectedVenueTypes.includes(type) ? 'rgba(0, 0, 255, 0.5)' : 'rgba(255, 255, 255, 0.2)',
            }}
            onClick={() => handleVenueTypeChange(type)}
          />
        ))}
      </div>
    </div>
  );
};

export default VenueTypeFilter;
