import { AuthContext } from 'Contexts/Auth'; // To get the current token
import { APIBase } from 'api/hosts'; // API base URL
import { useContext, useEffect, useState } from 'react';
import { FaCamera } from 'react-icons/fa'; // For camera toggle
import { QrReader } from 'react-qr-reader'; // For scanning QR codes
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit'; // Loading spinner
import Swal from 'sweetalert2';

const ScanDiscount = () => {
  const [scanning, setScanning] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cameraError, setCameraError] = useState(false); // Handle camera errors
  const [isBackCamera, setIsBackCamera] = useState(true); // State to toggle between cameras
  const [scannedData, setScannedData] = useState(null); // Store detected QR data for manual confirmation
  const { currentToken } = useContext(AuthContext);
  const navigate = useNavigate();

  // Check camera permissions on component mount
  const checkCameraPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop()); // Stop the stream once permissions are granted
    } catch (err) {
      console.error('Error accessing camera:', err);
      if (err.name === 'NotAllowedError') {
        setError('Camera access denied. Please enable camera permissions.');
        Swal.fire({
          title: 'Camera Access Denied',
          text: 'Please enable camera permissions in your browser settings to use the QR scanner.',
          icon: 'error',
        });
      } else {
        setError('Error accessing camera: ' + err.message);
        Swal.fire({
          title: 'Camera Error',
          text: 'An error occurred while accessing the camera: ' + err.message,
          icon: 'error',
        });
      }
      setCameraError(true);
    }
  };

  useEffect(() => {
    checkCameraPermissions();
  }, []);

  // Process the scanned QR code
  // Inside processScan function
  const processScan = async () => {
    setLoading(true); // Start loading spinner

    try {
      const { venueId, menuItemId, pointsUsed, priceWithDiscount, activityId, userId } = scannedData; // Replace discountPercentage with priceWithDiscount

      // Determine if it's an activity or discount QR
      if (activityId) {
        // Process activity QR code (Add points for the activity)
        const response = await fetch(`${APIBase}/client/activity/redeem`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${currentToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            venueId,
            activityId,
            pointsEarned: pointsUsed, // Points earned for the activity
            userId, // Include user ID from scanned QR code
          }),
        });

        const result = await response.json();

        if (response.ok) {
          Swal.fire({
            title: 'Success!',
            text: `You earned ${pointsUsed} points for the activity!`,
            icon: 'success',
            timer: 2000,
          }).then(() => navigate('/app/admin/scan-discount'));
        } else {
          if (result.message === 'You have already redeemed points for this activity within the last 12 hours.') {
            // Handle the 12-hour restriction message
            Swal.fire({
              title: 'Already Redeemed',
              text: result.message,
              icon: 'info',
              timer: 3000,
            });
          } else {
            throw new Error(result.message || 'Failed to process activity');
          }
        }
      } else {
        // Process discount QR code
        const response = await fetch(`${APIBase}/client/discount/redeem`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${currentToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            venueId,
            menuItemId,
            pointsUsed,
            priceWithDiscount,
            userId,
          }),
        });

        const result = await response.json();

        if (response.ok) {
          Swal.fire({
            title: 'Success!',
            text: result.message,
            icon: 'success',
            timer: 2000,
          }).then(() => navigate('/app/admin/scan-discount'));
        } else {
          throw new Error(result.message || 'Failed to redeem discount');
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to process the QR code. Please try again.',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false); // Stop loading spinner
      setScanning(false); // Stop scanning after success or error
    }
  };

  const handleError = (err) => {
    setError('An error occurred during scanning. Please try again.');
    setCameraError(true); // Set the camera error state if there is an issue with the camera
    console.error(err);
  };

  const toggleCamera = () => {
    setIsBackCamera((prev) => !prev); // Toggle between front and back camera
    setCameraError(false); // Reset camera error when switching
  };

  const previewStyle = {
    height: 320,
    width: 320,
    borderRadius: '10px',
    overflow: 'hidden',
    border: '2px solid #86ff0d',
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen bg-gray-900 px-4 pt-28">
      {loading ? (
        <div className="flex flex-col items-center justify-center">
          <StageSpinner size={60} color="#86ff0d" />
          <p className="text-white mt-4">Processing QR Code...</p>
        </div>
      ) : scanning ? (
        <>
          <h2 className="text-2xl text-white mb-4">Scan Discount or Activity QR Code</h2>
          <div className="relative w-full h-56">
            {!cameraError ? (
              <QrReader
                delay={300}
                constraints={{ facingMode: isBackCamera ? 'environment' : 'user' }} // Toggle front or back camera
                style={previewStyle}
                onError={handleError}
                onResult={(result) => {
                  if (result) {
                    try {
                      const data = JSON.parse(result.text);
                      // Store the scanned data and stop scanning to show confirmation button
                      setScannedData(data);
                      setScanning(false);
                    } catch (e) {
                      Swal.fire({
                        title: 'Error',
                        text: 'Invalid QR code format.',
                        icon: 'error',
                        timer: 3000,
                      });
                    }
                  }
                }}
              />
            ) : (
              <p className="text-red-500">Camera access denied or not available. Please check your permissions.</p>
            )}
            <button
              onClick={toggleCamera}
              className="absolute top-0 right-0 bg-gray-800 text-white p-2 rounded-md flex items-center"
            >
              <FaCamera className="mr-2" />
              {isBackCamera ? 'Use Front Camera' : 'Use Back Camera'}
            </button>
          </div>
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </>
      ) : (
        <div className="text-white">
          {scannedData ? (
            <>
              <h3 className="text-xl mb-4">QR Code Detected!</h3>
              <p>
                <strong>Venue ID:</strong> {scannedData.venueId}
              </p>
              {scannedData.activityId ? (
                <>
                  <p>
                    <strong>Activity ID:</strong> {scannedData.activityId}
                  </p>
                  <p>
                    <strong>Points Earned:</strong> {scannedData.pointsUsed}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Menu Item ID:</strong> {scannedData.menuItemId}
                  </p>
                  <p>
                    <strong>Points Used:</strong> {scannedData.pointsUsed}
                  </p>
                  <p>
                    <strong>Price with Discount:</strong> {scannedData.priceWithDiscount}
                  </p>
                </>
              )}
              <p>
                <strong>User ID:</strong> {scannedData.userId}
              </p>

              <button
                className="mt-6 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                onClick={processScan}
              >
                {scannedData.activityId ? 'Add Points for Activity' : 'Redeem Discount'}
              </button>
              <button
                className="mt-6 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 ml-4"
                onClick={() => setScanning(true)} // Allow to re-scan
              >
                Re-scan QR Code
              </button>
            </>
          ) : (
            <p>No valid QR code detected yet.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ScanDiscount;
