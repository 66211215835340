import React from 'react';
import HeroSection from './HeroSection';
import RoomList from './RoomList';
import VideoGallery from './VideoGallery';
import { useLanguage } from 'Contexts/LanguageContext';
import { rooms, videos, Language } from './data';

const Home = () => {
  const { language } = useLanguage();

  const goToEvents = () => {
    window.open('https://www.facebook.com/takitakikatowice/events');
  };

  return (
    <div
      className="w-screen h-screen flex flex-col justify-start items-start bg-cover bg-top bg-no-repeat  overflow-y-scroll"
      style={{ backgroundImage: 'url("assets/back_facebook.jpg")' }}
    >
      <HeroSection language={language} />
      <RoomList rooms={rooms[language as Language]} goToEvents={goToEvents} />
      <VideoGallery videos={videos[language as Language]} />
    </div>
  );
};

export default Home;
