import { APIBase } from 'api/hosts';
import React, { useContext } from 'react';
import { AuthContext } from 'Contexts/Auth';
import Swal from 'sweetalert2';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const locations = [
  {
    lat: 50.2597,
    lng: 19.02075,
    name: 'Klub Pomarańcza Katowice',
    address: 'Adama Mickiewicza 4, 40-092 Katowice',
    clubId: 'pomarancza',
  },
  {
    lat: 50.25881,
    lng: 19.02339,
    name: 'TAPAS La Firinda',
    address: 'Dyrekcyjna 9, 40-013 Katowice',
    clubId: 'tapas',
  },
  {
    lat: 50.25734,
    lng: 19.02364,
    name: 'Klub Cel',
    address: 'Dworcowa 4, 40-012 Katowice',
    clubId: 'cel',
  },
  {
    lat: 50.2639045715332,
    lng: 19.02390480041504,
    name: 'Królestwo',
    address: 'rondo Generała Jerzego Ziętka 1, 40-001',
    clubId: 'krolestwo',
  },
  {
    lat: 50.259698,
    lng: 19.0159007,
    name: 'Sixa',
    address: '3 Maja 23/3, 40-097 Katowice',
    clubId: 'sixa',
  },
  {
    lat: 50.255287170410156,
    lng: 19.021007537841797,
    name: 'Energy 2000 Katowice',
    address: 'Plebiscytowa 3, 40-035 Katowice',
    clubId: 'energy2000',
  },
  {
    lat: 50.261024475097656,
    lng: 19.01881980895996,
    name: 'Scena54 Katowice',
    address: 'Adama Mickiewicza 3, 40-092 Katowice',
    clubId: 'scena54',
  },
];

const TemporaryVenuesAdder: React.FC = () => {
  const { currentToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const addVenues = async () => {
    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    for (const location of locations) {
      data.body = JSON.stringify(location);
      await fetch(`${APIBase}/client/venue`, data)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Error creating venue');
          }
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: `Failed to create ${location.name}. Please try again later 😞`,
            icon: 'error',
            timer: 3000,
          });
        });
    }

    Swal.fire({
      title: 'Success',
      text: 'All venues added successfully! 🎉',
      icon: 'success',
      timer: 2000,
    });

    navigate('/app/admin/venues');
  };

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> Back
      </button>
      <button
        onClick={addVenues}
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
      >
        Add Venues
      </button>
    </div>
  );
};

export default TemporaryVenuesAdder;
