import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TopHeader = styled.header`
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 64.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0;
  background-color: transparent;
`;

export const LinkHeader = styled(Link)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderElementsContainer = styled.div`
  width: 100%;
  max-width: 1084px;
  height: 64.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: 992px) {
    width: 804px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1084px;
  }
`;
