import { addDays, endOfDay, isWithinInterval, startOfDay, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Event } from 'types'; // Adjust the import path as necessary

const getDateRanges = (timeZone: string) => {
  const now = toZonedTime(new Date(), timeZone);
  const todayStart = startOfDay(now);
  const todayEnd = endOfDay(now);

  const tomorrowStart = startOfDay(addDays(now, 1));
  const tomorrowEnd = endOfDay(addDays(now, 1));

  const thisWeekStart = todayStart;
  const thisWeekEnd = endOfDay(addDays(todayStart, 4 - now.getDay())); // End of Thursday of the current week

  const thisWeekendStart = startOfDay(addDays(now, 5 - now.getDay())); // Friday of the current week
  const thisWeekendEnd = endOfDay(addDays(now, 7 - now.getDay())); // Sunday of the current week

  const nextWeekStart = startOfDay(addDays(thisWeekStart, 7)); // Start of next week (Sunday)
  const nextWeekEnd = endOfDay(addDays(thisWeekEnd, 7)); // End of next week (Thursday)

  const nextWeekendStart = startOfDay(addDays(thisWeekendStart, 7)); // Next Friday
  const nextWeekendEnd = endOfDay(addDays(thisWeekendEnd, 7)); // Next Sunday

  const upcomingStart = startOfDay(addDays(nextWeekendEnd, 1)); // Day after the end of the next week

  console.log('Date ranges:', {
    now,
    todayStart,
    todayEnd,
    tomorrowStart,
    tomorrowEnd,
    thisWeekStart,
    thisWeekEnd,
    thisWeekendStart,
    thisWeekendEnd,
    nextWeekStart,
    nextWeekEnd,
    nextWeekendStart,
    nextWeekendEnd,
    upcomingStart,
  });

  return {
    now,
    todayStart,
    todayEnd,
    tomorrowStart,
    tomorrowEnd,
    thisWeekStart,
    thisWeekEnd,
    thisWeekendStart,
    thisWeekendEnd,
    nextWeekStart,
    nextWeekEnd,
    nextWeekendStart,
    nextWeekendEnd,
    upcomingStart,
  };
};

const categorizeEvents = (events: Event[], timeZone: string) => {
  const {
    now,
    todayStart,
    todayEnd,
    tomorrowStart,
    tomorrowEnd,
    thisWeekStart,
    thisWeekEnd,
    thisWeekendStart,
    thisWeekendEnd,
    nextWeekStart,
    nextWeekEnd,
    nextWeekendStart,
    nextWeekendEnd,
    upcomingStart,
  } = getDateRanges(timeZone);

  const happeningNow: Event[] = [];
  const today: Event[] = [];
  const tomorrow: Event[] = [];
  const thisWeek: Event[] = [];
  const thisWeekend: Event[] = [];
  const nextWeek: Event[] = [];
  const nextWeekend: Event[] = [];
  const upcoming: Event[] = [];

  events.forEach((event) => {
    if (!event.date) {
      return; // Skip events without a date
    }

    let eventStart = parseISO(event.date);
    let eventEnd = event.endHour ? parseISO(`${event.date.split('T')[0]}T${event.endHour}`) : parseISO(event.date);

    // Handle events ending past midnight
    if (event.endHour && event.startHour && event.endHour.split(':')[0] < event.startHour.split(':')[0]) {
      eventEnd = addDays(eventEnd, 1);
    }

    eventStart = toZonedTime(eventStart, timeZone);
    eventEnd = toZonedTime(eventEnd, timeZone);

    console.log('Event:', {
      event,
      eventStart,
      eventEnd,
    });

    if (isWithinInterval(now, { start: eventStart, end: eventEnd })) {
      happeningNow.push(event);
    } else if (isWithinInterval(eventStart, { start: todayStart, end: todayEnd })) {
      today.push(event);
    } else if (isWithinInterval(eventStart, { start: tomorrowStart, end: tomorrowEnd })) {
      tomorrow.push(event);
    } else if (isWithinInterval(eventStart, { start: thisWeekendStart, end: thisWeekendEnd })) {
      thisWeekend.push(event);
    } else if (isWithinInterval(eventStart, { start: thisWeekStart, end: thisWeekEnd })) {
      thisWeek.push(event);
    } else if (isWithinInterval(eventStart, { start: nextWeekendStart, end: nextWeekendEnd })) {
      nextWeekend.push(event);
    } else if (isWithinInterval(eventStart, { start: nextWeekStart, end: nextWeekEnd })) {
      nextWeek.push(event);
    } else if (isWithinInterval(eventStart, { start: upcomingStart, end: new Date('9999-12-31') })) {
      upcoming.push(event);
    }
  });

  console.log('Categorized events:', {
    happeningNow,
    today,
    tomorrow,
    thisWeek,
    thisWeekend,
    nextWeek,
    nextWeekend,
    upcoming,
  });

  return { happeningNow, today, tomorrow, thisWeek, thisWeekend, nextWeek, nextWeekend, upcoming };
};

export default categorizeEvents;
