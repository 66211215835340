// SplashScreen.tsx
import React, { useEffect } from 'react';
import './SplashScreen.scss'; // Ensure you create a corresponding SCSS file for styling

const SplashScreen: React.FC<{ onComplete: () => void }> = ({ onComplete }) => {
  useEffect(() => {
    const timer = setTimeout(onComplete, 3000); // Splash screen duration
    return () => clearTimeout(timer);
  }, [onComplete]);

  return (
    <div className="splash-screen">
      <div className="logo-container">
        <img src="/splash-logo.png" alt="Logo" className="splash-logo" />
      </div>
      <h1 className="splash-title">Do you wanna have fun?</h1>
    </div>
  );
};

export default SplashScreen;
