import React, { useState } from 'react';
export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [token, setCurrentToken] = useState(localStorage.getItem('token-taki'));
  const [expiration, setExpiration] = useState(localStorage.getItem('token-expires-taki'));

  const updateToken = (token) => {
    localStorage.setItem('token-taki', token);
    setCurrentToken(token);
  };

  const updateExpiration = (expiration) => {
    localStorage.setItem('token-expires-taki', expiration);
    setExpiration(expiration);
  };

  const clearAuthentication = () => {
    updateToken('');
    updateExpiration('');
  };

  return (
    <AuthContext.Provider
      value={{
        currentToken: token,
        updateToken: updateToken,
        expiration: expiration,
        updateExpiration: updateExpiration,
        clearAuthentication: clearAuthentication,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
