import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';

const OfferForEventOrganizers: React.FC = () => {
  const { language } = useLanguage();

  return (
    <div
      className="w-full min-h-screen flex flex-col items-center  bg-cover bg-center overflow-y-scroll"
      style={{ backgroundImage: 'url("assets/back_facebook.jpg")' }}
    >
      <div className="w-full bg-black bg-opacity-80 flex items-center justify-center pt-20">
        <div className="w-full flex flex-col items-center px-4 sm:px-8 md:px-16 max-w-4xl">
          {language === 'english' && (
            <>
              {/* Offer Section */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto">
                <div className='w-full flex items-center justify-center'>
                  <img alt="Logo Taki" src={'assets/sun3RemovebgPrev.png'} />
                </div>

                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  Partner with Taki Entertainment!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  Are you an event organizer looking to expand your reach and join a vibrant network?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  Join Taki Entertainment and enjoy these benefits:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>📈 Professional Marketing: We promote your events to attract more attendees.</li>
                  <li>🌐 Network of Events: Join our network of events and service providers to expand your reach.</li>
                  <li>🔗 Service Providers: Access our extensive network of DJs, hosts, and equipment providers.</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  Let’s create unforgettable events together!
                </p>
              </div>
            </>
          )}

          {language === 'polish' && (
            <>
              {/* Offer Section for Polish Version */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full">
                <div className='w-full flex items-center justify-center'>
                  <img alt="Logo Taki" src={'assets/sun3RemovebgPrev.png'} />
                </div>

                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  Współpracuj z Taki Entertainment!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  Jesteś organizatorem wydarzeń i chcesz rozszerzyć swoje możliwości oraz dołączyć do dynamicznej sieci?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  Dołącz do Taki Entertainment i ciesz się tymi korzyściami:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>📈 Profesjonalny marketing: Promujemy Twoje wydarzenia, aby przyciągnąć więcej uczestników.</li>
                  <li>🌐 Sieć wydarzeń: Dołącz do naszej sieci wydarzeń i dostawców usług, aby rozszerzyć swoje możliwości.</li>
                  <li>🔗 Dostawcy usług: Dostęp do naszej szerokiej sieci DJ-ów, prowadzących i dostawców sprzętu.</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  Twórzmy razem niezapomniane wydarzenia!
                </p>
              </div>
            </>
          )}

          {language === 'spanish' && (
            <>
              {/* Offer Section for Spanish Version */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full">
                <div className='w-full flex items-center justify-center'>
                  <img alt="Logo Taki" src={'assets/sun3RemovebgPrev.png'} />
                </div>

                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  ¡Asóciate con Taki Entertainment!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  ¿Eres organizador de eventos y quieres expandir tu alcance y unirte a una red vibrante?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  Únete a Taki Entertainment y disfruta de estos beneficios:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>📈 Marketing profesional: Promocionamos tus eventos para atraer a más asistentes.</li>
                  <li>🌐 Red de eventos: Únete a nuestra red de eventos y proveedores de servicios para expandir tu alcance.</li>
                  <li>🔗 Proveedores de servicios: Accede a nuestra amplia red de DJs, anfitriones y proveedores de equipos.</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  ¡Creamos juntos eventos inolvidables!
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferForEventOrganizers;
