import { faBuilding, faMapMarkedAlt, faMicrophone, faMusic } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LowerBar from '../../PartyTicket/LowerBar';

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  const translations = {
    english: {
      party: 'Party',
      karaoke: 'Karaoke',
      map: 'Map',
      bars: 'Bars',
      clubs: 'Clubs',
    },
    spanish: {
      party: 'Fiesta',
      karaoke: 'Karaoke',
      map: 'Mapa',
      bars: 'Bares',
      clubs: 'Clubes',
    },
    polish: {
      party: 'Impreza',
      karaoke: 'Karaoke',
      map: 'Mapa',
      bars: 'Bary',
      clubs: 'Kluby',
    },
  };

  const t = translations[language as keyof typeof translations];

  return (
    <div className="home-screen">
      <div className="pentagon-container">
        <button onClick={() => navigate('/app/partyticket/map')} className="futuristic-button green-glow">
          <div className="button-content">
            <FontAwesomeIcon icon={faMapMarkedAlt} className="icon" />
            <span>{t.map}</span>
          </div>
        </button>

        <button onClick={() => navigate('/app/partyticket/karaoke')} className="futuristic-button purple-glow">
          <div className="button-content">
            <FontAwesomeIcon icon={faMicrophone} className="icon" />
            <span>{t.karaoke}</span>
          </div>
        </button>
        <button onClick={() => navigate('/app/partyticket/events')} className="futuristic-button blue-glow">
          <div className="button-content">
            <FontAwesomeIcon icon={faMusic} className="icon" />
            <span>{t.party}</span>
          </div>
        </button>
        {/* <button onClick={() => navigate('/app/partyticket/bars')} className="futuristic-button yellow-glow">
          <div className="button-content">
            <FontAwesomeIcon icon={faGlassCheers} className="icon" />
            <span>{t.bars}</span>
          </div>
        </button>
        <button onClick={() => navigate('/app/partyticket/clubs')} className="futuristic-button red-glow">
          <div className="button-content">
            <FontAwesomeIcon icon={faBuilding} className="icon" />
            <span>{t.clubs}</span>
          </div>
        </button> */}
        <button
          onClick={() => navigate('/app/partyticket/bars/view-bar/66cc50ad5d4ada8c64b24616')}
          className="futuristic-button red-glow"
        >
          <div className="button-content">
            <FontAwesomeIcon icon={faBuilding} className="icon" />
            <span>Taki</span>
          </div>
        </button>
      </div>
      <LowerBar />
      <style>{styles}</style>
    </div>
  );
};

export default HomeScreen;

const styles = `
  .home-screen {
    min-height: 100vh;
    background-color: #111;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    position: relative;
    overflow: hidden;
  }

  .pentagon-container {
    position: relative;
    width: 260px; /* Slightly reduced size */
    height: 260px;
    margin: 0 auto;
    transform: rotate(-54deg); /* Rotates the pentagon for proper orientation */
  }

  .futuristic-button {
    width: 80px; /* Reduced size */
    height: 80px;
    border-radius: 50%;
    background: #222;
    border: 2px solid rgba(255, 255, 255, 0.1);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3), 0 10px 20px rgba(0, 0, 0, 0.5);
  }

  .futuristic-button:hover {
    transform: scale(1.1);
  }

  .button-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }

  .icon {
    font-size: 26px; /* Slightly reduced size */
    margin-bottom: 6px;
    color: white;
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.8));
  }

  .futuristic-button span {
    font-size: 11px; /* Slightly reduced size */
    color: white;
    filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.7));
  }

  .futuristic-button:nth-child(1) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(54deg);
  }

  .futuristic-button:nth-child(2) {
    top: 20%;
    left: 20%;
    transform: translate(-50%, -50%) rotate(54deg);
  }

  .futuristic-button:nth-child(3) {
    top: 20%;
    right: 20%;
    transform: translate(50%, -50%) rotate(54deg);
  }

  .futuristic-button:nth-child(4) {
      bottom: 20%;
    right: 20%;
    transform: translate(50%, 50%) rotate(54deg);
  }

  .futuristic-button:nth-child(5) {

  bottom: 20%;
  left: 20%;
  transform: translate(-50%, 50%) rotate(54deg);
  }

  .blue-glow::before, .purple-glow::before, .green-glow::before, .yellow-glow::before, .red-glow::before {
    content: '';
    position: absolute;
    inset: -4px;
    border-radius: 50%;
    border: 2px solid;
    opacity: 0.5;
    animation: glowing 2s infinite alternate;
  }

  .blue-glow::before {
    border-color: rgba(0, 153, 255, 0.5);
  }

  .purple-glow::before {
    border-color: rgba(150, 0, 255, 0.5);
  }

  .green-glow::before {
    border-color: rgba(0, 255, 127, 0.5);
  }

  .yellow-glow::before {
    border-color: rgba(255, 223, 0, 0.5);
  }

  .red-glow::before {
    border-color: rgba(255, 69, 0, 0.5);
  }

  @keyframes glowing {
    0% {
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.2), 0 0 15px rgba(255, 255, 255, 0.3);
    }
    100% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.7);
    }
  }
`;
