import Chip from '@material-ui/core/Chip';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import parse from 'html-react-parser';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { FaCalendarAlt, FaHeadphones, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface EventCardProps {
  title: string;
  date: string;
  time: string;
  location: string;
  address: string;
  imageURL: string;
  facebookLink: string;
  genres: string[];
  djs: string[];
  isVerified: boolean;
  isTakiNetwork: boolean;
  startHour?: string;
  endHour?: string;
  eventId: string;
  description: string;
}

const EventCard: React.FC<EventCardProps> = ({
  title,
  date,
  time,
  location,
  address,
  imageURL,
  description,
  genres,
  djs,
  isVerified,
  isTakiNetwork,
  startHour,
  endHour,
  eventId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { language, translations } = useLanguage();
  const { currentToken } = useContext(AuthContext);
  const t = translations[language];

  const chipStyles = {
    fontSize: '0.75rem',
    padding: '2px 6px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    margin: '2px',
  };

  const formattedDate = moment(date).format('MMMM Do, YYYY');

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const shortenedDescription = description.split('\n').slice(0, 5).join('\n');

  return (
    <div className="mb-7">
      <div className="event-item flex flex-col sm:flex-row lg:flex-col bg-gradient-to-b from-gray-900 via-black to-gray-900 bg-opacity-90 rounded-lg shadow-lg max-w-full sm:max-w-sm md:max-w-md lg:max-w-lg border border-gray-700 hover:border-neon-purple transition-all duration-300 ease-in-out">
        <div className="w-full sm:w-28 lg:w-full">
          <Link to={`/app/partyticket/event/${eventId}`} className="block h-40 sm:h-28 lg:h-40 w-full">
            <img
              src={imageURL}
              alt={title}
              className="w-full h-full object-cover rounded-t-lg sm:rounded-l-lg lg:rounded-lg"
            />
          </Link>
        </div>
        <div className="flex flex-col text-white w-full p-4 md:p-3 lg:p-4">
          <Link to={`/app/partyticket/event/${eventId}`} className="text-lg md:text-base lg:text-lg font-semibold mb-2 hover:text-neon-blue transition-colors duration-300">
            {title}
          </Link>
          <div className="flex flex-col mb-2">
            <div className="flex space-x-2 mb-2">
              {isVerified && <span className="bg-black text-xs px-2 py-1 rounded-full border border-neon-green">{t.verified}</span>}
              {isTakiNetwork && (
                <span className="bg-gradient-to-r from-yellow-500 to-yellow-700 text-xs px-2 py-1 rounded-full border border-yellow-600 shadow-md text-black font-semibold">
                  {t.network}
                </span>
              )}
            </div>
            <p className="flex items-center text-sm md:text-xs lg:text-sm text-gray-400">
              <FaCalendarAlt className="mr-2 text-neon-blue" />
              {formattedDate} - {startHour ? `${startHour} - ${endHour}` : time}
            </p>
            <p className="flex items-center text-sm md:text-xs lg:text-sm text-gray-400">
              <FaMapMarkerAlt className="mr-2 text-neon-blue" />
              {location}. {address}
            </p>
            <div className="flex flex-wrap mt-2">
              {genres.map((genre, index) => (
                <Chip key={index} label={genre} style={chipStyles} />
              ))}
            </div>
            <p className="flex items-center mt-2 text-sm md:text-xs lg:text-sm text-gray-400">
              <FaHeadphones className="mr-2 text-neon-blue" />
              {djs.join(', ')}
            </p>
          </div>
          <div className="hidden lg:block mt-4">
            <p className="text-gray-400">{isExpanded ? parse(description) : parse(shortenedDescription)}</p>
            {description.length > shortenedDescription.length && (
              <button
                onClick={handleToggle}
                className="text-neon-blue underline hover:text-neon-purple transition-colors duration-300 mt-2"
              >
                {isExpanded ? t.showLess : t.showMore}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
