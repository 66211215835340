const isVideo = (media) => {
  const mediaText = media.toLowerCase();
  if (
    mediaText.endsWith('.mp4') ||
    mediaText.endsWith('.mov') ||
    mediaText.endsWith('.wmv') ||
    mediaText.endsWith('.avi') ||
    mediaText.endsWith('.avchd') ||
    mediaText.endsWith('.flv') ||
    mediaText.endsWith('.webm')
  ) {
    return true;
  } else {
    return false;
  }
};

export default isVideo;
