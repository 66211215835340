import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaInfoCircle, FaMapMarkedAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

const translations = {
  english: {
    bars: 'Bars',
    mapView: 'Map View',
    viewDetails: 'View Details',
    error: 'Error',
    fetchError: 'Please try again later 😞',
  },
  spanish: {
    bars: 'Bares',
    mapView: 'Vista de Mapa',
    viewDetails: 'Ver Detalles',
    error: 'Error',
    fetchError: 'Por favor, inténtelo de nuevo más tarde 😞',
  },
  polish: {
    bars: 'Bary',
    mapView: 'Widok Mapy',
    viewDetails: 'Zobacz Szczegóły',
    error: 'Błąd',
    fetchError: 'Proszę spróbować ponownie później 😞',
  },
};

const Bars: React.FC = () => {
  const [bars, setBars] = useState([]);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchBars();
  }, [currentToken]);

  const fetchBars = async () => {
    setLoading(true);
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/venue?sort=-updatedAt&type=bar`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.error);
        }
      })
      .then((responseJson) => {
        setBars(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          showConfirmButton: false,
          timer: 3000,
        });
      });
    setLoading(false);
  };

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t.bars}</h2>
        <button
          onClick={() => navigate('/app/partyticket/bars/map')}
          className="bg-gradient-to-r from-black via-gray-800 to-black text-white p-3 rounded-full flex items-center justify-center shadow-lg hover:bg-gradient-to-r hover:from-gray-700 hover:via-gray-800 hover:to-gray-900 transition-all duration-300 ease-in-out border border-neon-blue"
        >
          <FaMapMarkedAlt className="text-2xl text-neon-blue" />
        </button>
      </div>
      {loading ? (
        <div className="w-full flex items-center justify-center">
          <StageSpinner color="#ffffff" loading={loading} size={50} />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {bars.map((bar: any) => (
            <div
              onClick={() => navigate(`/app/partyticket/bars/view-bar/${bar._id}`)}
              key={bar._id}
              className="relative bg-gradient-to-b from-black via-gray-800 to-gray-900 p-4 rounded-lg shadow-lg border border-gray-700 hover:border-neon-purple transition-all duration-300 ease-in-out cursor-pointer"
            >
              <button className="absolute top-2 right-2 p-2 rounded-full text-neon-purple hover:text-neon-pink transition-colors duration-300 border border-neon-purple shadow-lg">
                <FaInfoCircle className="text-xl" />
              </button>
              <h3 className="text-lg font-semibold mb-2">{bar.name}</h3>
              <p className="text-sm text-gray-400">{bar.address}</p>
              <p className="text-sm text-gray-500 mb-2">{bar.description}</p>
              {bar.imageURL && (
                <img src={bar.imageURL} alt={bar.name} className="w-full h-32 object-cover rounded-md mt-2" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Bars;
