import styled from 'styled-components';

export const ButtonLayersContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 10px;
  width: 100%;
  position: relative;
  cursor: pointer;
`;

export const ButtonLayerA = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 10px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(17, 248, 53, 0.76);
  filter: blur(4px);
  border-radius: 10px;
  z-index: 2;
`;

export const ButtonLayerB = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  border-radius: 10px;
  background: rgba(57, 203, 81, 0.42);
  border: 1px solid rgba(78, 254, 50, 0.12);
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 98%;
  position: absolute;
  top: 1px;
  left: 1%;
  z-index: 3;
`;

export const Button = styled.div`
  z-index: 4;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 30px;
  font-family: 'Khula';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: rgba(27, 27, 27, 0.92);
  border-radius: 10px;
  &:hover {
    background: rgba(17, 248, 53, 0.45);
  }
  transition: all 0.1s ease-in;
`;
