import * as React from 'react';
import * as styledComponents from './styles';
import { Item } from '../';
import { CMSBase } from 'api/hosts';

interface MenuCardProps {
  item: Item;
}

const MenuCard: React.FC<MenuCardProps> = ({ item }) => {
  return (
    <styledComponents.CardContainer>
      <styledComponents.CardBackground src={CMSBase + item.attributes.background.data.attributes.url} />
      <styledComponents.CardContentContainer>
        <styledComponents.CardImage src={CMSBase + item.attributes.image.data.attributes.url} />
        <styledComponents.CardTitle>{item.attributes.name}</styledComponents.CardTitle>
        <styledComponents.CardDescription>{item.attributes.description}</styledComponents.CardDescription>
        <styledComponents.CardPrice>{item.attributes.price}zł</styledComponents.CardPrice>
      </styledComponents.CardContentContainer>
    </styledComponents.CardContainer>
  );
};
export default MenuCard;
