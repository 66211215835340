import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaEllipsisV, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { StageSpinner } from 'react-spinners-kit';

const translations = {
  english: {
    cities: 'Cities',
    newCity: 'New City',
    viewDetails: 'View Details',
    edit: 'Edit',
    delete: 'Delete',
    noCities: 'No cities available.',
    createNewCity: 'Create New City',
    error: 'Error',
    fetchError: 'Failed to fetch cities. Please try again later.',
    deleted: 'Deleted!',
    cityDeleted: 'City has been deleted.',
    deleteError: 'Failed to delete city. Please try again later.',
    confirmDelete: 'Are you sure?',
    confirmDeleteText: "You won't be able to revert this!",
    confirmDeleteYes: 'Yes, delete it!',
    confirmDeleteCancel: 'Cancel',
    confirmDeleteSuccess: 'Deleted!',
    country: 'Country',
    coordinates: 'Coordinates',
  },
  spanish: {
    cities: 'Ciudades',
    newCity: 'Nueva Ciudad',
    viewDetails: 'Ver Detalles',
    edit: 'Editar',
    delete: 'Eliminar',
    noCities: 'No hay ciudades disponibles.',
    createNewCity: 'Crear Nueva Ciudad',
    error: 'Error',
    fetchError: 'No se pudo obtener las ciudades. Inténtalo de nuevo más tarde.',
    deleted: '¡Eliminado!',
    cityDeleted: 'La ciudad ha sido eliminada.',
    deleteError: 'No se pudo eliminar la ciudad. Inténtalo de nuevo más tarde.',
    confirmDelete: '¿Estás seguro?',
    confirmDeleteText: '¡No podrás revertir esto!',
    confirmDeleteYes: '¡Sí, elimínalo!',
    confirmDeleteCancel: 'Cancelar',
    confirmDeleteSuccess: '¡Eliminado!',
    country: 'País',
    coordinates: 'Coordenadas',
  },
  polish: {
    cities: 'Miasta',
    newCity: 'Nowe Miasto',
    viewDetails: 'Zobacz szczegóły',
    edit: 'Edytuj',
    delete: 'Usuń',
    noCities: 'Brak dostępnych miast.',
    createNewCity: 'Utwórz Nowe Miasto',
    error: 'Błąd',
    fetchError: 'Nie udało się pobrać miast. Spróbuj ponownie później.',
    deleted: 'Usunięto!',
    cityDeleted: 'Miasto zostało usunięte.',
    deleteError: 'Nie udało się usunąć miasta. Spróbuj ponownie później.',
    confirmDelete: 'Jesteś pewny?',
    confirmDeleteText: 'Nie będziesz w stanie tego cofnąć!',
    confirmDeleteYes: 'Tak, usuń to!',
    confirmDeleteCancel: 'Anuluj',
    confirmDeleteSuccess: 'Usunięto!',
    country: 'Kraj',
    coordinates: 'Współrzędne',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const CityList: React.FC = () => {
  const [cities, setCities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const t = translations[language as Language];
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentToken) {
      fetchCities();
    }
  }, [currentToken]);

  const fetchCities = async () => {
    setLoading(true); // Start loading
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/city/cities`, data);
      if (response.ok) {
        const data = await response.json();
        setCities(data);
      } else {
        throw new Error(t.fetchError);
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleDelete = async (cityId: string) => {
    const data: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/city/city/${cityId}`, data);
      if (response.ok) {
        Swal.fire({
          title: t.deleted,
          text: t.cityDeleted,
          icon: 'success',
          timer: 2000,
        });
        fetchCities();
      } else {
        throw new Error(t.deleteError);
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.deleteError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const confirmDelete = (cityId: string) => {
    Swal.fire({
      title: t.confirmDelete,
      text: t.confirmDeleteText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t.confirmDeleteYes,
      cancelButtonText: t.confirmDeleteCancel,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(cityId);
      }
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuItemClick = (callback: () => void) => {
    callback();
    setDropdownOpen(null);
  };

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl">{t.cities}</h2>
        <button
          onClick={() => navigate('/app/admin/new-city')}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded flex items-center"
        >
          <FaPlus className="" />
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <StageSpinner size={50} color="#ffffff" />
        </div>
      ) : cities.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {cities.map((city) => (
            <div key={city._id} className="bg-gray-800 p-4 rounded relative">
              <div className="absolute top-2 right-2">
                <button
                  onClick={() => setDropdownOpen(dropdownOpen === city._id ? null : city._id)}
                  className="bg-gray-700 px-2 py-1 rounded flex items-center"
                >
                  <FaEllipsisV />
                </button>
                {dropdownOpen === city._id && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-10"
                    ref={dropdownRef}
                  >
                    <button
                      onClick={() => handleMenuItemClick(() => navigate(`/app/admin/view-city/${city._id}`))}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.viewDetails}
                    </button>
                    <button
                      onClick={() => handleMenuItemClick(() => navigate(`/app/admin/edit-city/${city._id}`))}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.edit}
                    </button>
                    <button
                      onClick={() => handleMenuItemClick(() => confirmDelete(city._id))}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.delete}
                    </button>
                  </div>
                )}
              </div>
              <h3 className="text-xl">{city.name}</h3>
              <p>
                <strong>{t.country}:</strong> {city.country.name}
              </p>
              <p>
                <strong>{t.coordinates}:</strong> {city.lat}, {city.lng}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center pt-20">
          <p className="text-xl">{t.noCities}</p>
          <button
            onClick={() => navigate('/app/admin/new-city')}
            className="mt-4 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded flex items-center justify-center mx-auto"
          >
            <FaPlus className="mr-2" />
            {t.createNewCity}
          </button>
        </div>
      )}
    </div>
  );
};

export default CityList;
