import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import Modal from 'react-modal';
import AgeRestrictionFilter from './AgeRestrictionFilter';
import DressCodeFilter from './DressCodeFilter';
import MusicGenreFilter from './MusicGenreFilter';
import VenueTypeFilter from './VenueTypeFilter';

interface FilterSectionProps {
  selectedGenres: string[];
  setSelectedGenres: (genres: string[]) => void;
  selectedVenueTypes: string[];
  setSelectedVenueTypes: (venueTypes: string[]) => void;
  ageRestriction: string;
  setAgeRestriction: (ageRestriction: string) => void;
  dressCode: string;
  setDressCode: (dressCode: string) => void;
  handleClearFilters: () => void;
  showAdvancedFilters: boolean;
  setShowAdvancedFilters: (show: boolean) => void;
  anyFilterApplied: boolean;
  setSelectedClub: (clubId: string | null) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  selectedGenres,
  setSelectedGenres,
  selectedVenueTypes,
  setSelectedVenueTypes,
  ageRestriction,
  setAgeRestriction,
  dressCode,
  setDressCode,
  handleClearFilters,
  showAdvancedFilters,
  setShowAdvancedFilters,
  anyFilterApplied,
}) => {
  const { language, translations } = useLanguage();
  const t = translations[language];

  const resetFilters = () => {
    setSelectedGenres([]);
    setSelectedVenueTypes([]);
    setAgeRestriction('');
    setDressCode('');
  };

  return (
    <>
      {showAdvancedFilters && (
        <Modal
          isOpen={showAdvancedFilters}
          onRequestClose={() => setShowAdvancedFilters(false)}
          contentLabel="Advanced Filters"
          className="fixed inset-0 flex items-center justify-center z-50 py-8"
          overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-40"
          style={{
            content: {
              top: '10%',
              left: '5%',
              right: '5%',
              bottom: '5%',
              backgroundColor: 'black',
              borderRadius: '10px',
              padding: '20px',
            },
          }}
        >
          <div className="flex w-full h-full md:h-auto flex-col items-start justify-start mb-6 px-3 md:px-7 overflow-y-hidden md:p-8">
            <div className="h-full overflow-scroll w-full">
              <div className="flex flex-col md:flex-row px-3 pt-4">
                <MusicGenreFilter selectedGenres={selectedGenres} setSelectedGenres={setSelectedGenres} />
                <VenueTypeFilter
                  selectedVenueTypes={selectedVenueTypes}
                  setSelectedVenueTypes={setSelectedVenueTypes}
                />
              </div>
              <div className="flex flex-col md:flex-row px-3">
                <AgeRestrictionFilter ageRestriction={ageRestriction} setAgeRestriction={setAgeRestriction} />
                <DressCodeFilter dressCode={dressCode} setDressCode={setDressCode} />
              </div>
            </div>

            <div className="flex flex-row justify-end w-full px-3 h-14 shrink-0 pt-3 space-x-4">
              <button
                onClick={resetFilters}
                className="bg-gray-700 text-white font-semibold py-2 px-4 rounded-lg border border-gray-700"
              >
                {t.clearAllFilters}
              </button>
              <button
                onClick={() => setShowAdvancedFilters(false)}
                className="bg-white text-black font-semibold py-2 px-4 rounded-lg border border-white"
              >
                {t.search}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FilterSection;
