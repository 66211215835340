import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLocationContext } from 'Contexts/LocationContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';

interface CitySelectionModalProps {
  onCitySelect: (cityId: string) => void;
  onClose: () => void;
}

interface City {
  _id: string;
  name: string;
  country: { name: string };
  lat: number;
  lng: number;
}

const CitySelectionModal: React.FC<CitySelectionModalProps> = ({ onCitySelect, onClose }) => {
  const [cities, setCities] = useState<City[]>([]);
  const [filteredCities, setFilteredCities] = useState<City[]>([]);
  const [loading, setLoading] = useState(false);
  const [gpsLoading, setGpsLoading] = useState(false);
  const { currentToken } = useContext(AuthContext);
  const { setSelectedCity, setIsUsingGPS, setUserLocation } = useLocationContext();
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect(() => {
    if (currentToken) {
      fetchCities();
    }
  }, [currentToken]);

  useEffect(() => {
    setFilteredCities(
      cities.filter((city) =>
        city.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        city.country.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, cities]);

  const fetchCities = async () => {
    setLoading(true);
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/city/cities`, data);
      if (response.ok) {
        const data = await response.json();
        setCities(data);
        setFilteredCities(data);
      } else {
        throw new Error('Error fetching cities');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch cities. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const calculateDistance = (lat1: number, lng1: number, lat2: number, lng2: number) => {
    const toRad = (x: number) => (x * Math.PI) / 180;
    const R = 6371; // Radius of the Earth in km

    const dLat = toRad(lat2 - lat1);
    const dLng = toRad(lng2 - lng1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  const handleCitySelect = (cityId: string) => {
    onCitySelect(cityId);
    setSelectedCity(cityId);
    setIsUsingGPS(false);
  };

  const handleUseGPS = () => {
    if (navigator.geolocation) {
      setGpsLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([latitude, longitude]);

          const closestCity = cities.reduce((prev, curr) => {
            const prevDistance = calculateDistance(latitude, longitude, prev.lat, prev.lng);
            const currDistance = calculateDistance(latitude, longitude, curr.lat, curr.lng);
            return currDistance < prevDistance ? curr : prev;
          });

          setIsUsingGPS(true);
          setSelectedCity(closestCity._id);
          onCitySelect(closestCity._id);
          Swal.fire({
            title: 'Location Set',
            text: `The closest city to your location, ${closestCity.name}, has been selected.`,
            icon: 'success',
            timer: 3000,
          });
          onClose();
        },
        (error) => {
          Swal.fire({
            title: 'Error',
            text: 'Failed to get GPS location. Please select a city manually.',
            icon: 'error',
            timer: 3000,
          });
          setGpsLoading(false);
        },
        { timeout: 10000 }
      );
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Geolocation is not supported by this browser.',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
      <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg max-w-lg w-full m-8 overflow-hidden">
        <h2 className="text-xl sm:text-2xl mb-4 font-semibold">Select Your City</h2>
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search for a city..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 pl-10 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-white text-sm sm:text-base"
          />
          <FaSearch className="absolute left-3 top-3 text-gray-400" />
        </div>
        {loading ? (
          <div className="text-center text-sm sm:text-base">Loading cities...</div>
        ) : (
          <div className="max-h-64 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {filteredCities.map((city) => (
                <button
                  key={city._id}
                  onClick={() => handleCitySelect(city._id)}
                  className="bg-gray-800 hover:bg-gray-700 text-white p-3 rounded text-left transition duration-200 ease-in-out text-sm sm:text-base"
                >
                  {city.name}, {city.country.name}
                </button>
              ))}
            </div>
          </div>
        )}
        <div className="mt-4 flex justify-between items-center">
          <button onClick={onClose} className="text-gray-400 hover:text-gray-200 transition duration-200 text-sm sm:text-base">
            Close
          </button>
          <button
            onClick={handleUseGPS}
            className={`bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-700 transition duration-200 text-sm sm:text-base ${
              gpsLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={gpsLoading}
          >
            <FaMapMarkerAlt className="mr-2" />
            {gpsLoading ? 'Locating...' : 'Use GPS Location'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CitySelectionModal;
