import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import isVideo from 'helpers/isVideo';
import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import 'video-react/dist/video-react.css';
import Image from 'components/Profile/Gallery/Image';
import Video from 'components/Profile/Gallery/Video';
import { FaChevronLeft, FaEllipsisV, FaShareAlt } from 'react-icons/fa';

interface DetailViewProps {
  user: any;
  getUser: () => void;
}

const DetailView: React.FC<DetailViewProps> = ({ user, getUser }) => {
  const { language } = useLanguage();
  const translations = {
    english: {
      back: 'Back',
      delete: 'Delete',
      share: 'Share',
      error: 'Error',
      tryAgain: 'Please try again later 😞',
      linkCopied: 'Link copied to clipboard',
    },
    spanish: {
      back: 'Atrás',
      delete: 'Eliminar',
      share: 'Compartir',
      error: 'Error',
      tryAgain: 'Por favor, inténtelo de nuevo más tarde 😞',
      linkCopied: 'Enlace copiado al portapapeles',
    },
    polish: {
      back: 'Powrót',
      delete: 'Usuń',
      share: 'Udostępnij',
      error: 'Błąd',
      tryAgain: 'Proszę spróbować ponownie później 😞',
      linkCopied: 'Link skopiowany do schowka',
    },
  };

  type Language = 'english' | 'spanish' | 'polish';

  const t = translations[language as Language];

  const { postId } = useParams<{ postId: string }>();
  const [currentPostIndex, setCurrentPostIndex] = useState(Number(postId));
  const navigate = useNavigate();
  const { currentToken } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const postContainerRef = useRef<HTMLDivElement>(null);

  const media = user?.media || [];

  const handleDelete = async (index: number) => {
    const newMedia = media.filter((_: any, idx: number) => idx !== index);
    const body = { media: newMedia };
    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(`${APIBase}/client/user`, data);
      if (response.ok) {
        await getUser();
        navigate(-1); // Go back to the previous page
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.tryAgain,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleShare = (index: number) => {
    const shareData = {
      title: 'Check this out!',
      text: 'Here is something interesting I found:',
      url: `${window.location.origin}/app/profile/post/${index}`,
    };

    if (navigator.share) {
      navigator.share(shareData).catch(console.error);
    } else {
      navigator.clipboard.writeText(`${shareData.title}\n${shareData.text}\n${shareData.url}`).then(() => {
        Swal.fire({
          title: t.linkCopied,
          showConfirmButton: false,
          timer: 2000,
        });
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (postContainerRef.current) {
      postContainerRef.current.scrollTop = currentPostIndex * postContainerRef.current.clientHeight;
    }
  }, [currentPostIndex]);

  if (!media[currentPostIndex]) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-4 pt-20 pb-28">
      <div className="fixed top-0 left-0 flex justify-between items-center p-4 bg-gray-900 z-10 pt-20">
        <button onClick={() => navigate(-1)} className="bg-gray-700 px-4 py-2 rounded flex items-center">
          <FaChevronLeft className="mr-2" /> {t.back}
        </button>
      </div>
      <div ref={postContainerRef} className="mt-16 overflow-y-scroll h-full">
        {media.map((mediaContent: string, index: number) => (
          <div key={index} className="relative mb-4">
            <div className="absolute top-4 right-4" ref={dropdownRef}>
              <button
                onClick={() => setDropdownOpen(dropdownOpen === index ? null : index)}
                className="bg-gray-700 px-4 py-2 rounded flex items-center"
              >
                <FaEllipsisV />
              </button>
              {dropdownOpen === index && (
                <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-10">
                  <button onClick={() => handleDelete(index)} className="block px-4 py-2 w-full text-left hover:bg-gray-200">
                    {t.delete}
                  </button>
                  <button onClick={() => handleShare(index)} className="block px-4 py-2 w-full text-left hover:bg-gray-200">
                    {t.share}
                  </button>
                </div>
              )}
            </div>
            <div className="flex justify-center mt-10">
              {!isVideo(mediaContent) ? (
                <Image url={mediaContent} />
              ) : (
                <Video idx={index} url={mediaContent} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailView;
