import React from 'react';
import Chip from '@material-ui/core/Chip';

interface MusicGenreFilterProps {
  selectedGenres: string[];
  setSelectedGenres: (genres: string[]) => void;
}

const genres = [
  'Reggaeton', 'Twerk', 'Salsa', 'Bachata', 'Kizomba', 'Merengue', 'Cumbia', 'Trap', 'Rap', 'House', 'EDM',
  'Pop', 'Rock', 'Hip-hop', 'Jazz', 'Classical', 'Country', 'Reggae', 'Blues', 'Soul', 'Funk', 'Disco',
  'Techno', 'Dubstep', 'Trance', 'Drum & Bass', 'Garage', 'Latin', 'Ska', 'Metal', 'Punk', 'R&B', 'Folk',
  'Gospel', 'Opera', 'World', 'K-Pop', 'J-Pop',
];

const MusicGenreFilter: React.FC<MusicGenreFilterProps> = ({ selectedGenres, setSelectedGenres }) => {
  const handleGenreChange = (genre: string) => {
    if (selectedGenres.includes(genre)) {
      setSelectedGenres(selectedGenres.filter((g) => g !== genre));
    } else {
      setSelectedGenres([...selectedGenres, genre]);
    }
  };

  const chipStyles = {
    fontSize: '0.75rem', // Smaller font size
    padding: '2px 6px', // Smaller padding
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Transparent background
    color: 'white', // Text color
    margin: '2px', // Margin between chips
    cursor: 'pointer',
  };

  return (
    <div className="flex flex-col items-start">
      <h4 className="text-white mb-2">Music Genre</h4>
      <div className="flex flex-wrap gap-2">
        {genres.map((genre) => (
          <Chip
            key={genre}
            label={genre}
            style={{
              ...chipStyles,
              backgroundColor: selectedGenres.includes(genre) ? 'rgba(0, 0, 255, 0.5)' : 'rgba(255, 255, 255, 0.2)',
            }}
            onClick={() => handleGenreChange(genre)}
          />
        ))}
      </div>
    </div>
  );
};

export default MusicGenreFilter;
