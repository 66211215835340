import '@fortawesome/fontawesome-free/css/all.css';
import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import { useLocationContext } from 'Contexts/LocationContext';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaChevronLeft, FaCrosshairs, FaMinus, FaPlus } from 'react-icons/fa';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Venue } from 'types';

const translations = {
  english: {
    error: 'Error',
    locationError: 'Unable to retrieve your location. Please enable location services.',
    pleaseTryAgainLater: 'Please try again later 😞',
    return: 'Return',
    seeMore: 'See More',
  },
  spanish: {
    error: 'Error',
    locationError: 'No se pudo obtener su ubicación. Por favor, habilite los servicios de ubicación.',
    pleaseTryAgainLater: 'Por favor, inténtelo de nuevo más tarde 😞',
    return: 'Regresar',
    seeMore: 'Ver más',
  },
  polish: {
    error: 'Błąd',
    locationError: 'Nie można uzyskać Twojej lokalizacji. Proszę włączyć usługi lokalizacyjne.',
    pleaseTryAgainLater: 'Proszę spróbować ponownie później 😞',
    return: 'Powrót',
    seeMore: 'Zobacz więcej',
  },
};

const styles = `
  .party-marker {
    width: 10px;
    height: 10px;
    background: black;
    border-radius: 50%;
    position: relative;
  }
  .party-marker.selected {
    background: magenta;
  }
  .party-marker::after {
    content: '';
    width: 24px;
    height: 24px;
    border: 2px dashed black;
    border-radius: 50%;
    position: absolute;
    top: -7px;
    left: -7px;
    animation: rotate 10s linear infinite;
  }
  .party-marker.selected::after {
    border-color: magenta;
  }
  .user-location {
    width: 10px;
    height: 10px;
    background: blue;
    border-radius: 50%;
    position: relative;
  }
  .user-location::before {
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid blue;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: -5px;
    animation: pulsate 1s ease-out infinite;
  }
  @keyframes pulsate {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const BarsMap: React.FC = () => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [selectedBar, setSelectedBar] = useState<string | null>(null);
  const { selectedCity, userLocation, setUserLocation, isUsingGPS } = useLocationContext();
  const [selectedCityObject, setSelectedCityObject] = useState<{ lat: number; lng: number } | null>(null);
  const mapRef = useRef<L.Map | null>(null);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchBars();
    fetchUserLocation();
    if (selectedCity) {
      fetchSelectedCity();
    }
  }, [selectedCity]);

  const fetchBars = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/venue?sort=-updatedAt&type=bar`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.error);
        }
      })
      .then((responseJson) => {
        setVenues(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.pleaseTryAgainLater,
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const fetchUserLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLoc: [number, number] = [position.coords.latitude, position.coords.longitude];
        setUserLocation(userLoc);
        if (mapRef.current && !selectedCity) {
          mapRef.current.setView(userLoc, 13);
        }
      },
      () => {
        Swal.fire({
          title: t.error,
          text: t.locationError,
          icon: 'error',
          timer: 3000,
        });
      },
    );
  };

  const fetchSelectedCity = async () => {
    if (selectedCity) {
      const data: RequestInit = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await fetch(`${APIBase}/client/city/cities`, data);
        if (response.ok) {
          const cities = await response.json();
          const city = cities.find((city: any) => city._id === selectedCity);
          if (city) {
            setSelectedCityObject({ lat: city.lat, lng: city.lng });
            if (mapRef.current) {
              mapRef.current.setView([city.lat, city.lng], 13);
            }
          }
        }
      } catch {
        Swal.fire({
          title: t.error,
          text: t.pleaseTryAgainLater,
          icon: 'error',
          timer: 3000,
        });
      }
    }
  };

  const recenterMapToUser = () => {
    if (mapRef.current && userLocation) {
      mapRef.current.setView(userLocation, 13);
    }
  };

  const zoomIn = () => {
    if (mapRef.current) {
      mapRef.current.zoomIn();
    }
  };

  const zoomOut = () => {
    if (mapRef.current) {
      mapRef.current.zoomOut();
    }
  };

  const partyMarkerIcon = new L.DivIcon({
    html: `<div class="party-marker"></div>`,
    className: 'custom-div-icon',
    iconSize: [10, 10],
    iconAnchor: [5, 5],
    popupAnchor: [0, -36],
  });

  const selectedPartyMarkerIcon = new L.DivIcon({
    html: `<div class="party-marker selected"></div>`,
    className: 'custom-div-icon',
    iconSize: [10, 10],
    iconAnchor: [5, 5],
    popupAnchor: [0, -36],
  });

  const userLocationIcon = new L.DivIcon({
    html: `<div class="user-location"></div>`,
    className: 'custom-div-icon',
    iconSize: [10, 10],
    iconAnchor: [5, 5],
  });

  const handleMarkerClick = (barId: string, lat: number, lng: number) => {
    setSelectedBar(barId);
    if (mapRef.current) {
      mapRef.current.setView([lat, lng], 15);
    }
  };

  const UpdateMapCenter = () => {
    const map = useMap();
    mapRef.current = map;
    useEffect(() => {
      if (selectedBar) {
        const selectedVenue = venues.find((venue: Venue) => venue._id === selectedBar);
        if (selectedVenue) {
          map.setView([selectedVenue.lat, selectedVenue.lng], 15);
        }
      } else if (selectedCityObject) {
        map.setView([selectedCityObject.lat, selectedCityObject.lng], 13);
      } else if (isUsingGPS && userLocation) {
        map.setView(userLocation, 13);
      }
    }, [selectedBar, selectedCityObject, userLocation, map]);
    return null;
  };

  return (
    <>
      <div className="w-full h-screen relative z-0 mb-12">
        <MapContainer
          center={[50.260891, 19.018021]}
          zoom={13}
          style={{ width: '100%', height: '100%' }}
          zoomControl={false}
        >
          <UpdateMapCenter />
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          {venues.map((venue, index) => (
            <Marker
              key={index}
              position={[venue.lat, venue.lng]}
              icon={venue._id === selectedBar ? selectedPartyMarkerIcon : partyMarkerIcon}
              eventHandlers={{
                click: () => handleMarkerClick(venue._id, venue.lat, venue.lng),
              }}
            >
              <Popup>
                <div className="text-center max-w-xs mx-auto" style={{maxWidth: '50vw'}}>
                  {venue.imageURL && (
                    <img
                      src={venue.imageURL}
                      alt={venue.name}
                      className="w-16 h-16 rounded-full mx-auto mt-2 object-cover"
                    />
                  )}
                  <h3 className="font-bold text-lg truncate">{venue.name}</h3>
                  <p className="text-sm truncate">{venue.address}</p>
                  <p className="text-xs text-gray-600 truncate">{venue.description}</p>
                  <button
                    className="mt-2 px-4 py-2 bg-blue-600 text-white text-sm rounded-full hover:bg-blue-700 transition-all duration-300"
                    onClick={() => navigate(`/app/partyticket/bars/view-bar/${venue._id}`)}
                  >
                    {t.seeMore}
                  </button>
                </div>
              </Popup>
            </Marker>
          ))}
          {userLocation && <Marker position={userLocation} icon={userLocationIcon}></Marker>}
        </MapContainer>
      </div>
      <div className="fixed bottom-28 right-4 flex flex-col items-center z-50 space-y-2">
        <button
          onClick={recenterMapToUser}
          className="bg-black bg-opacity-80  text-white p-2 rounded-md flex items-center justify-center transition-colors duration-300"
        >
          <FaCrosshairs className="text-xl" />
        </button>
        <button
          onClick={zoomIn}
          className="bg-black bg-opacity-80  text-white p-2 rounded-md flex items-center justify-center transition-colors duration-300"
        >
          <FaPlus className="text-xl" />
        </button>
        <button
          onClick={zoomOut}
          className="bg-black bg-opacity-80  text-white p-2 rounded-md flex items-center justify-center transition-colors duration-300"
        >
          <FaMinus className="text-xl" />
        </button>
      </div>

      <button
        onClick={() => navigate(-1)}
        className="absolute left-4 bottom-28 bg-black bg-opacity-80  text-white p-3 rounded-md flex items-center justify-center transition-colors duration-300"
      >
        <FaChevronLeft />
      </button>
    </>
  );
};

export default BarsMap;
