import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Register from './Register';
import RecoverPassword from './RecoverPassword';
import ChangePassword from './ChangePassword';

const Auth = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="recover" element={<RecoverPassword />} />
      <Route path="changepassword/:tokenId" element={<ChangePassword />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

export default Auth;
