import styled from 'styled-components';

export const InputContainerLayers = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 48px;
  min-height: 48px;
  position: flex;
  align-items: center;
  justify-content: center;
`;

export const InputContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 48px;
  ${(props) => props.focus && `background: rgba(28, 202, 13, 0.38);`}
  filter: blur(5px);
  border-radius: 20px;
  transition: all 0.1s ease-in;
`;

export const InputSubContainer = styled.div`
  position: absolute;
  width: 99%;
  left: 0.5%;
  top: 3px;
  height: 42px;
  ${(props) => props.focus && `background: rgba(28, 202, 13, 0.38);`}
  filter: blur(2.5px);
  border-radius: 10px;
  transition: all 0.1s ease-in;
`;

export const Input = styled.input`
  position: absolute;
  width: 98%;
  left: 1%;
  height: 34px;
  top: 7px;
  padding-left: 10px;
  color: #b3b4b9;
  font-family: 'Khula';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  background: #1a1921;
  border: 2px solid rgba(49, 225, 34, 0.3);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  &:focus {
    outline: none;
  }
  transition: all 0.1s ease-in;
`;

export const Container = styled.div`
  background: linear-gradient(90.04deg, rgba(48, 15, 74, 0.54) 0.04%, rgba(97, 11, 49, 0.783) 99.97%);
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: flex-start;
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 170px;
  max-width: 50%;
  min-height: 170px;
  border: 1px solid orange;
`;

export const CardContentContainer = styled.div`
  top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const CardImage = styled.img`
  height: 170px;
  padding-top: 10px;
  z-index: 2;
`;

export const CardBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 170px;
  padding: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const CardTitle = styled.div`
  margin-top: 10px;
  font-family: 'Khula';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;

export const CardDescription = styled.div`
  font-family: 'Khula';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  padding: 0 10px;
`;

export const CardPrice = styled.div`
  width: 96%;
  margin: 0px 2%;
  height: 30px;
  border: 2px solid rgba(222, 137, 193, 0.17);
  filter: blur(0.5px);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Khula';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
`;
