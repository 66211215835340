export const CMSBase = 'https://oracliom-cms.herokuapp.com';
export const APIBase = 'https://api-takient.onrender.com';
// export const CMSBase = "http://localhost:1337";
// export const APIBase = 'http://localhost:8081';

export const djsAPI = CMSBase + '/api/djs?populate=*';
export const galleriesAPI = CMSBase + '/api/galleries?populate=*';
export const itemsAPI = CMSBase + '/api/drinks?populate=*';
export const itemAPI = CMSBase + '/api/drinks';

const token =
  '52badcc46bc5ab5f530914ef1bd7706abdeea8dd546aea3adbebb19fd01c1c0bd6e112e71a4ab3da792e2665c90f375c63d1c94b2b9930d5b47fc4372375f239c93da6ede088b55022923a6a30d36f8807dd361a65163525bce8c3a27b0f81ec24df356700821fff157c5aaf316054e181177671d6af88c4c3afaadafc2c7555';

export const cmsHeaders: RequestInit = {
  method: 'GET',
  mode: 'cors', // no-cors, *cors, same-origin
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
};
