import styled from 'styled-components';

const Line = styled.line`
  transition: all 0.7s;
  stroke: white;
`;

const ContainerBurguer = styled.div`
  z-index: 100000;
  display: flex;
  position: fixed;
  left: 10px;
  top: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const styledComponents = {
  Line,
  ContainerBurguer,
};

export default styledComponents;
