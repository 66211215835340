import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(90.04deg, rgba(48, 15, 74, 0.54) 0.04%, rgba(97, 11, 49, 0.783) 99.97%);
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: flex-start;
`;

export const CardContentContainer = styled.div`
  top: 10px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  padding-bottom: 20px;
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 375px;
  max-width: 100%;
  min-height: 170px;
  margin-bottom: 20px;
  background-color: #0000008a;
  border-radius: 15px 15px 0px 0px;
  margin: 0px 5px 20px 5px;
  @media (min-width: 668px) {
    width: 48%;
  }
  @media (min-width: 868px) {
    width: 32%;
  }
  @media (min-width: 767px) {
    width: 32%;
  }
  @media (min-width: 1100px) {
    width: 24%;
  }
  @media (min-width: 1400px) {
  }
`;

export const CardBackground = styled.img`
  position: absolute;
  width: 100%;
  height: 170px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
`;

export const DJContent = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-top: 180px;
  padding-left: 10px;
`;

export const BookButtonContainer = styled.div`
  width: 200px;
  max-width: 50%;
  height: 30px;
  margin: auto;
  margin-bottom: 10px;
`;

export const DJImage = styled.img`
  z-index: 1;
  margin-top: 80px;
  height: 230px;
  width: auto;
`;

export const DJName = styled.div`
  font-family: 'Advent Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: rgba(134, 255, 13, 0.77);
  text-shadow: 1px 1px 6px rgba(43, 250, 38, 0.53);
`;

export const DJDescription = styled.div`
  font-family: 'Khula';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
`;
