import React, { useState, ChangeEvent, FormEvent } from 'react';
import Swal from 'sweetalert2';
import { APIBase } from 'api/hosts';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ClapSpinner } from 'react-spinners-kit';

const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    Swal.fire({
      title: 'Password Recovery...',
      showConfirmButton: false,
      background: '#000000',
      color: '#FFFFFF',
    });
    const body = {
      email,
    };

    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(`${APIBase}/auth/recover`, data);
      if (response.ok) {
        navigate('/auth/login');
        Swal.fire({
          title: 'Request Sent',
          text: 'Instructions will be sent to your email',
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
      } else {
        Swal.fire({
          title: 'System Error',
          text: 'Please try again later',
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Network Error',
        text: 'Please check your connection and try again',
        showConfirmButton: false,
        timer: 3000,
        background: '#000000',
        color: '#FFFFFF',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-cover bg-top bg-no-repeat bg-black py-10 px-6">
      <div className="w-full max-w-sm bg-black bg-opacity-70 backdrop-blur-lg rounded-lg shadow-md p-6">
        <h1 className="text-4xl text-white font-bold mb-6">Password Recovery</h1>
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <input
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={handleChangeEmail}
            className="w-full px-3 py-2 mb-4 text-gray-900 rounded bg-gray-200 focus:outline-none focus:ring-2"
            required
          />
          <div className="w-full max-w-xs">
            <button
              type="submit"
              className="w-full py-2 flex items-center justify-center text-white rounded-lg border border-solid border-white shadow-md focus:outline-none"
            >
              {loading ? <ClapSpinner color="#86ff0dc4" size={15} /> : 'Recover Password'}
            </button>
          </div>
        </form>
        <div className="flex justify-between mt-6">
          <Link to="/auth/login" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
            Login
          </Link>
          <Link to="/auth/register" className="text-sm text-gray-400 hover:text-white transition-colors duration-200">
            Register
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
