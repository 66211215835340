import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  FaBuilding,
  FaChevronLeft,
  FaEdit,
  FaFacebook,
  FaGoogle,
  FaHeadphones,
  FaMapMarkerAlt,
  FaMusic,
  FaShareAlt,
  FaTicketAlt,
  FaUserLock,
} from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ActivityData } from 'types'; // Ensure you're importing ActivityData

const translations = {
  english: {
    back: 'Back',
    edit: 'Edit Event',
    error: 'Error',
    fetchError: 'Error fetching event',
    retryLater: 'Please try again later 😞',
    loading: 'Loading...',
    buyTickets: 'Buy Tickets',
    qrCodeTitle: 'Event QR Code',
    activities: 'Activities', // New
    activityPoints: 'Points',  // New
  },
  spanish: {
    back: 'Atrás',
    edit: 'Editar Evento',
    error: 'Error',
    fetchError: 'Error al obtener el evento',
    retryLater: 'Por favor, inténtelo de nuevo más tarde 😞',
    loading: 'Cargando...',
    buyTickets: 'Comprar Entradas',
    qrCodeTitle: 'Código QR del Evento',
    activities: 'Actividades', // New
    activityPoints: 'Puntos',  // New
  },
  polish: {
    back: 'Powrót',
    edit: 'Edytuj Wydarzenie',
    error: 'Błąd',
    fetchError: 'Błąd pobierania wydarzenia',
    retryLater: 'Spróbuj ponownie później 😞',
    loading: 'Ładowanie...',
    buyTickets: 'Kup Bilety',
    qrCodeTitle: 'Kod QR wydarzenia',
    activities: 'Zajęcia', // New
    activityPoints: 'Punkty',  // New
  },
};


const ViewEvent: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<any>(null);
  const [venue, setVenue] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [activities, setActivities] = useState<ActivityData[]>([]); // Holds event activities
  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    if (eventId && currentToken) {
      fetchData();
    }
  }, [eventId, currentToken]);

  const fetchActivities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${APIBase}/client/activity/event/${eventId}`, data);
    if (!response.ok) {
      throw new Error(t.fetchError);
    }
    return response.json();
  };
  
  const fetchData = async () => {
    try {
      const eventData = await fetchEvent();
      const venueData = await fetchVenue(eventData.clubId?._id);
      const activitiesData = await fetchActivities(); // Fetch activities
  
      setEvent(eventData);
      setVenue(venueData);
      setActivities(activitiesData.activities); // Set activities
    } catch (error) {
      setError(t.fetchError);
      Swal.fire({
        title: t.error,
        text: t.retryLater,
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  

  const fetchEvent = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${APIBase}/client/event/byEventId/${eventId}`, data);
    if (!response.ok) {
      throw new Error(t.fetchError);
    }
    return response.json();
  };

  const fetchVenue = async (clubId: string) => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${APIBase}/client/venue/${clubId}`, data);
    if (!response.ok) {
      throw new Error(t.fetchError);
    }
    return response.json();
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleEditClick = () => {
    navigate(`/app/admin/edit-event/${eventId}`);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: event.title,
        text: event.description,
        url: window.location.href,
      });
    } else {
      const shareData = {
        title: event.title,
        text: event.description,
        url: window.location.href,
      };
      navigator.clipboard.writeText(`${shareData.title}\n${shareData.text}\n${shareData.url}`);
      alert('Link copied to clipboard. Share it with your friends!');
    }
  };

  const isIphone = /iPhone|iPod/.test(navigator.userAgent);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <StageSpinner size={50} color="#86ff0dc4" />
      </div>
    );
  }

  if (error) {
    return <div className="text-white">Error: {error}</div>;
  }

  if (!event || !venue) {
    return <div className="text-white">Event or Venue not found</div>;
  }

  console.log(eventId);

  return (
    <div className="w-full px-3 py-3 bg-gradient-to-b from-gray-900 via-black to-gray-900 bg-opacity-90 overflow-scroll rounded-lg shadow-lg pb-24">
      <button onClick={handleBackClick} className="text-white mb-4 mt-20 flex items-center">
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <div className="flex justify-start items-center mb-4">
        <h2 className="text-3xl font-bold text-white">{event.title}</h2>
        <button
          onClick={handleEditClick}
          className="text-white pb-1 pl-5 rounded-lg flex items-center hover:text-neon-purple transition-colors duration-300 text-2xl"
        >
          <FaEdit />
        </button>
      </div>
      <div className="bg-black bg-opacity-90 p-4 rounded-lg shadow-md">
        <img src={event.imageURL} alt={event.title} className="w-full h-64 object-cover rounded-lg mb-4" />
        <p className="text-gray-400 mb-4">{event.description}</p>
        <p className="text-gray-400 mb-2">
          <strong>Date:</strong> {moment(event.date).format('MMMM Do, YYYY')}
        </p>
        <p className="text-gray-400 mb-2">
          <strong>Time:</strong> {event.time}
        </p>
        <p className="text-gray-400 mb-2">
          <strong>Location:</strong> {venue.name}
        </p>
        <p className="text-gray-400 mb-2">
          <strong>Address:</strong> {venue.address}
        </p>

        {event.genres.length > 0 && (
          <div className="flex flex-wrap mt-2">
            <p className="mr-2 h-10 items-center justify-center flex text-gray-400">
              <FaMusic className="mr-2 text-neon-blue" />
              Genres:
            </p>
            {event.genres.map((genre: string, index: number) => (
              <div key={index} className="bg-gray-700 text-white px-2 py-1 rounded-full mr-2 mb-2">
                {genre}
              </div>
            ))}
          </div>
        )}
        {venue.venueTypes && venue.venueTypes.length > 0 && (
          <div className="flex flex-wrap mt-2">
            <p className="mr-2 h-10 items-center justify-center flex text-gray-400">
              <FaBuilding className="mr-2 text-neon-blue" />
              Venue Type:
            </p>
            {venue.venueTypes.map((venueType: string, index: number) => (
              <div key={index} className="bg-gray-700 text-white px-2 py-1 rounded-full mr-2 mb-2">
                {venueType}
              </div>
            ))}
          </div>
        )}
        {event.dressCode && (
          <p className="flex items-center text-gray-400">
            <FaUserLock className="mr-2 text-neon-blue" />
            Dress Code: {event.dressCode}
          </p>
        )}
        {event.ageRestriction && (
          <p className="flex items-center text-gray-400">
            <FaUserLock className="mr-2 text-neon-blue" />
            Age Restriction: {event.ageRestriction}
          </p>
        )}
        {event.djs.length > 0 && (
          <p className="flex items-center text-gray-400">
            <FaHeadphones className="mr-2 text-neon-blue" />
            DJs: {event.djs.join(', ')}
          </p>
        )}
        {event.verified && (
          <div className="bg-black text-xs px-2 py-1 rounded-full border border-neon-green inline-flex items-center mt-2 text-gray-400 mr-3">
            <FaUserLock className="mr-2" />
            Verified
          </div>
        )}
        {event.ticketLink && (
          <div className="mt-4">
            <a
              href={event.ticketLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white bg-green-500 hover:bg-green-600 px-4 py-2 rounded-lg flex items-center justify-center w-full"
            >
              <FaTicketAlt className="mr-2" />
              {t.buyTickets}
            </a>
          </div>
        )}
        <h3 className="text-xl mt-6 text-white">{t.qrCodeTitle}</h3>
        <div className="flex justify-center mt-4 bg-white p-5">
          <QRCode value={`event-${eventId}`} size={128} />
          {/* Generate QR Code with event URL */}
        </div>
        <div className="flex flex-row space-x-4 my-4">
          <a
            href={`https://www.google.com/maps/dir/?api=1&destination=${venue.lat},${venue.lng}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 px-4 py-4 rounded-lg flex items-center justify-center"
          >
            <FaGoogle className="text-2xl" />
          </a>
          <a
            href={`http://maps.apple.com/?daddr=${venue.lat},${venue.lng}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-gradient-to-r from-gray-600 to-gray-800 hover:from-gray-700 hover:to-gray-900 px-4 py-4 rounded-lg flex items-center justify-center"
          >
            <FaMapMarkerAlt className="text-2xl" />
          </a>
          <a
            href={event.facebookLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 px-4 py-4 rounded-lg flex items-center justify-center"
          >
            <FaFacebook className="text-2xl" />
          </a>
          <div
            onClick={handleShare}
            className="text-white bg-gradient-to-r from-blue-700 to-blue-900 hover:from-blue-800 hover:to-blue-1000 px-4 py-4 rounded-lg flex items-center justify-center cursor-pointer"
          >
            <FaShareAlt className="text-2xl" />
          </div>
        </div>
        {activities.length > 0 && (
  <div className="mt-4">
    <h3 className="text-xl text-white mb-2">{t.activities}</h3>
    <ul className="list-disc pl-5">
      {activities.map((activity) => (
        <li key={activity._id} className="text-gray-400">
          {activity.name} ({activity.points} {t.activityPoints.toLowerCase()})
        </li>
      ))}
    </ul>
  </div>
)}
        {/* Event QR Code */}
      </div>
    </div>
  );
};

export default ViewEvent;
