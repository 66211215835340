import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import { APIBase } from 'api/hosts';
import React, { useContext, useEffect, useState } from 'react';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { FaChevronLeft } from 'react-icons/fa'; // Import FaChevronLeft for the return button
import { useNavigate } from 'react-router-dom';

const translations = {
  english: {
    pointsTitle: 'Your Points History',
    totalPoints: 'Total Points',
    date: 'Date',
    activity: 'Activity',
    points: 'Points',
    noPoints: 'No points history available.',
    error: 'Error fetching points. Please try again later.',
    back: 'Back to Profile',
  },
  spanish: {
    pointsTitle: 'Historial de Puntos',
    totalPoints: 'Puntos Totales',
    date: 'Fecha',
    activity: 'Actividad',
    points: 'Puntos',
    noPoints: 'No hay historial de puntos disponible.',
    error: 'Error al obtener los puntos. Por favor, inténtelo más tarde.',
    back: 'Volver al Perfil',
  },
  polish: {
    pointsTitle: 'Historia Punktów',
    totalPoints: 'Suma Punktów',
    date: 'Data',
    activity: 'Aktywność',
    points: 'Punkty',
    noPoints: 'Brak historii punktów.',
    error: 'Błąd pobierania punktów. Spróbuj ponownie później.',
    back: 'Powrót do Profilu',
  },
};

const PointsList: React.FC = () => {
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const t = translations[language as keyof typeof translations];
  const navigate = useNavigate(); // For navigating back

  const [pointsData, setPointsData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchPoints();
  }, []);

  const fetchPoints = async () => {
    setLoading(true);
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/point`, data);
      const result = await response.json();
      if (response.ok) {
        setPointsData(result);
      } else {
        Swal.fire({
          title: t.error,
          text: result.message || t.error,
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.error,
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen p-4 text-white">
      {/* Return Button */}
      <button
        onClick={() => navigate('/app/profile')}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>

      {loading ? (
        <div className="flex justify-center items-center">
          <StageSpinner color="#ffffff" size={50} loading={loading} />
        </div>
      ) : pointsData ? (
        <div className="">
          <h1 className="text-2xl font-bold mb-4 mt-32">{t.pointsTitle}</h1>
          <div className="mb-4">
            <strong>{t.totalPoints}: </strong>
            {pointsData.totalPoints}
          </div>
          {pointsData.pointsLog.length > 0 ? (
            <div className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <table className="w-full table-auto text-white">
                <thead>
                  <tr className="text-left">
                    <th>{t.date}</th>
                    <th>{t.activity}</th>
                    <th>{t.points}</th>
                  </tr>
                </thead>
                <tbody>
                  {pointsData.pointsLog.map((entry: any) => (
                    <tr key={entry._id}>
                      <td>{new Date(entry.date).toLocaleDateString()}</td>
                      <td>{entry.activity}</td>
                      <td>{entry.pointsEarned}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>{t.noPoints}</p>
          )}
        </div>
      ) : (
        <p>{t.noPoints}</p>
      )}
    </div>
  );
};

export default PointsList;
