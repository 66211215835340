import { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const GDPRPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const gdprConsent = localStorage.getItem('gdprConsent');

    if (!gdprConsent) {
      setShowPrompt(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('gdprConsent', 'accepted');
    setShowPrompt(false);
  };

  const handleDeny = () => {
    localStorage.setItem('gdprConsent', 'denied');
    setShowPrompt(false);
  };

  if (!showPrompt) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4" style={{zIndex: 10000}}>
      <div className="flex-1 w-full">
        <h3 className="flex flex-row text-lg font-bold mb-2 text-white w-full">
          Cookies Consent{' '}
          <button
            className="ml-auto text-gray-600 hover:text-gray-900 focus:outline-none"
            onClick={() => setShowPrompt(false)}
          >
            <FaTimes size={20} />
          </button>
        </h3>
        <p className="text-sm text-white">
          This site uses cookies to improve your experience. By continuing to browse the site, you are agreeing to our
          use of cookies.
        </p>
      </div>
      <div className="flex items-center mt-2">
        <button onClick={handleAccept} className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2">
          Accept
        </button>
        <button onClick={handleDeny} className="bg-red-500 text-white px-4 py-2 rounded-lg">
          Deny
        </button>
      </div>
    </div>
  );
};

export default GDPRPrompt;
