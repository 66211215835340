import React from 'react';
import * as styledComponents from './styles';
import { Helmet } from 'react-helmet';

const PubCrawl = () => {
  return (
    <styledComponents.HomeContainer>
      <styledComponents.TakitokContainer>
        <div className="flex flex-col max-w-4xl px-6 pb-32">
          <div className="flex flex-col md:flex-row items-start justify-start mt-28 ">
            <div className="flex flex-col text-white mb-10 md:mb-10 md:mr-10">
              <div className="text-white w-full flex items-center justify-center uppercase text-3xl mb-7">
                Taki Pub Crawl
              </div>
              <h1>Pub Crawl w Katowicach: Doświadcz Najlepszego Nocnego Życia w Mieście!</h1>
              <h2>🌆 Dołącz do Najlepszej Nocnej Przygody w Katowicach!</h2>
              <p>
                Chcesz doświadczyć najlepszego nocnego życia w Katowicach bez konieczności planowania? Nasz pub crawl to
                idealne rozwiązanie! Zanurz się w ekscytujący wieczór pełen śmiechu, nowych znajomości i niesamowitych
                atmosfer.
              </p>

              <h3>Dlaczego Wybrać Nasz Pub Crawl?</h3>
              <ul>
                <li>
                  🍻 Kilka Lokali w Jedną Noc: Od modnych barów po podziemne puby, wybraliśmy dla Ciebie najlepsze
                  miejsca w mieście na niezapomnianą noc.
                </li>
                <li>
                  🎉 Ekskluzywne Promocje: Będąc częścią naszej grupy, skorzystasz z ekskluzywnych promocji na napoje
                  dostępnych tylko dla uczestników naszego pub crawl.
                </li>
                <li>
                  🤝 Poznaj Nowe Osoby: Czy podróżujesz sam, z partnerem czy w grupie, nasz pub crawl to świetny sposób
                  na poznanie nowych ludzi i zawarcie nowych przyjaźni.
                </li>
                <li>
                  🌍 Doskonałe dla Mieszkańców i Turystów: Nowy w mieście czy doświadczony mieszkaniec, zawsze jest coś
                  nowego do odkrycia. Nasi doświadczeni przewodnicy dostarczą ciekawostki, historie i zabawne fakty na
                  temat każdego lokalu i miasta.
                </li>
                <li>
                  🎟️ Prosto i Wygodnie: Wystarczy zarezerwować miejsce, przyjść i pozwolić nam zająć się resztą! Nie
                  musisz się martwić o rezerwacje ani zastanawiać się, dokąd pójść dalej - mamy wszystko zaplanowane.
                </li>
              </ul>

              <h3>Szczegóły Wydarzenia:</h3>
              <p>📅 Terminy: Każdy piątek i sobota (Wydarzenia specjalne mogą się różnić)</p>
              <p>🕣 Początek: 20:00</p>
              <p>
                📍 Miejsce Spotkania: Ul. Stawowa 3, Katowice (Dokładne lokale mogą się różnić, uczestnicy będą
                informowani z wyprzedzeniem)
              </p>
              <p>🔞 Wiek: Musisz mieć 18 lat lub więcej, aby uczestniczyć</p>

              <h3>Ważne Informacje:</h3>
              <ul>
                <li>Pij odpowiedzialnie i znać swoje limity.</li>
                <li>Prosimy o zabranie ważnego dowodu tożsamości do wejścia do lokalu.</li>
                <li>Załóż wygodne buty; trasa będzie zawierała spacer między lokalami.</li>
                <li>Nasi przewodnicy będą łatwo rozpoznawalni dzięki koszulkom czy smyczom z Taki Pub Crawl.</li>
              </ul>

              <h3>Gotowy na Noc, Której Nie Zapomnisz?</h3>
              <p>
                Dołącz do nas i odkryj puls nocnego życia Katowic. Czy chcesz tańczyć całą noc, czy degustować najlepsze
                lokalne trunki, nasz pub crawl oferuje coś dla każdego.
              </p>
              <p>Zarezerwuj teraz i rozpocznij przygodę!</p>
            </div>
            <iframe
              style={{
                border: '0px none transparent',
                overflow: 'hidden auto',
                margin: '0px',
                padding: '0px',
                width: '100%',
                height: '830px',
              }}
              src={
                'https://assets.ticketinghub.com/checkout/index-f6ebc991c1447fd15546256f11532c9190e1f87d.html#options=%7B%22context%22%3A%22customer%22%2C%22layout%22%3A%22embed%22%2C%22landing%22%3A%22calendar%22%2C%22color%22%3A%22%2300baef%22%2C%22footer%22%3Atrue%2C%22discounts%22%3Atrue%2C%22subscribe%22%3Atrue%2C%22avs%22%3Afalse%2C%22free%22%3Afalse%2C%22referral-code-param-name%22%3A%22th-reseller-ref%22%2C%22coupon-param-name%22%3Afalse%2C%22fields%22%3A%22name%2Cemail%2Ctelephone%22%2C%22event-period%22%3A%227%22%2C%22events-view-mode%22%3A%22multi-day%22%2C%22collect-voucher-recipient-info%22%3Atrue%2C%22button-label%22%3A%22BOOK%20NOW%22%2C%22social-sharing%22%3Afalse%2C%22book-more%22%3Afalse%2C%22book-more-if-tickets-limit-reached%22%3Afalse%2C%22show-product-logo%22%3Afalse%2C%22combination-discounts%22%3Atrue%2C%22show-product-description%22%3Afalse%2C%22gift-cards%22%3Atrue%2C%22ga-track-pageviews%22%3Atrue%2C%22ga-track-purchases%22%3Afalse%2C%22ga-enable-debug-mode%22%3Afalse%2C%22extras-right-after-tickets%22%3Afalse%2C%22events-show-product-description%22%3Afalse%2C%22display-currency-as-symbol%22%3Afalse%2C%22extra-tickets%22%3Afalse%2C%22queueit%22%3Afalse%2C%22read-only-api%22%3Afalse%2C%22hide-option-prices%22%3Afalse%2C%22channel%22%3A%22389b840b-7be7-4d28-b5c5-658c2992f4a1%22%2C%22name%22%3A%22Pub%20Crawl%22%2C%22products_enabled%22%3Atrue%2C%22products%22%3A%2225440b36-8293-45e6-a03d-e22afa717a38%22%2C%22referrer%22%3A%22https%3A%2F%2Fdashboard.ticketinghub.com%2Fsupplier%2Fwidgets%2F0da20fe0-086e-4a22-b888-efc74c6c944b%22%2C%22endpoint%22%3A%22https%3A%2F%2Fapi.ticketinghub.com%22%2C%22extras_enabled%22%3Afalse%2C%22packages_enabled%22%3Afalse%2C%22merchandises_enabled%22%3Afalse%2C%22widget%22%3A%220da20fe0-086e-4a22-b888-efc74c6c944b%22%2C%22referral-code%22%3Anull%2C%22origin%22%3A%22https%3A%2F%2Fdashboard.ticketinghub.com%22%2C%22parentWidth%22%3A1920%7D'
              }
            ></iframe>
          </div>
        </div>
      </styledComponents.TakitokContainer>
    </styledComponents.HomeContainer>
  );
};
export default PubCrawl;
