import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

interface RoomCardProps {
  imgSrc: string;
  title: string;
  description: string;
  onClick: () => void;
}

const RoomCard: React.FC<RoomCardProps> = ({ imgSrc, title, description, onClick }) => (
  <Card style={{ width: '18rem' }} className="m-5 bg-black rounded-lg">
    <Card.Img variant="top" src={imgSrc} className="rounded-t-lg" />
    <Card.Body>
      <Card.Title className="text-white w-full flex items-center justify-center uppercase mt-3 text-xl">
        {title}
      </Card.Title>
      <Card.Text className="text-white p-3 w-full">{description}</Card.Text>
      <Button
        variant="primary"
        className="bg-white text-black mx-3 cursor-pointer p-3 rounded-lg w-full"
        onClick={onClick}
      >
        Sprawdź wydarzenia
      </Button>
    </Card.Body>
  </Card>
);

export default RoomCard;
