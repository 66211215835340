import React from 'react';
import Typed from 'react-typed';

interface HeroSectionProps {
  language: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ language }) => {
  return (
    <div className="h-4/5 w-screen flex-shrink-0 flex items-center justify-center flex-col bg-black bg-opacity-30">
      <img alt="Logo Taki" className="h-60" src={'assets/sun3RemovebgPrev.png'} />
      <div className="text-white text-xl md:text-3xl uppercase font-Khula max-w-90 px-10">
        <Typed
          strings={[
            language === 'english' ? 'All what you need for a Party Night!' : 
            language === 'spanish' ? '¡Todo lo que necesitas para una noche de fiesta!' : 
            'Wszystko, czego potrzebujesz na nocną imprezę!',
            'Party',
            'Karaoke',
            'Games',
            'Events',
            'and more!',
            language === 'english' ? 'All what you need for a Party Night!' : 
            language === 'spanish' ? '¡Todo lo que necesitas para una noche de fiesta!' : 
            'Wszystko, czego potrzebujesz na nocną imprezę!'
          ]}
          typeSpeed={40}
        />
      </div>
    </div>
  );
};

export default HeroSection;
