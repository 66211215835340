import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import LowerBar from 'PartyTicket/LowerBar';
import { useContext, useState } from 'react';
import { QrReader } from 'react-qr-reader'; // Use @react-qr-reader for camera selection
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit'; // Importing a loading spinner
import Swal from 'sweetalert2';

const QRScan = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cameraError, setCameraError] = useState(false); // Handle camera errors
  const [scannedData, setScannedData] = useState(null); // Store detected QR data for manual confirmation
  const { currentToken } = useContext(AuthContext);
  const navigate = useNavigate();

  // Function to process the scan (triggered by user action)
  const processScan = async () => {
    setLoading(true); // Start loading spinner

    try {
      let endpoint = '';
      let id = '';

      // Check the prefix and assign the appropriate endpoint and ID
      if (scannedData.text.startsWith('venue-')) {
        endpoint = '/client/venue/scan-qr';
        id = scannedData.text.replace('venue-', '');
      } else if (scannedData.text.startsWith('event-')) {
        endpoint = '/client/event/scan-qr';
        id = scannedData.text.replace('event-', '');
      } else {
        throw new Error('Invalid QR code');
      }

      const response = await fetch(`${APIBase}${endpoint}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }), // Send the venue or event ID
      });

      const result = await response.json();

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'You earned 10 points!',
          icon: 'success',
          timer: 2000,
        }).then(() => navigate('/app/profile')); // Navigate to profile after success
      } else {
        if (result.message === 'You have already checked in within the last 12 hours.') {
          // Handle the 12-hour restriction message
          Swal.fire({
            title: 'Already Checked In',
            text: result.message,
            icon: 'info',
            timer: 2000,
          });
        } else {
          throw new Error(result.message || 'Failed to award points');
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to scan QR code. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleError = (err) => {
    setError('An error occurred during scanning. Please try again.');
    setCameraError(true); // Set the camera error state if there is an issue with the camera
    console.error(err);
  };

  const previewStyle = {
    height: 320,
    width: 320,
    borderRadius: '10px',
    overflow: 'hidden',
    border: '2px solid #86ff0d',
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen bg-gray-900 px-4 pt-28 text-white">
      {loading ? (
        <div className="flex flex-col items-center justify-center">
          <StageSpinner size={60} color="#86ff0d" />
          <p className="text-white mt-4">Processing QR Code...</p>
        </div>
      ) : (
        <>
          <h2 className="text-2xl text-white mb-4">Scan QR Code</h2>
          {scannedData ? (
            <>
              <h3 className="text-xl mb-4">QR Code Detected!</h3>
              <p>
                <strong>{scannedData.text.startsWith('venue-') ? 'Venue detected' : 'Event Detected'}</strong>
              </p>

              <button
                className="mt-6 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                onClick={processScan}
              >
                Claim my points +10
              </button>
              <button
                className="mt-6 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 ml-4"
                onClick={() => setScannedData(null)} // Allow to re-scan
              >
                Re-scan QR Code
              </button>
            </>
          ) : (
            <>
              {!cameraError ? (
                <div className="relative w-full h-56">
                  <QrReader
                    delay={300}
                    constraints={{ facingMode: 'environment' }} // Use the default camera, typically the back camera
                    style={previewStyle}
                    onError={handleError}
                    onResult={(result) => {
                      if (result) {
                        setScannedData(result); // Store the scanned data
                      }
                    }}
                  />
                </div>
              ) : (
                <p className="text-red-500">Camera access denied or not available. Please check your permissions.</p>
              )}
            </>
          )}
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </>
      )}
      <LowerBar />
    </div>
  );
};

export default QRScan;
