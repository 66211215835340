import React from 'react';
import RoomCard from './RoomCard';
import { Room } from './data';

interface RoomListProps {
  rooms: Room[];
  goToEvents: () => void;
}

const RoomList: React.FC<RoomListProps> = ({ rooms, goToEvents }) => {
  return (
    <div className="w-screen flex items-center justify-center bg-black bg-opacity-80 p-5">
      <div className="flex flex-col md:flex-row items-center justify-center" style={{ marginTop: '-120px' }}>
        {rooms.map((room) => (
          <RoomCard
            key={room.title}
            title={room.title}
            description={room.description}
            imgSrc={room.imgSrc}
            onClick={goToEvents}
          />
        ))}
      </div>
    </div>
  );
};

export default RoomList;
