import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft, FaEdit, FaTrash } from 'react-icons/fa'; // Icons for editing/deleting
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select'; // Import react-select
import Swal from 'sweetalert2';
import { ActivityData, DaySchedule, VenueData } from 'types';
import ScheduleManager from '../ScheduleManager';

const EditVenue: React.FC = () => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const { venueId } = useParams<{ venueId: string }>();
  const [venueData, setVenueData] = useState<VenueData>({
    name: '',
    address: '',
    description: '',
    imageURL: '',
    lat: '',
    lng: '',
    venueTypes: [], // Updated to be an array of strings
    schedule: [
      { day: 'Monday', times: [] },
      { day: 'Tuesday', times: [] },
      { day: 'Wednesday', times: [] },
      { day: 'Thursday', times: [] },
      { day: 'Friday', times: [] },
      { day: 'Saturday', times: [] },
      { day: 'Sunday', times: [] },
    ],
    facebookLink: '', // Added fields for social media links
    instagramLink: '',
    tiktokLink: '',
    websiteLink: '',
  });

  const [activities, setActivities] = useState<ActivityData[]>([]); // State for managing activities
  const [newActivity, setNewActivity] = useState<ActivityData>({
    name: '',
    points: 0,
  }); // State for new activity

  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal state
  const [selectedActivity, setSelectedActivity] = useState<ActivityData | null>(null); // Selected activity for editing

  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();

  const translations = {
    english: {
      back: 'Back',
      editVenue: 'Edit Venue',
      name: 'Name',
      address: 'Address',
      description: 'Description',
      imageURL: 'Image URL',
      uploadNewImage: 'Upload New Image',
      latitude: 'Latitude',
      longitude: 'Longitude',
      venueTypes: 'Venue Types',
      facebook: 'Facebook Link',
      instagram: 'Instagram Link',
      tiktok: 'TikTok Link',
      website: 'Website Link',
      updateVenue: 'Update Venue',
      activities: 'Activities',
      addActivity: 'Add Activity',
      activityName: 'Activity Name',
      activityPoints: 'Activity Points',
      saveActivity: 'Save Activity',
      editActivity: 'Edit Activity',
      deleteActivity: 'Delete Activity',
      cancel: 'Cancel', // Added translation for Cancel
      success: 'Success',
      updateSuccess: 'Venue updated successfully! 🎉',
      activitySuccess: 'Activity updated successfully!',
      deleteSuccess: 'Activity deleted successfully!',
      error: 'Error',
      fetchError: 'Failed to fetch venue. Please try again later.',
      uploadError: 'Image upload failed.',
      updateError: 'Failed to update venue. Please try again later.',
      deleteError: 'Failed to delete activity. Please try again later.',
    },
    spanish: {
      back: 'Atrás',
      editVenue: 'Editar Lugar',
      name: 'Nombre',
      address: 'Dirección',
      description: 'Descripción',
      imageURL: 'URL de la Imagen',
      uploadNewImage: 'Subir Nueva Imagen',
      latitude: 'Latitud',
      longitude: 'Longitud',
      venueTypes: 'Tipos de Lugar',
      facebook: 'Enlace de Facebook',
      instagram: 'Enlace de Instagram',
      tiktok: 'Enlace de TikTok',
      website: 'Enlace del sitio web',
      updateVenue: 'Actualizar Lugar',
      activities: 'Actividades',
      addActivity: 'Añadir Actividad',
      activityName: 'Nombre de la Actividad',
      activityPoints: 'Puntos de la Actividad',
      saveActivity: 'Guardar Actividad',
      editActivity: 'Editar Actividad',
      deleteActivity: 'Eliminar Actividad',
      cancel: 'Cancelar', // Added translation for Cancel
      success: 'Éxito',
      updateSuccess: '¡Lugar actualizado con éxito! 🎉',
      activitySuccess: '¡Actividad actualizada con éxito!',
      deleteSuccess: '¡Actividad eliminada con éxito!',
      error: 'Error',
      fetchError: 'No se pudo obtener el lugar. Por favor, inténtelo de nuevo más tarde.',
      uploadError: 'La carga de la imagen falló.',
      updateError: 'No se pudo actualizar el lugar. Por favor, inténtelo de nuevo más tarde.',
      deleteError: 'No se pudo eliminar la actividad. Por favor, inténtelo de nuevo más tarde.',
    },
    polish: {
      back: 'Powrót',
      editVenue: 'Edytuj Miejsce',
      name: 'Nazwa',
      address: 'Adres',
      description: 'Opis',
      imageURL: 'URL obrazu',
      uploadNewImage: 'Prześlij Nowy Obraz',
      latitude: 'Szerokość geograficzna',
      longitude: 'Długość geograficzna',
      venueTypes: 'Rodzaje Miejsc',
      facebook: 'Link do Facebooka',
      instagram: 'Link do Instagrama',
      tiktok: 'Link do TikToka',
      website: 'Link do strony internetowej',
      updateVenue: 'Zaktualizuj Miejsce',
      activities: 'Zajęcia',
      addActivity: 'Dodaj Zajęcie',
      activityName: 'Nazwa Zajęcia',
      activityPoints: 'Punkty za Zajęcie',
      saveActivity: 'Zapisz Zajęcie',
      editActivity: 'Edytuj Zajęcie',
      deleteActivity: 'Usuń Zajęcie',
      cancel: 'Anuluj', // Added translation for Cancel
      success: 'Sukces',
      updateSuccess: 'Miejsce zostało pomyślnie zaktualizowane! 🎉',
      activitySuccess: 'Zajęcie zostało pomyślnie zaktualizowane!',
      deleteSuccess: 'Zajęcie zostało pomyślnie usunięte!',
      error: 'Błąd',
      fetchError: 'Nie udało się pobrać miejsca. Spróbuj ponownie później.',
      uploadError: 'Przesyłanie obrazu nie powiodło się.',
      updateError: 'Nie udało się zaktualizować miejsca. Spróbuj ponownie później.',
      deleteError: 'Nie udało się usunąć zajęcia. Spróbuj ponownie później.',
    },
    // Add more languages as necessary
  };

  type Language = 'english' | 'spanish' | 'polish';

  const t = translations[language as Language];

  useEffect(() => {
    fetchVenue();
    fetchActivities();
  }, [venueId]);

  const fetchActivities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/activity/venue/${venueId}`, data);
      if (!response.ok) {
        throw new Error(t.fetchError); // Throw error if response is not OK
      }

      const result = await response.json();
      setActivities(result.activities);
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchError + ' (Activities)', // Custom error message for activities
        icon: 'error',
        timer: 3000,
      });
    }
  };
  const handleActivitySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...newActivity, venueId }),
    };
    await fetch(`${APIBase}/client/activity`, data)
      .then((response) => response.json())
      .then(() => {
        setNewActivity({ name: '', points: 0 }); // Reset form
        fetchActivities(); // Fetch updated activities list
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.updateError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const handleActivityDelete = async (activityId: string) => {
    const data: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    await fetch(`${APIBase}/client/activity/${activityId}`, data)
      .then(() => {
        fetchActivities(); // Refresh the list of activities after deletion
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.updateError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const handleActivityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewActivity({ ...newActivity, [name]: value });
  };

  const openEditModal = (activity: ActivityData) => {
    setSelectedActivity(activity);
    setIsEditModalOpen(true);
  };

  const handleActivityEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedActivity) {
      setSelectedActivity({ ...selectedActivity, [e.target.name]: e.target.value });
    }
  };

  const handleActivityEditSubmit = async () => {
    if (selectedActivity) {
      const data: RequestInit = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedActivity),
      };
      await fetch(`${APIBase}/client/activity/${selectedActivity._id}`, data)
        .then((response) => response.json())
        .then(() => {
          setIsEditModalOpen(false); // Close modal
          fetchActivities(); // Refresh activities list
        })
        .catch(() => {
          Swal.fire({
            title: t.error,
            text: t.updateError,
            icon: 'error',
            timer: 3000,
          });
        });
    }
  };

  const fetchVenue = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/venue/${venueId}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.fetchError);
        }
      })
      .then((responseJson) => {
        setVenueData({
          ...responseJson,
          lat: String(responseJson.lat), // Convert lat and lng to string for input fields
          lng: String(responseJson.lng),
        });
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setVenueData({ ...venueData, [name]: value });
  };

  const handleScheduleChange = (schedule: DaySchedule[]) => {
    setVenueData({ ...venueData, schedule });
  };

  const handleVenueTypesChange = (selectedOptions: any) => {
    const types = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setVenueData({ ...venueData, venueTypes: types });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let uploadedImageUrl = venueData.imageURL;

    if (imageFile) {
      const formData = new FormData();
      formData.append('mediaUpload', imageFile);

      const uploadResponse = await fetch(`${APIBase}/client/venue/upload-media`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
        body: formData,
      });

      if (uploadResponse.ok) {
        const uploadResult = await uploadResponse.json();
        uploadedImageUrl = uploadResult.imageURL;
      } else {
        Swal.fire({
          title: t.error,
          text: t.uploadError,
          icon: 'error',
          timer: 3000,
        });
        return;
      }
    }

    const finalVenueData = {
      ...venueData,
      imageURL: uploadedImageUrl,
      lat: Number(venueData.lat), // Convert lat and lng back to numbers before submission
      lng: Number(venueData.lng),
    };

    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(finalVenueData),
    };

    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.success,
            text: t.updateSuccess,
            icon: 'success',
            timer: 2000,
          });
          navigate('/app/admin/venues');
        } else {
          Swal.fire({
            title: t.error,
            text: t.updateError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.updateError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const venueTypeOptions = [
    { value: 'bar', label: 'Bar' },
    { value: 'club', label: 'Club' },
    { value: 'rooftop', label: 'Rooftop' },
    { value: 'beachbar', label: 'Beach Bar' },
    { value: 'lounge', label: 'Lounge' },
    { value: 'pub', label: 'Pub' },
    { value: 'restaurant', label: 'Restaurant' },
    { value: 'nightclub', label: 'Nightclub' },
    // Add more types as necessary
  ];

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 pt-20 px-2 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg mx-2" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.editVenue}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="name">
            {t.name}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={venueData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="address">
            {t.address}
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={venueData.address}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="description">
            {t.description}
          </label>
          <textarea
            id="description"
            name="description"
            value={venueData.description}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="imageURL">
            {t.imageURL}
          </label>
          <input
            type="text"
            id="imageURL"
            name="imageURL"
            value={venueData.imageURL}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="imageUpload">
            {t.uploadNewImage}
          </label>
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files ? e.target.files[0] : null)}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="lat">
            {t.latitude}
          </label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={venueData.lat}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="lng">
            {t.longitude}
          </label>
          <input
            type="text"
            id="lng"
            name="lng"
            value={venueData.lng}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="venueTypes">
            {t.venueTypes}
          </label>
          <Select
            id="venueTypes"
            isMulti
            options={venueTypeOptions}
            value={venueTypeOptions.filter((option) => venueData.venueTypes.includes(option.value))}
            onChange={handleVenueTypesChange}
            className="w-full text-black rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="facebookLink">
            {t.facebook}
          </label>
          <input
            type="text"
            id="facebookLink"
            name="facebookLink"
            value={venueData.facebookLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="instagramLink">
            {t.instagram}
          </label>
          <input
            type="text"
            id="instagramLink"
            name="instagramLink"
            value={venueData.instagramLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="tiktokLink">
            {t.tiktok}
          </label>
          <input
            type="text"
            id="tiktokLink"
            name="tiktokLink"
            value={venueData.tiktokLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="websiteLink">
            {t.website}
          </label>
          <input
            type="text"
            id="websiteLink"
            name="websiteLink"
            value={venueData.websiteLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-8">
          <ScheduleManager schedule={venueData.schedule} onScheduleChange={handleScheduleChange} />
        </div>
        <div className="mb-6">
          <h3 className="text-xl mb-2">{t.activities}</h3>
          <div className="space-y-2">
            {activities.map((activity) => (
              <div
                key={activity._id}
                className="flex justify-between items-center bg-gray-800 p-2 rounded-lg shadow-sm"
              >
                <span className="text-sm">
                  {activity.name} ({activity.points} {t.activityPoints.toLowerCase()})
                </span>
                <div className="flex space-x-1">
                  <button
                    className="px-1 py-1 bg-red-600 rounded hover:bg-red-700"
                    onClick={() => activity?._id && handleActivityDelete(activity._id)}
                  >
                    <FaTrash className="text-xs" />
                  </button>
                  <button
                    className="px-1 py-1 bg-blue-600 rounded hover:bg-blue-700"
                    onClick={() => openEditModal(activity)}
                  >
                    <FaEdit className="text-xs" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm mb-1" htmlFor="activityName">
            {t.activityName}
          </label>
          <div className="flex space-x-2">
            <input
              type="text"
              id="activityName"
              name="name"
              value={newActivity.name}
              onChange={handleActivityChange}
              className="w-2/3 px-2 py-1 bg-gray-800 text-white rounded text-sm"
              placeholder={t.activityName}
            />
            <input
              type="number"
              id="activityPoints"
              name="points"
              value={newActivity.points}
              onChange={handleActivityChange}
              className="w-1/3 px-2 py-1 bg-gray-800 text-white rounded text-sm"
              placeholder={t.activityPoints}
            />
          </div>
        </div>

        <button
          type="button"
          onClick={handleActivitySubmit}
          className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-500 text-sm"
        >
          {t.addActivity}
        </button>
        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.updateVenue}
          </button>
        </div>
      </form>

      {/* Modal for Editing Activity */}
      {isEditModalOpen && selectedActivity && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl mb-4">{t.editActivity}</h3>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.activityName}</label>
              <input
                type="text"
                name="name"
                value={selectedActivity.name}
                onChange={handleActivityEditChange}
                className="w-full px-3 py-2 bg-gray-800 text-white rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.activityPoints}</label>
              <input
                type="number"
                name="points"
                value={selectedActivity.points}
                onChange={handleActivityEditChange}
                className="w-full px-3 py-2 bg-gray-800 text-white rounded"
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setIsEditModalOpen(false)}
                className="px-3 py-2 bg-gray-600 rounded hover:bg-gray-700"
              >
                {t.cancel}
              </button>
              <button onClick={handleActivityEditSubmit} className="px-3 py-2 bg-blue-600 rounded hover:bg-blue-700">
                {t.saveActivity}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditVenue;
