import styled from 'styled-components';

export const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  background-image: url("assets/back_facebook.jpg");
  background-position: top; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  @media (min-width: 768 ) {
    padding-bottom: 80px;
}

  font-family: Khula, Arial;
  overflow-y: scroll;
`;

export const HeroContainer = styled.div`
  height: 80vh;
  flex-shrink: 0;
  width: 100vw;
  display: flex;
  flex-shrink: 'no-shrink';
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0,0,0,0.3);
`;

export const BarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
  width: 100vw;
  padding: 20px;
`;

export const TakitokContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
  width: 100vw;
  padding: 20px;
`;

export const NewsContainer = styled.div`
  position: fixed;
  color: #d6e1ef;
  background: linear-gradient(#140a0899 0%, #37233dde 100%);
  flex-direction: column;
  display: flex;
  top: 45%;
  right: 0px;
`;

export const ButtonNews = styled.div`
  color: #021b09c7; 
  border: none;
  border-radius: 50px;
  flex-direction: row;
  flex-basis: 27%;
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  margin-left: 3px;
  padding-bottom: 1px;
  padding-right: 2px;
  font-family: Khula, Arial;
  font-weight: 800;
  line-height: 35px;
  display: flex;
`;
