import React from 'react';
import * as styledComponents from './styles';

const TakiTon = () => {
  return (
    <styledComponents.HomeContainer>
      <styledComponents.TakitokContainer>
        <div className="flex flex-col max-w-4xl px-6 pb-32">
          <div className="flex flex-col md:flex-row items-start justify-start mt-28 ">
            <div className="flex flex-col text-white mb-10 md:mb-10 md:mr-10">
              {/* Polish Description */}
              <div className="text-white w-full flex items-center justify-center uppercase text-3xl">
                🌍🔥 Takiton: Największa impreza latino na Śląsku! 🔥 + Bitwa DJ-ów Latino 🎧
              </div>
              <h2>Zanurz się w nocy, gdzie Latino i rytmy świata zderzają się! 🎵</h2>
              <p>To nie jest zwykła impreza muzyczna, to LATINO i MIĘDZYNARODOWA BASH MUZYCZNA w mieście! Od pulsujących rytmów Ameryki Łacińskiej do elektryzujących dźwięków sceny międzynarodowej - mamy to wszystko! Nasz line-up prezentuje NAJLEPSZYCH DJ-ów w mieście 🎧:</p>
              <p>Pokazy DJ-ów:</p>
              <p>21:00 - 22:00 DJ Siwar (z Peru) 🇵🇪</p>
              <p>22:00 - 24:00 Goście DJs!</p>
              <p>24:00 - 00:30 DJ Jhoa (z Peru) 🇵🇪</p>
              <p>00:30 - 1:00 DJ GM (z Polski) 🇵🇱</p>
              <p>1:00 - 1:30 DJ Kinder Bueno (z Peru) 🇵🇪</p>
              <p>Całą noc animacja z Cuban Dance Asley (z Kuby) 🎉</p>
              <p>🎨 Bezpłatne malowanie twarzy przez profesjonalnych wizażystów!</p>
              <p>A o 2:00 rano... wybuch tradycyjnych Piñatas pełnych niespodzianek w środku!</p>
              <p>Ale to nie wszystko! Na Takiton chodzi o coś więcej niż tylko muzykę. Spodziewaj się eksplozji kultury, z żywymi grami, kuszącymi niespodziankami i publicznością, która potrafi się bawić!</p>
              <p>Zaznacz w kalendarzu i przygotuj się na zanurzenie w magii muzyki na Takiton! Przygotuj się na noc niezapomnianych melodii i hipnotyzujących bitów. Dołącz do nas na noc jedności, rytmu i czystego świętowania. Takiton - tam, gdzie muzyka nie zna granic.</p>
              <div className="eventDetails">
                <p>🗓️ Data: 13.10.23</p>
                <p>🕗 Godzina: 21:00</p>
                <p>📍 Miejsce: Królestwo, Rondo im, rondo Generała Jerzego Ziętka 1</p>
              </div>
              <p>Bilety Regularne:</p>
              <p>🎫Early Bird (do 15/08): 20.00 zł (max 100)</p>
              <p>🎫Standard Ticket (do 13/10 o 16:00): 25.00 zł</p>
              <p>🎫Door: 30.00zl</p>
              <p>Special Tickets:</p>
              <p>🎫DJ Booth 50.00 zł</p>
              {/* English Description */}
              <div className="text-white w-full flex items-center justify-center uppercase text-3xl mt-10">
                🌍🔥 Takiton: The biggest Latino Party in Silesia! 🔥 + Latino DJs Battle Show 🎧
              </div>
              <h2>Step into a night where Latino and world rhythms collide! 🎵</h2>
              <p>This isn't just any music bash; it's THE Latino & International Music Bash of the city! From pulsating Latin rhythms to electrifying sounds of the global scene - we've got it all! Our line-up showcases the BEST DJs in town 🎧:</p>
              <p>DJ Shows:</p>
              <p>9:00 PM - 10:00 PM DJ Siwar (from Peru) 🇵🇪</p>
              <p>10:00 PM - 12:00 AM Guest DJs!</p>
              <p>12:00 AM - 12:30 AM DJ Jhoa (from Peru) 🇵🇪</p>
              <p>12:30 AM - 1:00 AM DJ GM (from Poland) 🇵🇱</p>
              <p>1:00 AM - 1:30 AM DJ Kinder Bueno (from Peru) 🇵🇪</p>
              <p>All night long animation with Cuban Dance Asley (from Cuba) 🎉</p>
              <p>🎨 Free face painting by professional makeup artists!</p>
              <p>And at 2:00 AM... burst of traditional Piñatas filled with surprises inside!</p>
              <p>But that's not all! Takiton is more than just music. Expect a cultural explosion, with live games, tempting surprises, and an audience that knows how to party!</p>
              <p>Mark your calendar and get ready to dive into the magic of music at Takiton! Prepare for a night of unforgettable melodies and mesmerizing beats. Join us for a night of unity, rhythm, and pure celebration. Takiton - where music knows no boundaries.</p>
              <div className="eventDetails">
                <p>🗓️ Date: 10/13/23</p>
                <p>🕗 Time: 9:00 PM</p>
                <p>📍 Location: Królestwo, Rondo im, rondo Generała Jerzego Ziętka 1</p>
              </div>
              <p>Regular Tickets:</p>
              <p>🎫Early Bird (until 08/15): PLN 20.00 (max 100)</p>
              <p>🎫Standard Ticket (until 10/13 at 4:00 PM): PLN 25.00</p>
              <p>🎫Door: PLN 30.00</p>
              <p>Special Tickets:</p>
              <p>🎫DJ Booth PLN 50.00</p>
            </div>
            <iframe
              title={'Takiton Subpage Frame'}
              style={{
                border: '0px none transparent',
                overflow: 'hidden auto',
                margin: '0px',
                padding: '0px',
                width: '100%',
                height: '830px',
              }}
              src={
                'https://assets.ticketinghub.com/checkout/index-093973fe4911f6e19e54f36fb3093d5430858e1d.html#options=%7B%22context%22%3A%22customer%22%2C%22layout%22%3A%22embed%22%2C%22landing%22%3A%22tickets%22%2C%22color%22%3A%22%23000000%22%2C%22footer%22%3Atrue%2C%22discounts%22%3Atrue%2C%22subscribe%22%3Atrue%2C%22avs%22%3Afalse%2C%22free%22%3Afalse%2C%22referral-code-param-name%22%3A%22th-reseller-ref%22%2C%22coupon-param-name%22%3Afalse%2C%22fields%22%3A%22name%2Cemail%2Ctelephone%22%2C%22event-period%22%3A%227%22%2C%22events-view-mode%22%3A%22one-day%22%2C%22collect-voucher-recipient-info%22%3Atrue%2C%22button-label%22%3A%22BOOK%20NOW%22%2C%22social-sharing%22%3Afalse%2C%22book-more%22%3Afalse%2C%22book-more-if-tickets-limit-reached%22%3Afalse%2C%22show-product-logo%22%3Afalse%2C%22combination-discounts%22%3Atrue%2C%22show-product-description%22%3Afalse%2C%22gift-cards%22%3Atrue%2C%22ga-track-pageviews%22%3Atrue%2C%22ga-track-purchases%22%3Afalse%2C%22ga-enable-debug-mode%22%3Afalse%2C%22extras-right-after-tickets%22%3Afalse%2C%22events-show-product-description%22%3Afalse%2C%22display-currency-as-symbol%22%3Atrue%2C%22extra-tickets%22%3Afalse%2C%22queueit%22%3Afalse%2C%22read-only-api%22%3Afalse%2C%22hide-option-prices%22%3Afalse%2C%22channel%22%3A%22389b840b-7be7-4d28-b5c5-658c2992f4a1%22%2C%22name%22%3A%22Takiton%22%2C%22products_enabled%22%3Atrue%2C%22products%22%3A%2237e7bc5e-b259-44f8-96bb-95ed078e91a1%22%2C%22referrer%22%3A%22https%3A%2F%2Fdashboard.ticketinghub.com%2Fsupplier%2Fwidgets%2F5e2975c3-3360-44db-ae91-6d252f855ea0%22%2C%22endpoint%22%3A%22https%3A%2F%2Fdashboard-api.ticketinghub.com%22%2C%22extras_enabled%22%3Afalse%2C%22packages_enabled%22%3Afalse%2C%22merchandises_enabled%22%3Afalse%2C%22widget%22%3A%225e2975c3-3360-44db-ae91-6d252f855ea0%22%2C%22referral-code%22%3Anull%2C%22origin%22%3A%22https%3A%2F%2Fdashboard.ticketinghub.com%22%2C%22parentWidth%22%3A1920%7D'
              }
            ></iframe>
          </div>
        </div>
      </styledComponents.TakitokContainer>
    </styledComponents.HomeContainer>
  );
};

export default TakiTon;