import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import { useLocationContext } from 'Contexts/LocationContext';
import { useContext, useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaUserCircle } from 'react-icons/fa'; // Import the profile icon
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CitySelectionModal from '../../PrivateApp/CitySelectionModal';

Modal.setAppElement('#root');

const NavElements = () => {
  const { language, setLanguage, options } = useLanguage();
  const { currentToken } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const { selectedCity, isUsingGPS, setSelectedCity, fetchUserCity } = useLocationContext();
  const [cities, setCities] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentToken) {
      fetchCities(); // Fetch the list of cities if there is a valid token
    }
  }, [currentToken]);

  useEffect(() => {
    fetchUserCity(); // Fetch the user city after cities are loaded
  }, []);

  const fetchCities = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/city/cities`, data);
      if (response.ok) {
        const data = await response.json();
        setCities(data);
      } else {
        throw new Error('Error fetching cities');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch cities. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
    setIsModalOpen(false);
  };

  const handleLocationClick = () => {
    setIsCityModalOpen(true);
  };

  const handleCitySelection = async (cityId: string) => {
    localStorage.setItem('selectedCity', cityId);
    setSelectedCity(cityId);
    setIsCityModalOpen(false);
  };

  return (
    <>
      <div
        className="fixed left-0 top-0 w-full flex items-center justify-center flex-row h-16 py-10"
        style={{ zIndex: 999 }}
      >
        <div className="ml-4 max-w-xs absolute right-2 flex flex-row items-center justify-end space-x-2">
          {currentToken && (
            <button
              onClick={handleLocationClick}
              className="text-white text-xs flex items-center justify-center p-1 px-3 rounded-lg h-8 bg-black hover:bg-gray-700 focus:outline-none transition-all duration-200"
            >
              <FaMapMarkerAlt className="text-lg mr-1" />
              {selectedCity ? (
                isUsingGPS ? (
                  <>
                    <span>GPS</span>
                    <span className="w-2 h-2 bg-green-500 rounded-full ml-1"></span>
                  </>
                ) : cities.length && selectedCity !== 'Using GPS Location' ? (
                  <span>{cities.find((city) => city._id === selectedCity)?.name}</span>
                ) : (
                  <span>{selectedCity}</span>
                )
              ) : (
                <span>Select City</span>
              )}
            </button>
          )}
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-black text-white text-xs p-1 rounded-lg px-3 h-8 hover:bg-gray-700 focus:outline-none transition-all duration-200"
          >
            {options.find((option) => option.value === language)?.label || 'Language'}
          </button>
          {/* New Profile Button */}
          {currentToken && (
            <button
              onClick={() => navigate('/app/profile')}
              className="bg-black text-white p-1 rounded-lg h-8 w-8 flex items-center justify-center hover:bg-gray-700 focus:outline-none transition-all duration-200"
            >
              <FaUserCircle className="text-lg" />
            </button>
          )}
        </div>
      </div>

      {isCityModalOpen && (
        <CitySelectionModal onCitySelect={handleCitySelection} onClose={() => setIsCityModalOpen(false)} />
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Select Language"
        className="fixed inset-0 flex items-center justify-center z-50 py-8"
        overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-40"
        style={{
          content: {
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '20%',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '10px',
            padding: '20px',
          },
        }}
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl mb-4">Select Language</h2>
          <ul className="flex flex-col space-y-2 w-full">
            {options.map((option) => (
              <li key={option.value} className="w-full">
                <button
                  onClick={() => handleLanguageChange(option.value)}
                  className="bg-gray-800 hover:bg-gray-600 text-white py-2 px-4 rounded-lg w-full text-left"
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default NavElements;
