import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';

const OfferForClubs: React.FC = () => {
  const { language } = useLanguage();

  return (
    <div
      className="w-full min-h-screen flex flex-col items-center  bg-cover bg-center overflow-y-scroll"
      style={{ backgroundImage: 'url("assets/back_facebook.jpg")' }}
    >
      <div className="w-full bg-black bg-opacity-80 flex items-center justify-center pt-20">
        <div className="w-full flex flex-col items-center px-4 sm:px-8 md:px-16 max-w-4xl">
          {language === 'english' && (
            <>
              {/* Offer Section */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full mx-auto">
                <div className='w-full flex items-center justify-center'>
                  <img alt="Logo Taki" src={'assets/sun3RemovebgPrev.png'} />
                </div>

                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  Partner with Taki Entertainment!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  Are you a club owner looking to increase your customer base and host thrilling events?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  Join Taki Entertainment and enjoy these benefits:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>📈 Professional Marketing: We promote your club to attract more customers.</li>
                  <li>🎉 Event Hosting: We consider your venue for organizing our high-energy events, bringing more foot traffic.</li>
                  <li>💼 Complete Setup: We provide all necessary equipment and personnel for the events.</li>
                  <li>🎂 Birthday Parties: We pay you to host unforgettable birthday parties.</li>
                  <li>🏢 Company Events: We organize and pay you to host corporate events.</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  Let’s create unforgettable nightlife experiences together!
                </p>
              </div>
            </>
          )}

          {language === 'polish' && (
            <>
              {/* Offer Section for Polish Version */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full">
                <div className='w-full flex items-center justify-center'>
                  <img alt="Logo Taki" src={'assets/sun3RemovebgPrev.png'} />
                </div>

                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  Współpracuj z Taki Entertainment!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  Jesteś właścicielem klubu i chcesz zwiększyć liczbę klientów oraz organizować ekscytujące wydarzenia?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  Dołącz do Taki Entertainment i ciesz się tymi korzyściami:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>📈 Profesjonalny marketing: Promujemy Twój klub, aby przyciągnąć więcej klientów.</li>
                  <li>🎉 Organizacja wydarzeń: Rozważamy Twój klub do organizacji naszych wysokiej energii wydarzeń, co zwiększa ruch.</li>
                  <li>💼 Kompleksowa obsługa: Dostarczamy cały niezbędny sprzęt i personel na wydarzenia.</li>
                  <li>🎂 Przyjęcia urodzinowe: Płacimy za organizację niezapomnianych przyjęć urodzinowych.</li>
                  <li>🏢 Wydarzenia firmowe: Organizujemy i płacimy za organizację wydarzeń korporacyjnych.</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  Twórzmy razem niezapomniane nocne doświadczenia!
                </p>
              </div>
            </>
          )}

          {language === 'spanish' && (
            <>
              {/* Offer Section for Spanish Version */}
              <div className="p-8 bg-black bg-opacity-80 rounded-lg text-center w-full">
                <div className='w-full flex items-center justify-center'>
                  <img alt="Logo Taki" src={'assets/sun3RemovebgPrev.png'} />
                </div>

                <h2 className="text-2xl md:text-4xl text-white mb-4">
                  ¡Asóciate con Taki Entertainment!
                </h2>
                <p className="text-lg md:text-xl text-white mb-8">
                  ¿Eres dueño de un club y quieres aumentar tu base de clientes y organizar eventos emocionantes?
                </p>
                <p className="text-lg md:text-xl text-white mb-8">
                  Únete a Taki Entertainment y disfruta de estos beneficios:
                </p>
                <ul className="text-lg md:text-xl text-white mb-8 list-disc list-inside">
                  <li>📈 Marketing profesional: Promocionamos tu club para atraer más clientes.</li>
                  <li>🎉 Organización de eventos: Consideramos tu club para organizar nuestros eventos de alta energía, aumentando el tráfico de personas.</li>
                  <li>💼 Configuración completa: Proporcionamos todo el equipo necesario y el personal para los eventos.</li>
                  <li>🎂 Fiestas de cumpleaños: Te pagamos para organizar fiestas de cumpleaños memorables.</li>
                  <li>🏢 Eventos corporativos: También organizamos y te pagamos para organizar eventos corporativos.</li>
                </ul>
                <p className="text-lg md:text-xl text-white mb-8">
                  ¡Creemos juntos experiencias nocturnas inolvidables!
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferForClubs;
