import React, { useContext, useEffect, useState, createRef, RefObject } from 'react';
import 'video-react/dist/video-react.css';
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from 'video-react';
import { CarouselContext } from 'pure-react-carousel';

interface VideoProps {
  url: string;
  idx: number;
}

const Video: React.FC<VideoProps> = ({ url, idx }) => {
  const playerRef: RefObject<HTMLDivElement> = createRef();
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState<number | undefined>(carouselContext?.state?.currentSlide);

  useEffect(() => {
    const onChange = () => {
      setCurrentSlide(carouselContext.state.currentSlide);
    };
    carouselContext?.subscribe(onChange);
    return () => {
      carouselContext?.unsubscribe(onChange);
    };
  }, [carouselContext]);

  useEffect(() => {
    if (currentSlide !== idx) {
      playerRef.current?.querySelector('video')?.pause();
    }
  }, [currentSlide, idx]);

  return (
    <div className="relative pb-full" ref={playerRef}>
      <Player aspectRatio="16:9">
        <source src={url} />
        <ControlBar>
          <ReplayControl seconds={10}/>
          <ForwardControl seconds={30} />
          <CurrentTimeDisplay/>
          <TimeDivider />
          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]}/>
          <VolumeMenuButton />
        </ControlBar>
      </Player>
    </div>
  );
};

export default Video;
