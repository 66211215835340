import React from 'react';

interface AgeRestrictionFilterProps {
  ageRestriction: string;
  setAgeRestriction: (restriction: string) => void;
}

const AgeRestrictionFilter: React.FC<AgeRestrictionFilterProps> = ({ ageRestriction, setAgeRestriction }) => {
  return (
    <div className="flex flex-col items-start">
      <h4 className="text-white mb-2">Age Restriction</h4>
      <select
        value={ageRestriction}
        onChange={(e) => setAgeRestriction(e.target.value)}
        className="p-2 rounded-lg bg-white text-black"
      >
        <option value="">All Ages</option>
        <option value="18+">18+</option>
        <option value="21+">21+</option>
      </select>
    </div>
  );
};

export default AgeRestrictionFilter;
