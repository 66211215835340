import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

const EditCity: React.FC = () => {
  const { cityId } = useParams<{ cityId: string }>();
  const [cityData, setCityData] = useState({ name: '', country: '', lat: '', lng: '' });
  const [countries, setCountries] = useState([]);
  const { currentToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const { language } = useLanguage();
  
  const translations = {
    english: {
      back: 'Back',
      editCity: 'Edit City',
      cityName: 'City Name',
      country: 'Country',
      latitude: 'Latitude',
      longitude: 'Longitude',
      saveChanges: 'Save Changes',
      error: 'Error',
      fetchError: 'Failed to fetch city details. Please try again later.',
      countriesError: 'Failed to fetch countries. Please try again later.',
      success: 'Success',
      updateSuccess: 'City updated successfully!',
      updateError: 'Failed to update city. Please try again later.',
      selectCountry: 'Select a country...',
    },
    spanish: {
      back: 'Atrás',
      editCity: 'Editar Ciudad',
      cityName: 'Nombre de la Ciudad',
      country: 'País',
      latitude: 'Latitud',
      longitude: 'Longitud',
      saveChanges: 'Guardar Cambios',
      error: 'Error',
      fetchError: 'No se pudo obtener los detalles de la ciudad. Por favor, inténtelo de nuevo más tarde.',
      countriesError: 'No se pudieron obtener los países. Por favor, inténtelo de nuevo más tarde.',
      success: 'Éxito',
      updateSuccess: '¡Ciudad actualizada con éxito!',
      updateError: 'No se pudo actualizar la ciudad. Por favor, inténtelo de nuevo más tarde.',
      selectCountry: 'Seleccione un país...',
    },
    polish: {
      back: 'Powrót',
      editCity: 'Edytuj Miasto',
      cityName: 'Nazwa Miasta',
      country: 'Kraj',
      latitude: 'Szerokość geograficzna',
      longitude: 'Długość geograficzna',
      saveChanges: 'Zapisz zmiany',
      error: 'Błąd',
      fetchError: 'Nie udało się pobrać szczegółów miasta. Spróbuj ponownie później.',
      countriesError: 'Nie udało się pobrać krajów. Spróbuj ponownie później.',
      success: 'Sukces',
      updateSuccess: 'Miasto zaktualizowane pomyślnie!',
      updateError: 'Nie udało się zaktualizować miasta. Spróbuj ponownie później.',
      selectCountry: 'Wybierz kraj...',
    },
  };

  type Language = 'english' | 'spanish' | 'polish';

  const t = translations[language as Language];


  useEffect(() => {
    fetchCity();
    fetchCountries();
  }, [cityId, currentToken]);

  const fetchCity = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/city/city/${cityId}`, data);
      if (response.ok) {
        const data = await response.json();
        setCityData({ ...data, country: data.country._id });
      } else {
        throw new Error(t.fetchError);
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const fetchCountries = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/country/countries`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.countriesError);
        }
      })
      .then((responseJson) => {
        setCountries(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.countriesError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCityData({ ...cityData, [name]: value });
  };

  const handleCountrySelect = (selectedOption: any) => {
    setCityData({ ...cityData, country: selectedOption ? selectedOption.value : '' });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...cityData, countryId: cityData.country }),
    };

    try {
      const response = await fetch(`${APIBase}/client/city/city/${cityId}`, data);
      if (response.ok) {
        Swal.fire({
          title: t.success,
          text: t.updateSuccess,
          icon: 'success',
          timer: 2000,
        });
        navigate('/app/admin/cities');
      } else {
        throw new Error(t.updateError);
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.updateError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const countryOptions = countries.map((country: any) => ({
    value: country._id,
    label: country.name,
  }));

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.editCity}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="name">
            {t.cityName}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={cityData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="country">
            {t.country}
          </label>
          <Select
            id="country"
            name="country"
            value={countryOptions.find((option) => option.value === cityData.country)}
            onChange={handleCountrySelect}
            options={countryOptions}
            className="w-full text-black"
            placeholder={t.selectCountry}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="lat">
            {t.latitude}
          </label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={cityData.lat}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="lng">
            {t.longitude}
          </label>
          <input
            type="text"
            id="lng"
            name="lng"
            value={cityData.lng}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.saveChanges}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCity;
