import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const AddToHomeScreenPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

    if (isIos && !isInStandaloneMode) {
      const promptShown = localStorage.getItem('addToHomeScreenPromptShown');

      if (!promptShown) {
        const timer = setTimeout(() => {
          setShowPrompt(true);
          localStorage.setItem('addToHomeScreenPromptShown', 'true');
        }, 30000); // 30 seconds

        return () => clearTimeout(timer);
      }
    }
  }, []);

  if (!showPrompt) return null;

  return (
    <div className="fixed bottom-4 left-4 right-4 md:left-auto md:right-8 bg-white p-4 rounded-lg shadow-lg flex items-start z-50">
      <div className="flex-1">
        <h3 className="text-lg font-bold mb-2">Add to Home Screen</h3>
        <p className="text-sm">Install this app on your home screen for quick access and a better experience.</p>
        <p className="text-sm mt-2">Tap <strong>Share</strong> and then <strong>Add to Home Screen</strong>.</p>
      </div>
      <button
        className="ml-4 mt-1 text-gray-600 hover:text-gray-900 focus:outline-none"
        onClick={() => setShowPrompt(false)}
      >
        <FaTimes size={16} />
      </button>
    </div>
  );
};

export default AddToHomeScreenPrompt;
