import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';

interface LocationContextProps {
  selectedCity: string | null;
  isUsingGPS: boolean;
  userLocation: [number, number] | null;
  setSelectedCity: (city: string) => void;
  setIsUsingGPS: (usingGPS: boolean) => void;
  setUserLocation: (location: [number, number] | null) => void; // Add this
  fetchUserCity: () => void;
}

const LocationContext = createContext<LocationContextProps>({
  selectedCity: null,
  isUsingGPS: false,
  userLocation: null,
  setSelectedCity: () => {},
  setIsUsingGPS: () => {},
  setUserLocation: () => {}, // Add this
  fetchUserCity: () => {},
});

export const useLocationContext = () => useContext(LocationContext);

export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [isUsingGPS, setIsUsingGPS] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);

  useEffect(() => {
    fetchUserCity();

    // Update user location every 15 seconds if using GPS
    let locationUpdateInterval: NodeJS.Timeout | null = null;
    if (isUsingGPS) {
      locationUpdateInterval = setInterval(() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation([position.coords.latitude, position.coords.longitude]);
          },
          (error) => {
            console.error('Error getting user location:', error);
          }
        );
      }, 15000);
    }

    return () => {
      if (locationUpdateInterval) {
        clearInterval(locationUpdateInterval);
      }
    };
  }, [isUsingGPS]);

  const fetchUserCity = () => {
    const storedCity = localStorage.getItem('selectedCity');
    if (storedCity) {
      setSelectedCity(storedCity);
      setIsUsingGPS(false);
    } else {
      setIsUsingGPS(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setSelectedCity('Using GPS Location');
          setUserLocation([position.coords.latitude, position.coords.longitude]);
        },
        () => {
          setSelectedCity(null);
          setIsUsingGPS(false);
        }
      );
    }
  };

  return (
    <LocationContext.Provider
      value={{
        selectedCity,
        isUsingGPS,
        userLocation,
        setSelectedCity: (city) => {
          localStorage.setItem('selectedCity', city);
          setSelectedCity(city);
          setIsUsingGPS(false);
        },
        setIsUsingGPS,
        setUserLocation, // Provide setUserLocation
        fetchUserCity,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
