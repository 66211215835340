import React from 'react';
import * as styledComponents from './styles';
import { DJ } from '../';
import { CMSBase } from 'api/hosts';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
interface DJCardProps {
  dj: DJ;
}

const DJCard: React.FC<DJCardProps> = ({ dj }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('https://wa.me/+48537435130');
  };

  return (
    <styledComponents.CardContainer>
      <styledComponents.CardBackground src={CMSBase + dj.attributes.background.data.attributes.url} />
      <styledComponents.CardContentContainer>
        <styledComponents.DJContent>
          <styledComponents.DJName>{dj.attributes.name}</styledComponents.DJName>
          <styledComponents.DJDescription>{dj.attributes.description}</styledComponents.DJDescription>
        </styledComponents.DJContent>
        <styledComponents.DJImage src={CMSBase + dj.attributes.image.data.attributes.url} />
      </styledComponents.CardContentContainer>
      <styledComponents.BookButtonContainer>
        <Button onClick={handleButtonClick}>BOOK</Button>
      </styledComponents.BookButtonContainer>
    </styledComponents.CardContainer>
  );
};
export default DJCard;
