import React, { useEffect, useState } from 'react';
import { FaClock, FaPlus, FaTrash } from 'react-icons/fa';
import { DaySchedule } from 'types';
import { useLanguage } from 'Contexts/LanguageContext';

interface ScheduleManagerProps {
  schedule: DaySchedule[];
  onScheduleChange: (schedule: DaySchedule[]) => void;
}

const ScheduleManager: React.FC<ScheduleManagerProps> = ({ schedule, onScheduleChange }) => {
  const [localSchedule, setLocalSchedule] = useState<DaySchedule[]>([]);
  const { language } = useLanguage();

  const translations = {
    english: {
      manageSchedule: 'Manage Schedule',
      addTimeSlot: 'Add Time Slot',
      saveSchedule: 'Save Schedule',
    },
    spanish: {
      manageSchedule: 'Gestionar Horario',
      addTimeSlot: 'Añadir Franja Horaria',
      saveSchedule: 'Guardar Horario',
    },
    polish: {
      manageSchedule: 'Zarządzaj Harmonogramem',
      addTimeSlot: 'Dodaj Przedział Czasowy',
      saveSchedule: 'Zapisz Harmonogram',
    },
  };

  type Language = 'english' | 'spanish' | 'polish';

  const t = translations[language as Language];

  useEffect(() => {
    // If schedule is empty, initialize it with empty days
    if (schedule.length === 0) {
      const initialSchedule: DaySchedule[] = [
        { day: 'Monday', times: [] },
        { day: 'Tuesday', times: [] },
        { day: 'Wednesday', times: [] },
        { day: 'Thursday', times: [] },
        { day: 'Friday', times: [] },
        { day: 'Saturday', times: [] },
        { day: 'Sunday', times: [] },
      ];
      setLocalSchedule(initialSchedule);
    } else {
      const deepCopySchedule = schedule.map((daySchedule) => ({
        ...daySchedule,
        times: daySchedule.times.map((timeSlot) => ({ ...timeSlot })),
      }));
      setLocalSchedule(deepCopySchedule);
    }
  }, [schedule]);

  const handleTimeChange = (dayIndex: number, timeIndex: number, field: 'open' | 'close', value: string) => {
    const updatedSchedule = localSchedule.map((daySchedule, dIndex) =>
      dIndex === dayIndex
        ? {
            ...daySchedule,
            times: daySchedule.times.map((timeSlot, tIndex) =>
              tIndex === timeIndex ? { ...timeSlot, [field]: value } : timeSlot,
            ),
          }
        : daySchedule,
    );
    setLocalSchedule(updatedSchedule);
  };

  const handleAddTimeSlot = (dayIndex: number) => {
    const updatedSchedule = localSchedule.map((daySchedule, dIndex) =>
      dIndex === dayIndex ? { ...daySchedule, times: [...daySchedule.times, { open: '', close: '' }] } : daySchedule,
    );
    setLocalSchedule(updatedSchedule);
  };

  const handleRemoveTimeSlot = (dayIndex: number, timeIndex: number) => {
    const updatedSchedule = localSchedule.map((daySchedule, dIndex) =>
      dIndex === dayIndex
        ? { ...daySchedule, times: daySchedule.times.filter((_, tIndex) => tIndex !== timeIndex) }
        : daySchedule,
    );
    setLocalSchedule(updatedSchedule);
  };

  const handleSave = () => {
    onScheduleChange(localSchedule);
  };

  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-lg text-white">
      <h3 className="text-xl mb-4">{t.manageSchedule}</h3>
      {localSchedule.map((daySchedule, dayIndex) => (
        <div key={daySchedule.day} className="mb-4">
          <div className="font-semibold text-lg mb-2">{daySchedule.day}</div>
          {daySchedule.times.map((timeSlot, timeIndex) => (
            <div key={timeIndex} className="flex items-center mb-2">
              <FaClock className="mr-2 text-gray-400" />
              <input
                type="time"
                value={timeSlot.open || ''}
                onChange={(e) => handleTimeChange(dayIndex, timeIndex, 'open', e.target.value)}
                className="bg-gray-900 text-white p-2 rounded-md mr-2 w-32"
              />
              <span className="mx-2">-</span>
              <input
                type="time"
                value={timeSlot.close || ''}
                onChange={(e) => handleTimeChange(dayIndex, timeIndex, 'close', e.target.value)}
                className="bg-gray-900 text-white p-2 rounded-md mr-2 w-32"
              />
              <button
                onClick={() => handleRemoveTimeSlot(dayIndex, timeIndex)}
                className="p-2 bg-red-600 hover:bg-red-700 text-white rounded-md"
              >
                <FaTrash />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => handleAddTimeSlot(dayIndex)}
            className="p-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md mt-2 flex items-center"
          >
            <FaPlus className="mr-2" /> {t.addTimeSlot}
          </button>
        </div>
      ))}
      <div className="flex justify-end mt-4">
        <button
          type="button"
          onClick={handleSave}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-500"
        >
          {t.saveSchedule}
        </button>
      </div>
    </div>
  );
};

export default ScheduleManager;
