import Chip from '@material-ui/core/Chip';
import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  FaBuilding,
  FaChevronLeft,
  FaFacebook,
  FaGoogle,
  FaHeadphones,
  FaMapMarkerAlt,
  FaMusic,
  FaShareAlt,
  FaUserLock,
  FaTicketAlt,
} from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { Event, Venue } from 'types';
import MapComponent from '../PartyTicket/MapComponent';
import { Link } from 'react-router-dom';
// Function to fetch event by ID
const fetchEventById = async (id: string, token: string): Promise<Event> => {
  const response = await fetch(`${APIBase}/client/event/byEventId/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch event');
  }
  return response.json();
};

// Function to fetch venue by ID
const fetchVenueById = async (id: string, token: string): Promise<Venue> => {
  const response = await fetch(`${APIBase}/client/venue/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch venue');
  }
  return response.json();
};

const EventDetail: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const { currentToken } = useContext(AuthContext);
  const [event, setEvent] = useState<Event | null>(null);
  const [venue, setVenue] = useState<Venue | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (eventId && currentToken) {
      const fetchData = async () => {
        try {
          const eventData = await fetchEventById(eventId, currentToken);
          const venueData = await fetchVenueById(eventData.clubId?._id, currentToken);
          setEvent(eventData);
          setVenue(venueData);
          setLoading(false);
        } catch (error) {
          setError((error as Error).message);
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [eventId, currentToken]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900">
        <StageSpinner size={50} color="#86ff0dc4" />
      </div>
    );
  }

  if (error) {
    return <div className="text-white text-center mt-10">{error}</div>;
  }

  if (!event || !venue) {
    return <div className="text-white text-center mt-10">Event or Venue not found</div>;
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: event.title,
        text: event.description,
        url: window.location.href,
      });
    } else {
      const shareData = {
        title: event.title,
        text: event.description,
        url: window.location.href,
      };
      navigator.clipboard.writeText(`${shareData.title}\n${shareData.text}\n${shareData.url}`);
      Swal.fire('Link copied to clipboard', 'Share it with your friends!', 'success');
    }
  };

  const chipStyles = {
    fontSize: '0.75rem',
    padding: '2px 6px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    margin: '2px',
  };

  return (
    <div className="w-full px-3 py-3 bg-gradient-to-b from-gray-900 via-black to-gray-900 bg-opacity-90 overflow-scroll rounded-lg shadow-lg pb-24">
      <button onClick={handleBackClick} className="text-white mb-4 mt-20 flex items-center">
        <FaChevronLeft className="mr-2" /> Back
      </button>
      <div className="flex justify-start items-center mb-4">
        <h2 className="text-3xl font-bold text-white">{event.title}</h2>
      </div>
      <div className="bg-black bg-opacity-90 p-4 rounded-lg shadow-md">
        <img src={event.imageURL} alt={event.title} className="w-full h-64 object-cover rounded-lg mb-4" />
        {(event.verified || event.network) && (
          <div className="flex space-x-2 my-2">
            {event.verified && (
              <span className="bg-black text-xs px-2 py-1 rounded-full border border-neon-green text-gray-400">
                {'Verified'}
              </span>
            )}
            {event.network && (
              <span className="bg-gradient-to-r from-yellow-500 to-yellow-700 text-xs px-2 py-1 rounded-full border border-yellow-600 shadow-md text-black font-semibold">
                {'Taki Network'}
              </span>
            )}
          </div>
        )}
        <p className="text-gray-400 mb-4">{event.description}</p>
        <p className="text-gray-400 mb-2">
          <strong>Date:</strong> {moment(event.date).format('MMMM Do, YYYY')}
        </p>
        <p className="text-gray-400 mb-2">
          <strong>Time:</strong> {event.time}
        </p>
        <p className="text-gray-400 mb-2">
          <strong>Location:</strong> {venue.name}
        </p>
        <p className="text-gray-400 mb-2">
          <strong>Address:</strong> {venue.address}
        </p>
        {/* Venue Detail Link */}
        <Link
          to={`/app/partyticket/bars/view-bar/${venue._id}`}
          className="text-white underline"
        >
          See Venue Details
        </Link>
        {event.genres.length > 0 && (
          <div className="flex flex-wrap mt-2">
            <p className="mr-2 h-10 items-center justify-center flex text-gray-400">
              <FaMusic className="mr-2 text-neon-blue" />
              Genres:
            </p>
            {event.genres.map((genre, index) => (
              <Chip key={index} label={genre} style={chipStyles} />
            ))}
          </div>
        )}
        {venue.venueTypes && venue.venueTypes.length > 0 && (
          <div className="flex flex-wrap mt-2">
            <p className="mr-2 h-10 items-center justify-center flex text-gray-400">
              <FaBuilding className="mr-2 text-neon-blue" />
              Venue Type:
            </p>
            {venue.venueTypes.map((venueType, index) => (
              <Chip key={index} label={venueType} style={chipStyles} />
            ))}
          </div>
        )}
        {event.dressCode && (
          <p className="flex items-center text-gray-400">
            <FaUserLock className="mr-2 text-neon-blue" />
            Dress Code: {event.dressCode}
          </p>
        )}
        {event.ageRestriction && (
          <p className="flex items-center text-gray-400">
            <FaUserLock className="mr-2 text-neon-blue" />
            Age Restriction: {event.ageRestriction}
          </p>
        )}
        {event.djs.length > 0 && (
          <p className="flex items-center text-gray-400">
            <FaHeadphones className="mr-2 text-neon-blue" />
            DJs: {event.djs.join(', ')}
          </p>
        )}
        {event.ticketLink && (
          <div className="mt-4">
            <a
              href={event.ticketLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white bg-green-500 hover:bg-green-600 px-4 py-2 rounded-lg flex items-center justify-center w-full"
            >
              <FaTicketAlt className="mr-2" />
              Buy Tickets
            </a>
          </div>
        )}
        <div className="flex flex-row space-x-4 my-4">
          <a
            href={`https://www.google.com/maps/dir/?api=1&destination=${venue.lat},${venue.lng}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 px-4 py-4 rounded-lg flex items-center justify-center"
          >
            <FaGoogle className="text-2xl" />
          </a>
          <a
            href={`http://maps.apple.com/?daddr=${venue.lat},${venue.lng}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-gradient-to-r from-gray-600 to-gray-800 hover:from-gray-700 hover:to-gray-900 px-4 py-4 rounded-lg flex items-center justify-center"
          >
            <FaMapMarkerAlt className="text-2xl" />
          </a>
          <a
            href={event.facebookLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 px-4 py-4 rounded-lg flex items-center justify-center"
          >
            <FaFacebook className="text-2xl" />
          </a>
          <div
            onClick={handleShare}
            className="text-white bg-gradient-to-r from-blue-700 to-blue-900 hover:from-blue-800 hover:to-blue-1000 px-4 py-4 rounded-lg flex items-center justify-center cursor-pointer"
          >
            <FaShareAlt className="text-2xl" />
          </div>
        </div>
        <MapComponent venues={[venue]} onMarkerClick={() => {}} selectedClub={event.clubId?._id} relativeView />
      </div>
    </div>
  );
};

export default EventDetail;
