import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}

const translations = {
  english: {
    searchPlaceholder: 'Search for events...',
  },
  spanish: {
    searchPlaceholder: 'Buscar eventos...',
  },
  polish: {
    searchPlaceholder: 'Szukaj wydarzeń...',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const SearchBar: React.FC<SearchBarProps> = ({ searchQuery, setSearchQuery }) => {
  const { language } = useLanguage();
  const placeholderText =
    translations[language as Language]?.searchPlaceholder || translations.english.searchPlaceholder;

  return (
    <div className="flex items-center w-full md:mt-0 md:mr-0 relative bg-white rounded-lg shadow-md overflow-hidden">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={placeholderText}
        className="w-full p-2 rounded-l-lg bg-transparent text-black focus:outline-none pl-8"
      />
    </div>
  );
};

export default SearchBar;
