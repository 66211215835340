import React, { useContext } from 'react';
import { AuthContext } from 'Contexts/Auth';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { APIBase } from 'api/hosts';
// krolestwoEvents.ts

const scena54Events = [
  {
    title: 'S.54 pres | Dizze Noize · All night long | 26.07 FRI',
    description: `
        S.54 pres
        ----------------
        Zapraszamy w piątek 26-ego lipca ( 26.07 FRI ) do Sceny54 jako dobry wstęp do weekendu. W piątkowym formacie 'all night long' ze znakomitą selekcją house music @ Dizze Noize.
        Wyjątkowa atmosfera S.54 oraz niebanalne klubowe brzmienia zapewnią niczym nieskrępowaną rozrywkę wieczoru.
        Zapraszamy do wcześniejszego skorzystania z rezerwacji.
        You are invited to Scena54 on Friday, July 26th ( 26.07 FRI ) as a great start to the weekend. Enjoy the Friday format 'all night long' with an excellent selection of house music by @Lun4tic.
        The unique atmosphere of S.54 and the extraordinary club sounds will ensure an evening of unrestrained entertainment.
        We encourage you to make reservations in advance.
        ----------------
        Line-up
        -
        Dizze Noize ( All night long )
        ----------------
        ⓢ 54 RSVN : + 48 730 500 836 or DM
        Mickiewicza 3 – 40 092 Katowice
        Door tax – Door selection
        Scena54 By Feiim
      `,
    date: '2024-07-26',
    time: '23:00',
    startHour: '23:00',
    endHour: '05:00',
    imageURL: 'https://i.imgur.com/gD3axRT.jpeg',
    facebookLink: 'https://www.facebook.com/events/486257670753533/',
    genres: ['House'],
    dressCode: '',
    ageRestriction: '18+',
    djs: ['Dizze Noize'],
    verified: false,
    network: false,
    clubId: '66b24ad675db99843ecfca2f', // Scena54 ID
    type: ['party'], 
  },
  {
    title: 'S.54 pres | Lun4tic | Mirjami | 27.07 SAT',
    description: `
        S.54 pres
        ----------------
        Zapraszamy 27-ego ( 27.07 SAT ) lipca do Sceny54. W nachodzącą sobotę na scenie @Lun4atic oraz zwycięzca pierwszej edycji Opendeck @Mirjami.
        Wyjątkowa atmosfera S.54 oraz niebanalne klubowe brzmienia zapewnią niczym nieskrępowaną rozrywkę wieczoru.
        Zapraszamy do składania wcześniejszych rezerwacji.
        You are invited on July 27th (Saturday, 27.07) to Scena54. This upcoming Saturday, on stage, we will have @Lun4atic and the winner of the first edition of Opendeck, @Mirjami.
        The unique atmosphere of S.54 and the extraordinary club sounds will ensure an evening of unrestricted entertainment.
        We encourage you to make reservations in advance.
        ----------------
        Line-up
        Lun4atic ( Amb_S.54 )
        Mirjami ( Guest Artist )
        ----------------
        ⓢ 54 RSVN : + 48 730 500 836 or DM
        Mickiewicza 3 – 40 092 Katowice
        Door tax – Door selection
        Scena54 By Feiim
      `,
    date: '2024-07-27',
    time: '23:00',
    startHour: '23:00',
    endHour: '05:00',
    imageURL: 'https://i.imgur.com/P9Mn2Q7.jpg',
    facebookLink: 'https://www.facebook.com/events/1201322601060919',
    genres: ['House'],
    dressCode: '',
    ageRestriction: '18+',
    djs: ['Lun4atic', 'Mirjami'],
    verified: false,
    network: false,
    clubId: '66b24ad675db99843ecfca2f', // Scena54 ID
    type: ['party'],
  },
  {
    title: 'S.54 pres | Cream · All night long',
    description: `
        S.54 pres
        ----------------
        Zapraszamy do Sceny54 3-ego sierpnia ( 03.08 SAT ). W wyjątkowym formacie 'all night long' nasz gość @ Cream.
        Wyjątkowa atmosfera S.54 oraz niebanalne klubowe brzmienia zapewnią niczym nieskrępowaną rozrywkę wieczoru.
        Zapraszamy do wcześniejszego skorzystania z rezerwacji.
        We invite you to Scena54 on August 3rd ( 03.08 SAT ). Join us for an exceptional 'all night long' format with our guest @ Cream.
        The unique atmosphere of S.54 and distinctive club sounds will ensure an evening of unrestricted entertainment.
        We encourage you to make reservations in advance.
        ----------------
        Line-up
        -
        Cream ( Guest Artist / Kraków )
        ----------------
        ⓢ 54 RSVN : + 48 730 500 836 or DM
        Mickiewicza 3 – 40 092 Katowice
        Door tax – Door selection
        Scena54 By Feiim
      `,
    date: '2024-08-03',
    time: '23:00',
    startHour: '23:00',
    endHour: '05:00',
    imageURL: 'https://i.imgur.com/e6S6QZN.jpeg',
    facebookLink: 'https://www.facebook.com/events/339574659223419',
    genres: ['House'],
    dressCode: '',
    ageRestriction: '18+',
    djs: ['Cream'],
    verified: false,
    network: false,
    clubId: '66b24ad675db99843ecfca2f', // Scena54 ID
    type: ['party'],
  },
  {
    title: 'S.54 pres | CASSIMM · Toolroom, Defected | Po1ny | Lun4tic | 21.09 SAT',
    description: `
        S.54 pres
        ----------------
        Zapraszamy w sobotę 21 września ( 21.09 SAT ) do S54. Tego wieczoru artysta dużego formatu CASSIMM ( Uk · Toolroom, Defected ).
        We invite you on Saturday, September 21st (21.09 SAT) to S54. On this evening, the big-name artist CASSIMM (UK · Toolroom, Defected) will be performing.
        Oprócz headlinera, na scenie wystąpią także PO1NY oraz LUN4TIC. Joining the headliner on stage will be PO1NY and LUN4TIC.
        CASSIMM ( Uk · Toolroom, Defected ) a dj/producer who was born in the south of Italy in the 80’s.He is already well known under the name Alessio Caforio for his production and collaboration on labels such as « Stereo Production of Chus and Ceballos, »Hotfingers » and « 303 Lovers.
        « Although Alessio has always been filled with passion for the music and has been a dj and musican since the age of 15, his career really started to sprout in Turin in 2007 where he moved to study as a sound engineer.
        Alessio was the resident dj in the hottiest clubs in the city of Turin, he also worked in many clubs in the north of Italy including « Magazzini Generali » in Milan.
        The CASSIMM project was born in 2012 and he was pleasantly surprised by the success of his first release, » Silent to Me » as it flew straight to the top tenof Beatport indie Nudisco chart and licenced by Sony Columbia.
        He actually works with many labels including « Sleazy Deep », « Spirit Soul records », « King Street » and « Beatdown » of Sonny Fodera where he proposes a sound going from deephouse to techouse.
        CASSIMM moved to London in 2014 and straight away became a resident dj in one of London’s most famous clubs « The Aquarium » where you can find himevery weekend driving the crowd wild .You can also find guest dj’s such as Smokin Jo ,Alex Niggerman, Tiger Stripes, Betoko etc.
        CASSIMM’s dj set is very versitale,he has the knack to be able to sense the mood of the crowd, and feeds them what they want.This is the product of many years of being a resident dj and musical lover.
        PO1NY, a Dj, promoter, and author of numerous projects and initiatives. For years, he has consistently pursued a direction towards more electronic house and tech-house formats, intentionally complementing them with contemporary forms of dark disco and indie dance.
        LUN4TIC is a representative of the Silesian scene. Originating strictly from dance house music, he currently oscillates around tech-house and broadly defined electronic music.
        Wyjątkowa atmosfera S.54 oraz niebanalne klubowe brzmienia zapewnią niczym nieskrępowaną rozrywkę wieczoru.
        Zapraszamy do składania wcześniejszych rezerwacji.
        The unique atmosphere of S.54 and the unconventional club sounds will ensure an evening of uninhibited entertainment.
        We invite you to make early reservations.
        ----------------
        Line-up
        Cassimm ( Uk · Toolroom, Defected )
        Po1ny ( Amb_S.54 )
        Lun4tic ( Amb_S.54 )
        ----------------
        ⓢ 54 RSVN : + 48 730 500 836 or DM
        Mickiewicza 3 – 40 092 Katowice
        Door tax – Door selection
        Scena54 By Feiim
      `,
    date: '2024-09-21',
    time: '23:00',
    startHour: '23:00',
    endHour: '05:00',
    imageURL: 'https://i.imgur.com/bSSqyZa.jpg',
    facebookLink: 'https://www.facebook.com/events/1138140717482216',
    genres: ['Deep House', 'Tech House'],
    dressCode: '',
    ageRestriction: '18+',
    djs: ['Cassimm', 'Po1ny', 'Lun4tic'],
    verified: false,
    network: false,
    clubId: '66b24ad675db99843ecfca2f', // Scena54 ID
    type: ['party'],
  },
];

const CreateScena54Events: React.FC = () => {
    const { currentToken } = useContext(AuthContext);

  const navigate = useNavigate();

  const createEvents = async () => {
    try {
      for (const event of scena54Events) {
        const response = await fetch(`${APIBase}/client/event`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentToken}`,
          },
          body: JSON.stringify(event),
        });

        if (!response.ok) {
          throw new Error('Failed to create event');
        }
      }

      Swal.fire({
        title: 'Success',
        text: 'All events created successfully!',
        icon: 'success',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to create some events. Please try again later.',
        icon: 'error',
      });
    }
  };

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 mt-20">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        Back
      </button>
      <button
        onClick={createEvents}
        className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
      >
        Create Scena54 Events
      </button>
    </div>
  );
};

export default CreateScena54Events;
