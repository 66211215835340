import ReactGA from 'react-ga4';

const trackingId = 'G-F7E2WDL73Q'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId, { debug: true }); // Enable debug mode

export const logPageView = () => {
//   console.log('Logging page view for', window.location.pathname);
  //   ReactGA.set({ page: window.location.pathname });
  //   ReactGA.pageview(window.location.pathname);
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: window.location.pathname });
//   console.log('Page view logged:', window.location.pathname);
};

// export const logEvent = (category = '', action = '') => {
//   if (category && action) {
//     console.log(`Logging event: Category - ${category}, Action - ${action}`);
//     ReactGA.event({ category, action });
//     console.log(`Event logged: Category - ${category}, Action - ${action}`);
//   } else {
//     console.log('Event not logged: Missing category or action');
//   }
// };

// export const logException = (description = '', fatal = false) => {
//   if (description) {
//     console.log(`Logging exception: Description - ${description}, Fatal - ${fatal}`);
//     ReactGA.exception({ description, fatal });
//     console.log(`Exception logged: Description - ${description}, Fatal - ${fatal}`);
//   } else {
//     console.log('Exception not logged: Missing description');
//   }
// };
