import { APIBase } from 'api/hosts';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'Contexts/Auth';
import Swal from 'sweetalert2';
import { FaChevronLeft } from 'react-icons/fa';
import Select from 'react-select';

const translations = {
  english: {
    back: 'Back',
    createCity: 'Create City',
    cityName: 'City Name',
    country: 'Country',
    selectCountry: 'Select a country...',
    latitude: 'Latitude',
    longitude: 'Longitude',
    success: 'Success',
    cityCreated: 'City created successfully! 🎉',
    error: 'Error',
    fetchError: 'Failed to fetch countries. Please try again later.',
    submitError: 'Please try again later 😞',
  },
  spanish: {
    back: 'Regresar',
    createCity: 'Crear Ciudad',
    cityName: 'Nombre de la Ciudad',
    country: 'País',
    selectCountry: 'Selecciona un país...',
    latitude: 'Latitud',
    longitude: 'Longitud',
    success: 'Éxito',
    cityCreated: '¡Ciudad creada con éxito! 🎉',
    error: 'Error',
    fetchError: 'No se pudo obtener los países. Inténtalo de nuevo más tarde.',
    submitError: 'Inténtalo de nuevo más tarde 😞',
  },
  polish: {
    back: 'Powrót',
    createCity: 'Utwórz Miasto',
    cityName: 'Nazwa Miasta',
    country: 'Kraj',
    selectCountry: 'Wybierz kraj...',
    latitude: 'Szerokość geograficzna',
    longitude: 'Długość geograficzna',
    success: 'Sukces',
    cityCreated: 'Miasto utworzone pomyślnie! 🎉',
    error: 'Błąd',
    fetchError: 'Nie udało się pobrać krajów. Spróbuj ponownie później.',
    submitError: 'Spróbuj ponownie później 😞',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const CreateCity: React.FC = () => {
  const [cityData, setCityData] = useState({
    name: '',
    country: '',
    lat: '',
    lng: '',
  });
  const [countries, setCountries] = useState([]);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const t = translations[language as Language];
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountries();
  }, [currentToken]);

  const fetchCountries = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/country/countries`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.fetchError);
        }
      })
      .then((responseJson) => {
        setCountries(responseJson);
      })
      .catch((error) => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCityData({ ...cityData, [name]: value });
  };

  const handleCountrySelect = (selectedOption: any) => {
    setCityData({ ...cityData, country: selectedOption ? selectedOption.value : '' });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...cityData, countryId: cityData.country }),
    };

    await fetch(`${APIBase}/client/city/city`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.success,
            text: t.cityCreated,
            icon: 'success',
            timer: 2000,
          });
          navigate('/app/admin/cities');
        } else {
          Swal.fire({
            title: t.error,
            text: t.submitError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.submitError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const countryOptions = countries.map((country: any) => ({
    value: country._id,
    label: country.name,
  }));

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.createCity}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="name">
            {t.cityName}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={cityData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="country">
            {t.country}
          </label>
          <Select
            id="country"
            name="country"
            value={countryOptions.find((option) => option.value === cityData.country)}
            onChange={handleCountrySelect}
            options={countryOptions}
            className="w-full text-black"
            placeholder={t.selectCountry}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="lat">
            {t.latitude}
          </label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={cityData.lat}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="lng">
            {t.longitude}
          </label>
          <input
            type="text"
            id="lng"
            name="lng"
            value={cityData.lng}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.createCity}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCity;
