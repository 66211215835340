import {
  faBuilding,
  faGlassCheers,
  faHome,
  faMapMarkedAlt,
  faMicrophone,
  faMusic,
  faQrcode, // Import faQrcode for the scan option
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LowerBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();

  const translations = {
    english: {
      home: 'Home',
      party: 'Party',
      karaoke: 'Karaoke',
      map: 'Map',
      bars: 'Bars',
      clubs: 'Clubs',
      scan: 'Scan QR', // Updated label for scan option
    },
    spanish: {
      home: 'Inicio',
      party: 'Fiestas',
      karaoke: 'Karaoke',
      map: 'Mapa',
      bars: 'Bares',
      clubs: 'Clubes',
      scan: 'Escanear QR', // Updated label for scan option
    },
    polish: {
      home: 'Strona główna',
      party: 'Impreza',
      karaoke: 'Karaoke',
      map: 'Mapa',
      bars: 'Bary',
      clubs: 'Kluby',
      scan: 'Skanuj QR', // Updated label for scan option
    },
  };

  const t = translations[language as keyof typeof translations];

  const getButtonClass = (paths: string[]) => {
    return paths.some((path) => location.pathname.startsWith(path))
      ? 'text-neon-blue border-b-2 border-neon-blue'
      : 'text-white';
  };

  return (
    <div
      className="fixed bottom-0 left-0 w-full bg-gradient-to-r from-black via-gray-900 to-black text-white flex justify-around p-2 h-20 pt-3 shadow-lg z-50"
      style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 8px)' }}
    >
      <button
        onClick={() => navigate('/app/partyticket/events')}
        className={`flex flex-col items-center ${getButtonClass([
          '/app/partyticket/events',
        ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
      >
        <FontAwesomeIcon icon={faMusic} className="text-xl" />
        <span className="text-xs mt-1">{t.party}</span>
      </button>
      <button
        onClick={() => navigate('/app/partyticket/karaoke')}
        className={`flex flex-col items-center ${getButtonClass([
          '/app/partyticket/karaoke',
        ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
      >
        <FontAwesomeIcon icon={faMicrophone} className="text-xl" />
        <span className="text-xs mt-1">{t.karaoke}</span>
      </button>

      {/* <button
        onClick={() => navigate('/app/partyticket/bars')}
        className={`flex flex-col items-center ${getButtonClass([
          '/app/partyticket/bars',
          '/app/partyticket/bars/map',
        ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
      >
        <FontAwesomeIcon icon={faGlassCheers} className="text-xl" />
        <span className="text-xs mt-1">{t.bars}</span>
      </button> */}
      <button
        onClick={() => navigate('/app/home')}
        className={`flex flex-col items-center mb-3 w-12 ${getButtonClass([
          '/app/home',
        ])} transition-all duration-300 ease-in-out`}
      >
        <div className="bg-gradient-to-r  p-3 rounded-full shadow-lg border border-neon-blue absolute -top-1">
          <FontAwesomeIcon icon={faHome} className="text-2xl text-neon-blue" />
        </div>
      </button>
      {/* <button
        onClick={() => navigate('/app/partyticket/clubs')}
        className={`flex flex-col items-center ${getButtonClass([
          '/app/partyticket/clubs',
          '/app/partyticket/clubs/map',
        ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
      >
        <FontAwesomeIcon icon={faBuilding} className="text-xl" />
        <span className="text-xs mt-1">{t.clubs}</span>
      </button> */}
      <button
        onClick={() => navigate('/app/partyticket/map')}
        className={`flex flex-col items-center ${getButtonClass([
          '/app/partyticket/map',
        ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
      >
        <FontAwesomeIcon icon={faMapMarkedAlt} className="text-xl" />
        <span className="text-xs mt-1">{t.map}</span>
      </button>
      <button
        onClick={() => navigate('/app/scan-qr')} // Updated to navigate to the QR scan page
        className={`flex flex-col items-center ${getButtonClass([
          '/app/scan-qr',
        ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
      >
        <FontAwesomeIcon icon={faQrcode} className="text-xl" />
        <span className="text-xs mt-1">{t.scan}</span> {/* Updated text */}
      </button>
    </div>
  );
};

export default LowerBar;
