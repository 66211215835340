import React from 'react';

interface DressCodeFilterProps {
  dressCode: string;
  setDressCode: (code: string) => void;
}

const dressCodes = ['Casual', 'Formal', 'Themed'];

const DressCodeFilter: React.FC<DressCodeFilterProps> = ({ dressCode, setDressCode }) => {
  return (
    <div className="flex flex-col items-start">
      <h4 className="text-white mb-2">Dress Code</h4>
      <select
        value={dressCode}
        onChange={(e) => setDressCode(e.target.value)}
        className="p-2 rounded-lg bg-white text-black"
      >
        <option value="">No Dress Code</option>
        {dressCodes.map((code) => (
          <option key={code} value={code}>
            {code}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DressCodeFilter;
