import React from 'react';

interface ImageProps {
  url: string;
}

const Image: React.FC<ImageProps> = ({ url }) => {
  return (
    <div
      className="w-full pb-full bg-cover bg-center"
      style={{ backgroundImage: `url(${url})`, paddingTop: '100%' }}
    />
  );
};

export default Image;
