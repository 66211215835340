import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft, FaEllipsisV, FaFacebook, FaGlobe, FaInstagram, FaMapMarkerAlt, FaTiktok } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

const translations = {
  english: {
    details: 'Venue Details',
    name: 'Name',
    address: 'Address',
    description: 'Description',
    latitude: 'Latitude',
    longitude: 'Longitude',
    venueTypes: 'Venue Types',
    schedule: 'Schedule',
    activities: 'Activities',
    viewOnMap: 'View on Map',
    back: 'Back to Venues',
    edit: 'Edit',
    delete: 'Delete',
    error: 'Error',
    deleteConfirmTitle: 'Are you sure?',
    deleteConfirmText: "You won't be able to revert this!",
    deleteSuccess: 'Venue has been deleted.',
    deleteFailure: 'Failed to delete venue. Please try again later.',
    fetchError: 'Failed to fetch venue. Please try again later.',
    loading: 'Loading...',
  },
  // Add more languages as needed
};

const ViewVenue: React.FC = () => {
  const { venueId } = useParams<{ venueId: string }>();
  const [venueData, setVenueData] = useState<any>(null);
  const [activities, setActivities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchVenue();
    fetchActivities();
  }, [venueId]);

  const fetchVenue = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await fetch(`${APIBase}/client/venue/${venueId}`, data);
      if (response.ok) {
        const responseJson = await response.json();
        setVenueData(responseJson);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchActivities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await fetch(`${APIBase}/client/activity/venue/${venueId}`, data);
      if (response.ok) {
        const result = await response.json();
        setActivities(result.activities);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError + ' (Activities)',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleDelete = async () => {
    const data: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ venueId }),
    };
    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.deleteSuccess,
            icon: 'success',
            timer: 2000,
          });
          navigate('/app/admin/venues');
        } else {
          Swal.fire({
            title: t.error,
            text: t.deleteFailure,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.deleteFailure,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const confirmDelete = () => {
    Swal.fire({
      title: t.deleteConfirmTitle,
      text: t.deleteConfirmText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t.delete,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  const generateGoogleMapsLink = () => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(venueData.address)}`;
  };

  const generateAppleMapsLink = () => {
    return `http://maps.apple.com/?q=${encodeURIComponent(venueData.address)}`;
  };

  const handleViewOnMap = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const mapLink = isIOS ? generateAppleMapsLink() : generateGoogleMapsLink();
    window.open(mapLink, '_blank');
  };

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <StageSpinner size={50} color="#ffffff" />
      </div>
    );
  }

  if (!venueData) {
    return <div className="text-white min-h-screen flex items-center justify-center">{t.loading}</div>;
  }

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate('/app/admin/venues')}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      <div className="max-w-4xl mx-auto mt-12">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-4xl font-extrabold tracking-tight">{t.details}</h2>
          <div className="relative">
            <button
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="px-2 py-1 bg-gray-700 text-white rounded-lg flex items-center hover:bg-gray-600"
            >
              <FaEllipsisV />
            </button>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-xl z-10">
                <button
                  onClick={() => navigate(`/app/admin/edit-venue/${venueId}`)}
                  className="block px-4 py-2 w-full text-left hover:bg-gray-100 transition duration-150"
                >
                  {t.edit}
                </button>
                <button
                  onClick={confirmDelete}
                  className="block px-4 py-2 w-full text-left hover:bg-gray-100 transition duration-150"
                >
                  {t.delete}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
          <h3 className="text-3xl mb-4">{venueData.name}</h3>
          <p className="text-lg mb-4">
            <strong>{t.address}:</strong> {venueData.address}
          </p>
          <p className="text-lg mb-4">
            <strong>{t.description}:</strong> {venueData.description}
          </p>
          <button
            onClick={handleViewOnMap}
            className="flex items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white p-3 rounded-full shadow-lg hover:from-blue-700 hover:to-blue-900 transition-all duration-300 ease-in-out mb-6"
          >
            <FaMapMarkerAlt className="mr-2 text-xl" />
            {t.viewOnMap}
          </button>

          {venueData.imageURL && (
            <img src={venueData.imageURL} alt={venueData.name} className="w-full h-72 object-cover rounded-md mb-6" />
          )}

          {venueData.schedule && (
            <div className="mt-6">
              <h4 className="text-2xl font-semibold mb-4">{t.schedule}</h4>
              <div className="space-y-2">
                {venueData.schedule.map((day: any, index: number) => (
                  <div key={index} className="flex justify-between text-lg">
                    <span>{day.day}</span>
                    <span>
                      {day.times.length > 0
                        ? day.times.map((time: any) => `${time.open} - ${time.close}`).join(', ')
                        : 'Closed'}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Activities Section */}
          <div className="mt-8">
            <h4 className="text-2xl font-semibold mb-4">{t.activities}</h4>
            <ul className="space-y-2">
              {activities.length > 0 ? (
                activities.map((activity) => (
                  <li key={activity._id} className="flex justify-between items-center bg-gray-700 p-3 rounded-lg">
                    <span>
                      {activity.name} ({activity.points} points)
                    </span>
                  </li>
                ))
              ) : (
                <li className="text-gray-400">{t.loading}</li>
              )}
            </ul>
          </div>

          <p className="text-lg mt-6">
            <strong>{t.latitude}:</strong> {venueData.lat}
          </p>
          <p className="text-lg">
            <strong>{t.longitude}:</strong> {venueData.lng}
          </p>
          <p className="text-lg mt-4">
            <strong>{t.venueTypes}:</strong> {venueData.venueTypes.join(', ')}
          </p>

          <div className="flex flex-row space-x-4 my-4">
            {venueData.facebookLink && (
              <a
                href={venueData.facebookLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 px-4 py-4 rounded-lg flex items-center justify-center"
              >
                <FaFacebook className="text-2xl" />
              </a>
            )}
            {venueData.instagramLink && (
              <a
                href={venueData.instagramLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white bg-gradient-to-r from-pink-500 to-pink-700 hover:from-pink-600 hover:to-pink-800 px-4 py-4 rounded-lg flex items-center justify-center"
              >
                <FaInstagram className="text-2xl" />
              </a>
            )}
            {venueData.tiktokLink && (
              <a
                href={venueData.tiktokLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white bg-gradient-to-r from-gray-600 to-gray-800 hover:from-gray-700 hover:to-gray-900 px-4 py-4 rounded-lg flex items-center justify-center"
              >
                <FaTiktok className="text-2xl" />
              </a>
            )}
            {venueData.websiteLink && (
              <a
                href={venueData.websiteLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 px-4 py-4 rounded-lg flex items-center justify-center"
              >
                <FaGlobe className="text-2xl" />
              </a>
            )}
          </div>

          <h3 className="text-xl mt-6">Venue QR Code</h3>
          <div className="flex justify-center mt-4 bg-white p-5 rounded-md shadow-md">
            <QRCode value={`venue-${venueData._id}`} size={128} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewVenue;
