import { useLanguage } from 'Contexts/LanguageContext'; // Adjust the import path as necessary
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useMediaQuery } from 'react-responsive';

interface DateFilterProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  handleCloseModal: () => void; // Prop to close the modal
}

const DateFilter: React.FC<DateFilterProps> = ({ startDate, endDate, setStartDate, setEndDate, handleCloseModal }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { language, translations } = useLanguage();
  const t = translations[language];

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  useEffect(() => {
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [startDate, endDate]);

  const applyFilters = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    handleCloseModal(); // Close the modal
  };

  const cancelFilters = () => {
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
    handleCloseModal(); // Close the modal without applying filters
  };

  const handleStartDateChange = (newDate: Date | null) => {
    if (isMobile) {
      newDate && setLocalStartDate(newDate);
    } else {
      newDate && setStartDate(newDate);
    }
  };

  const handleEndDateChange = (newDate: Date | null) => {
    if (isMobile) {
      newDate && setLocalEndDate(newDate);
    } else {
      newDate && setEndDate(newDate);
    }
  };

  return isMobile ? (
    <div className="flex flex-col items-start justify-center h-full w-full px-3 bg-black rounded-lg p-4 shadow-md">
      <div className="text-white mb-1">{t.from}</div>
      <input
        type="date"
        value={localStartDate.toISOString().substr(0, 10)}
        onChange={(e) => setLocalStartDate(new Date(e.target.value))}
        className="date-input w-full p-2 rounded-lg border border-gray-300 text-black bg-white"
      />
      <div className="text-white mb-1 mt-6">{t.to}</div>
      <input
        type="date"
        value={localEndDate.toISOString().substr(0, 10)}
        onChange={(e) => setLocalEndDate(new Date(e.target.value))}
        className="date-input w-full p-2 rounded-lg border border-gray-300 text-black bg-white"
      />
      <button
        onClick={applyFilters}
        className="mt-4 p-2 bg-black text-white border border-solid border-white rounded-lg w-full"
      >
        {t.applyFilters}
      </button>
      <button
        onClick={cancelFilters}
        className="mt-2 p-2 bg-gray-700 text-white border border-solid border-white rounded-lg w-full"
      >
        {t.cancel}
      </button>
    </div>
  ) : (
    <div className="flex items-center space-x-2 flex-row z-10 bg-black p-4 rounded-lg shadow-md">
      <DatePicker
        selected={localStartDate}
        onChange={(date: Date | null) => handleStartDateChange(date)}
        className="p-2 rounded-lg w-full border border-gray-300 text-black bg-white"
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
      <DatePicker
        selected={localEndDate}
        onChange={(date: Date | null) => handleEndDateChange(date)}
        className="p-2 rounded-lg w-full border border-gray-300 text-black bg-white"
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
      <button
        onClick={applyFilters}
        className="p-2 bg-black text-white border border-solid border-white rounded-lg md:hidden"
      >
        {t.apply}
      </button>
      <button
        onClick={cancelFilters}
        className="p-2 bg-gray-700 text-white border border-solid border-white rounded-lg md:hidden"
      >
        {t.cancel}
      </button>
    </div>
  );
};

export default DateFilter;
